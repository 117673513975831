const initialState = {
  loading: false,
  lists: [],
  error: null,
  orders: [],
  totalOrders: 0,
  backendAssetUrl: "",
  loadingMore: false,
  hasMore: true,
  totalOtherOrders: 0,
};
const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ORDER_LIST":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SET_ORDER_LIST":
      return {
        ...state,
        loading: false,
        orders: action.payload,
        ...action.extra,
      };

    case "SET_ORDER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.err?.message,
      };
    case "LOADING_MORE_ORDERS":
      return { ...state, error: null, loadingMore: true };
    case "APPEND_ORDERS":
      return {
        ...state,
        error: null,
        orders: [...state.orders, ...action.payload],
        ...action.extra,
        loadingMore: false,
      };
    case "FILTER_ORDER_LISTS":
      let orders = state.orders.filter((c) => c._id !== action.payload);
      return {
        ...state,
        loading: false,
        orders,
        totalOrders: orders.length,
        totalOtherOrders: state.totalOtherOrders + 1,
      };
    default:
      return state;
  }
};

export default orderReducer;
