import React from "react";
import VendorNavbar from "../component/VendorNavbar";
import { Link } from "react-router-dom";
import ASSETS from "../common/images";

const Landing = () => {
  return (
    <div className="vendor_page_spacing">
      {/* <VendorNavbar title="" backgroundColor="white" backArrow="" /> */}
      <div className="create_protal align_center_pg">
        <div className="cp_inner acp_inner text-center">
          <div className="congrates_img">
            <img src="assets/bbsite_images/cat_img.png" alt="" />
          </div>
          <div className="vg22"></div>
          <h5 className="m15 line_hight">
            यहाँ आप बिना किसी कोडिंग ज्ञान या बिना किसी आईटी कंपनी की सहायता के,
            केवल 10 मिनट में अपनी खुद की पेशेवर वेबसाइट बनाएँगे।
          </h5>
          <div className="vg22"></div>
          <Link to="/create-account" className="theme_btn m-auto">
            Create Your Website
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Landing;
