import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import ASSETS from "../../../common/images";
import ActionConfirmationAlert from "../../../shared/Model/ActionConfirmationAlert";
import { dateToFormatWithTime } from "../../../common/helpers/_helper";
import { CATEGORY_LISTS } from "../../../common/constants";

const OrderDetailCard = ({
  index,
  order,
  toggleBtn,
  handleStatusChange,
  handleDeleteClick,
  backendAssetUrl,
  handleData,
}) => {
  const [checked, setChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [checkedTemp, setCheckedTemp] = useState(false);

  const handleChange = (checked, event) => {
    event.stopPropagation();
    // setChecked(checked);
    setCheckedTemp(checked);
    // handleStatusChange(order, checked);
    setShowPopup(true);
  };

  const getByTitle = () => {
    return (order?.items || [])
      .map((item) => `${item?.service?.title}`)
      .join(" | ");
  };

  return (
    <>
      <Accordion.Item
        eventKey={index.toString()}
        key={index}
        className="relative"
      >
        <Accordion.Header>
          <div className="left">
            <h5 className="product_name" style={{
              textTransform:"capitalize"
            }}>{getByTitle()}</h5>
            <div className="vg3"></div>
            <h6 className="product_id">
              {dateToFormatWithTime(order?.orderDate)} ({order?.items.length} item
              {order?.items.length > 1 && "s"})
            </h6>
          </div>
          <div className="right">
            {toggleBtn && (
              <div className={`my_switch ${checked ? "on" : "off"}`}>
                <div className="switch">
                  <Switch onChange={handleChange} checked={checked} />
                </div>
                <div
                  className="label"
                  style={{
                    fontSize: "8px",
                  }}
                >
                  {checked ? "Done" : "Pending"}
                </div>
              </div>
            )}

            <span
              className="material-symbols-outlined red"
              onClick={(e) => handleDeleteClick(e, order)}
            >
              delete_forever
            </span>
            <span className="material-symbols-outlined open icon_grad">
              add
            </span>
            <span className="material-symbols-outlined close icon_grad">
              remove
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <div className="my_accordion_body">
            <div className="product_short_info relative">
              {order?.items.length > 0 &&
                order?.items.map((item, index) => (
                  <div key={index} className={`${order?.serviceType}`}>
                    {" "}

                    <h6 className="product_id">
                      ID : #{order?.invoiceNo || ""}
                    </h6>
                    <div className="vg6"></div>
                    <div className="name r14 d-flex align-items-center" style={{
                      textTransform:"capitalize"
                    }}>
                      {item?.service?.title === "Hotel Booking" && (
                        <img src="/assets/img/service_hotel.png" alt="" style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "5px"
                        }} />
                      )}
                      {item?.service?.title === "Railway Ticket Booking" && (
                        <img src="/assets/img/service_train.png" alt="" style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "5px"
                        }} />
                      )}
                      {item?.service?.title === "Air Ticket Booking" && (
                        <img src="/assets/img/service_plain.png" alt="" style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "5px"
                        }} />
                      )}
                      {item?.service?.title === "Bus Ticket Booking" && (
                        <img src="/assets/img/service_bus.png" alt="" style={{
                          width: "14px",
                          height: "14px",
                          marginRight: "5px"
                        }} />
                      )}
                      {item?.service?.title} inquiry</div>
                    <div className="vg3"></div>
                    {CATEGORY_LISTS.TRAVEL === order?.categoryName ? (
                      <div>
                        {order?.serviceType === "serviceInfo" ? (
                          <>
                            {order?.otherDetails?.fromCityName && (
                              <span className="m16 blue">
                                For{" "}{order?.otherDetails?.fromCityName}
                              </span>
                            )}{" "}
                            {order?.otherDetails?.toCityName && (
                              <span className="m16 blue">
                                To{" "}{order?.otherDetails?.toCityName}
                              </span>
                            )}
                            {order?.otherDetails?.isReturn && (
                              <div className="r12 grey">
                                (Round Trip)
                              </div>
                            )}
                            {order?.otherDetails?.toDate && (
                              <>
                                <div className="vg3"></div>
                                <div className="r14
                              ">Journey Date : {order?.otherDetails?.toDate}
                                </div></>
                            )}
                            {order?.otherDetails?.isReturn && (
                              <div className="r14
                              ">
                                Return Date : {order?.otherDetails?.returnDate}
                              </div>
                            )}
                            {order?.otherDetails?.cityName && (
                              <div className="m16 blue">
                                For {order?.otherDetails?.cityName || 0}
                              </div>
                            )}
                            <div className="r12 grey">
                              {order?.otherDetails?.totalAdult && (
                                <span>
                                  ( {order?.otherDetails?.totalAdult || 0} Adult and
                                </span>
                              )}{" "}
                              {order?.otherDetails?.totalChild && (
                                <>
                                  {order?.otherDetails?.totalChild || 0} Child )
                                </>
                              )}
                            </div>
                            {order?.otherDetails?.checkInDate && (
                              <div className="r14">
                                <div className="vg3"></div>
                                Check In Date: {order?.otherDetails?.checkInDate}
                              </div>
                            )}{" "}
                            {order?.otherDetails?.checkOutDate && (
                              <div className="r14">
                                Check Out Date: {order?.otherDetails?.checkOutDate}
                              </div>
                            )}{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="pic_price">
                      <div className="pic">
                        <img
                          src={
                            item?.service?.thumbnail
                              ? `${backendAssetUrl}${item?.service?.thumbnail}`
                              : ASSETS.demoProduct
                          }
                          alt=""                        
                        />
                      </div>                 

                      {item?.service?.type === "vehicleInfo" && (
                        <div>
                          <h6 className="">
                          <span className="sb14 purple">  ₹{item?.service?.pricePerKm || 0}/km{" "}</span>
                            {item?.service?.minimumPrice > 0 && (
                              <span className="r14">(₹{item?.service?.minimumPrice} min.)</span>
                            )}
                          </h6>
                          <div className="r12">
                            {item?.service?.totalSeats || 0} seats
                            {item?.service?.features?.length > 0 && (
                              <span>
                                {" "}
                                | {item?.service?.features.join(" | ")}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {item?.service?.type === "packageInfo" && (
                        <>
                          <h6 className="sb16">
                            ₹{item?.service?.price || 0}
                          </h6>
                        </>
                      )}
                        {CATEGORY_LISTS.TRAVEL !== order?.categoryName && (
                        <div className="price">
                          <h5 className="sb16">₹ {item?.total}</h5>
                          <h6 className="r14"> {item?.quantity} Pieces</h6>
                        </div>
                      )}
                      {/* <div className="view">
                      <img src={ASSETS.eyeIcon} alt="" />
                    </div> */}
                    </div>
                    {index !== order?.items.length - 1 && <hr />}
                    <div
                      className="icon"
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      <img
                        src={ASSETS.shareIcon}
                        alt=""
                        style={{
                          height: "20px  ",
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="ab_divider"></div>
            <div className="contact_person_info">
              {order?.items.length > 1 && order?.totalAmount > 0 && (
                <h6 className="m14">Total: {order?.totalAmount || ""}</h6>
              )}
              <h6 className="m14">{order?.name || ""}</h6>
              <h6 className="r14">
                {order?.phoneNumber || ""}
                <Link
                  to={`tel:${order?.phoneNumber || ""}`}
                  className="theme_btn text_near_btn"
                  style={{
                    padding: "0px 6px"
                  }}
                >
                  <span className="material-symbols-outlined" style={{
                    fontSize: "8px"
                  }}>call</span>
                  Call
                </Link>
              </h6>             
              <p className={`r12 bottom_address ${order?.serviceType}`}>
                {order?.fullAddress || ""} <br /> {order?.cityName || ""},{" "}
                {order?.stateName || ""} - {order?.countryName || ""}
              </p>
            </div>
            <div className="vg12"></div>
            <div
              className="theme_btn text_near_btn"
              style={{
                margin: "0px",
                fontSize: "12px",
              }}
              onClick={() => handleData !== undefined && handleData(order)}
            >
              Invoice
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
      {showPopup && (
        <ActionConfirmationAlert
          show={showPopup}
          onHide={() => setShowPopup(false)}
          actionHandler={() => {
            handleStatusChange(order, checkedTemp);
            setShowPopup(false);
          }}
          message="Are you sure you want to complete this order?"
        />
      )}
    </>
  );
};

export default OrderDetailCard;
