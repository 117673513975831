import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";

// vendor navbar
import VendorNavbar from "../component/VendorNavbar";

import ActivityIndigator from "../shared/ActivityIndigator";
import { Form } from "react-bootstrap";
import { resendOTP, verifyOTP } from "../store/actions/authActions";
import { connect } from "react-redux";

const Otp = ({ tempInfo, resendOTPhandler, verifyOTPHandler, submitting }) => {
  const [OTP, setOTP] = useState("");
  const [hasError, setHasError] = useState(false);
  const [counter, setCounter] = React.useState(25);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const navigate = useNavigate();
  const handleChange = (otp) => {
    // if (otp.length === 4) {
    //   if (tempInfo.OTP !== Number(otp)) {
    //     setHasError(true);
    //   }
    // } else {
    setHasError(false);
    // }
    setOTP(otp);
  };

  const handleVerify = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyOTPHandler(OTP, navigate, setHasError);
      }
    } else {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyOTPHandler(OTP, navigate, setHasError);
      }
    }
  };

  const displayTimer = (time_seconds) => {
    let minutes = Math.floor((time_seconds % 3600) / 60);
    let seconds = time_seconds % 60;
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;

    return `${minutes}:${seconds}`;
  };

  const showTimer = useMemo(() => displayTimer(counter), [counter]);

  return (
    <div className="vendor_page_spacing">
      {/* <VendorNavbar title="" backgroundColor="white" backArrow="" /> */}
      <div className="create_protal align_center_pg">
        <div className="cp_inner acp_inner text-center">
          <div className="logo">
            <img src="./assets/img/logo_businessbaab.svg" alt="" />
          </div>
          <div className="vg22"></div>
          <h2 className="sb22">Welcome to BusinessBaab</h2>
          <div className="vg22"></div>
          <h4 className="r18">OTP</h4>
          <div className="vg22"></div>
          <div className="vg22"></div>
          <span>{tempInfo?.OTP}</span>
          <Form onSubmit={handleVerify}>
            <div className="form_field otp">
              <label htmlFor="">Enter 4 Digit OTP</label>
              <OTPInput
                className={`otp_input ${hasError ? "b-red" : ""}`}
                inputStyle="inputStyle"
                value={OTP}
                onChange={handleChange}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                isInputNum={true}
                hasErrored={hasError}
                placeholder="0000"
                errorStyle="error"
              />
              {hasError && <span className="err_red ">enter valid otp</span>}
            </div>
            <div className="vg22"></div>
            {showTimer === "00:00" ? null : (
              <p className="r14">Resend OTP in {showTimer}</p>
            )}
            {counter === 0 && (
              <p className="resend_otp r14">
                Didn't recieve an OTP?
                <span
                  className="cursor pl-2 grad_text "
                  onClick={() => {
                    resendOTPhandler(setCounter);
                  }}
                >
                  <br></br> Resend OTP
                </span>
              </p>
            )}
            <div className="vg22"></div>
            <button
              // to="/select-account-type"
              disabled={submitting}
              className="theme_btn"
            >
              {submitting ? <ActivityIndigator /> : "submit"}
            </button>

            <div className="vg22"></div>
            <Link to="/region" className="grad_text">
              Back
            </Link>
          </Form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ form, auth }) => {
  return {
    submitting: form.submitting,
    success: form.success,
    tempInfo: auth.tempInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    verifyOTPHandler: (data, navigate, setHasError) =>
      dispatch(verifyOTP(data, navigate, setHasError)),
    resendOTPhandler: (setCounter) => dispatch(resendOTP(setCounter)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
