import React, { useState } from "react";
import { Link } from "react-router-dom";

import ASSETS from "../../../common/images";
import Modal from "react-bootstrap/Modal";

const ContactInfo = ({ profile: { personalInfo, businessInfo } }) => {
  //   modal
  const [showCustomDate, setShowCustomDate] = useState(false);

  const handleCloseCustomDateModal = () => setShowCustomDate(false);
  const handleShowCustomDateModal = () => setShowCustomDate(true);
  //   modal

  return (
    <>
      <h3 className="sect_title">Contact Now</h3>
      <div className="contact_parent">
        <Link
          to={`tel:${personalInfo?.phoneNumber || ""}`}
          className="contact_single text-center pointer"
        >
          <div className="cs_inner">
            <img src={ASSETS.callIcon} alt="" />
          </div>
          <div className="vg12"></div>
          <h6 className="grad_text">Call</h6>
        </Link>
        <Link
          to={`https://wa.me/${personalInfo?.whatsapNumber || ""}/?text=Hello`}
          className="contact_single text-center pointer"
        >
          <div className="cs_inner">
            <img src={ASSETS.whatsappIcon} alt="" />
          </div>
          <div className="vg12"></div>
          <h6 className="grad_text">Whatsapp</h6>
        </Link>
        {businessInfo?.email && (
          <Link
            to={`mailto:${businessInfo?.email || ""}`}
            className="contact_single text-center pointer"
          >
            <div className="cs_inner">
              <img src={ASSETS.emailIcon} alt="" />
            </div>
            <div className="vg12"></div>
            <h6 className="grad_text">Email</h6>
          </Link>
        )}

        <Link
          onClick={handleShowCustomDateModal}
          className="contact_single text-center pointer"
        >
          <div className="cs_inner">
            <img src={ASSETS.addressIcon} alt="" />
          </div>
          <div className="vg12"></div>
          <h6 className="grad_text">Address</h6>
        </Link>
      </div>
      {/* modal for delete order  */}
      <Modal
        show={showCustomDate}
        onHide={handleCloseCustomDateModal}
        className="my_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">Address</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseCustomDateModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r14">{businessInfo?.address?.fullAddress || ""}</h6>
          <h6 className="r14">
            {businessInfo?.address?.cityName || ""},{" "}
            {businessInfo?.address?.stateName || ""}{" "}
            <span
              style={{
                textTransform: "uppercase",
              }}
            >
              - {businessInfo?.address?.countryName || ""}
            </span>
          </h6>
          <div className="vg12"></div>
          {businessInfo?.address?.googleLink && (
            <Link
              to={businessInfo?.address?.googleLink || ""}
              className="text-center r16 grad_text gt_border"
            >
              Google Map
            </Link>
          )}
        </Modal.Body>
        <div className="vg22"></div>
      </Modal>
    </>
  );
};

export default ContactInfo;
