import React from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";

const AccountAboutUs = () => {
  usePageTitle("About Us");
  return (
    <div className="pg_aboutus static_pg">
      <p className="r14">
      Here you can create your own professional website in just 15 minutes, without any coding knowledge or help from any IT company.
      </p>
      <div className="vg12"></div>
      <p className="r14">
      At BusinessBaab, we understand the challenges small businesses face in today's online world. That is why we have brought a platform that simplifies the process of creating a website. You just focus on your business, leave all the technology problems to us for just ₹150 per month.
      </p>
      <div className="vg12"></div>
      <p className="r14">
      Join BusinessBaab today and take the first step towards taking your business online. Let us help you build your online presence and reach new heights of success.
      </p>
      <div className="vg22"></div>
      <h6 className="sb16">Vision and Mission</h6>
      <div className="vg8"></div>
      <p className="r14">
      Our vision is to empower individuals and businesses to build their online presence effortlessly and affordably.
      </p>
      <div className="vg12"></div>
      <p className="r14">
      Our mission is to provide a user-friendly platform that enables anyone to create a website quickly and easily, allowing them to focus on their passion and expertise.
      </p>
    </div>
  );
};

export default AccountAboutUs;
