import React from "react";
import { Accordion } from "react-bootstrap";
import OrderDetailCard from "./OrderDetailCard";

const OrderItem = ({
  orders,
  handleStatusChange,
  toggleBtn = true,
  handleDeleteClick,
  backendAssetUrl,
  handleData,
}) => {
  return (
    <>
      {orders.length > 0 ? (
        <Accordion defaultActiveKey="0" className="theme_accordion">
          {orders.map((order, index) => (
            <OrderDetailCard
              key={index}
              index={index}
              order={order}
              toggleBtn={toggleBtn}
              handleStatusChange={handleStatusChange}
              handleDeleteClick={handleDeleteClick}
              backendAssetUrl={backendAssetUrl}
              handleData={handleData}
            />
          ))}
        </Accordion>
      ) : (
        <h3 className="m14 text-center red">No item found</h3>
      )}
    </>
  );
};

export default OrderItem;
