import React, { useRef } from "react";
import { dateToFormat } from "../../../common/helpers/_helper";
import { Modal } from "react-bootstrap";
import html2canvas from "html2canvas";
const InvoiceModal = ({ order, userDetails, show, onHide }) => {
  const captureRef = useRef(null);

  let invoiceName = `inovie-${order?.invoiceNo}.png`;

  const handleCaptureClick = async () => {
    if (captureRef.current) {
      const canvas = await html2canvas(captureRef.current);
      const imgData = canvas.toDataURL("image/png");
      downloadImage(imgData, invoiceName);
    }
  };

  const downloadImage = (dataUrl, filename) => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShareClick = async () => {
    if (navigator.share) {
      if (captureRef.current) {
        const canvas = await html2canvas(captureRef.current);
        canvas.toBlob(async (blob) => {
          const file = new File([blob], invoiceName, {
            type: "image/png",
          });

          try {
            await navigator.share({
              files: [file],
              title: "INVOICE",
              text: "Check out your invoice!",
            });
            console.log("Image shared successfully");
          } catch (error) {
            console.error("Error sharing image:", error);
          }
        }, "image/png");
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };


 

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="my_modal modal_top invoice_modal"
    >
       <Modal.Header closeButton>      
            <span
              class="material-symbols-outlined modal_close red"
              onClick={onHide}
              style={{
                top:"6px",
                right:"6px",
                zIndex:"9"
              }}
            >

              close
            </span>
          </Modal.Header>
      <Modal.Body>
        {" "}
        {/* invoice jsx start */}
        <div    className="invoice" style={{
          overflow: "scroll"
        }}>
          <div
            className="invoice_inner" id="dowanlod_visting_card"
            style={{
              padding: "20px",
              width: "575px",
            }}
            ref={captureRef}
          
          >
            <div
              className="top"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                marginBottom: "8px",
              }}
            >
              <div className="left">
                <img
                  src={userDetails?.businessInfo?.logo}
                  alt=""
                  className="logo"
                  style={{
                    height: "35px",
                    width: "auto",
                    objectFit: "contain",
                    marginBottom: "30px",
                  }}
                />
                <h3
                  style={{
                    fontSize: "20px",
                    fontFamily: "semibold",
                    lineHeight: "30px",
                    color: "#000000",
                    marginBottom: "2px",
                  }}
                >
                  {userDetails?.businessInfo?.title}
                </h3>
                <h4
                  style={{
                    fontSize: "14px",
                    fontFamily: "medium",
                    lineHeight: "21px",
                    color: "#000000",
                    marginBottom: "2px",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <img
                    src="/assets/img/footer_whatsapp.png"
                    alt=""
                    style={{
                      width: "15px",
                      height: "15px",
                      objectFit: "contain",
                    }}
                  />
                  <span> {userDetails?.phoneNumber}</span>
                </h4>
                <h6
                  style={{
                    color: "#807E7E",
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontFamily: "regular",
                    marginBottom: "14px",
                  }}
                >
                  {userDetails?.businessInfo?.address?.cityName},{" "}
                  {userDetails?.businessInfo?.address?.stateName} <br />{" "}
                  <span
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    {userDetails?.businessInfo?.address?.countryName}
                  </span>
                </h6>
                <h6
                  style={{
                    fontSize: "14px",
                    fontFamily: "medium",
                    lineHeight: "21px",
                    color: "#000000",
                    marginBottom: "2px",
                  }}
                >
                  Bill To
                </h6>
                <h6
                  style={{
                    fontSize: "18px",
                    fontFamily: "semibold",
                    lineHeight: "27px",
                    color: "#000000",
                    marginBottom: "2px",
                  }}
                >
                  {order?.name}
                </h6>
                <h6
                  style={{
                    fontSize: "14px",
                    fontFamily: "medium",
                    lineHeight: "21px",
                    color: "#000000",
                    marginBottom: "4px",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                  }}
                >
                  <span
                    class="material-symbols-outlined"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    call
                  </span>
                  <span> {order?.phoneNumber}</span>
                </h6>
                <h6
                  style={{
                    color: "#807E7E",
                    fontSize: "14px",
                    fontFamily: "regular",
                    lineHeight: "21px",
                  }}
                >
                  {order?.cityName}, {order?.stateName} <br />{" "}
                  {order?.countryName}
                </h6>
              </div>
              <div className="right text-end">
                <h3
                  style={{
                    fontSize: "32px",
                    lineHeight: "48px",
                    fontFamily: "regular",
                    color: "#000000",
                  }}
                >
                  INVOICE
                </h3>
                <h6
                  style={{
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#807E7E",
                    fontFamily: "regular",
                  }}
                >
                  #{order?.invoiceNo || ""}
                </h6>
                <h6
                  style={{
                    fontSize: "14px",
                    fontFamily: "medium",
                    lineHeight: "21px",
                    color: "#000000",
                  }}
                >
                  Date : {dateToFormat(order?.createdAt)}
                </h6>
              </div>
            </div>
            <div className="order_in_table">
              <table width="100%">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th width="50%">Item</th>
                    <th>Qty.</th>
                    <th>Rate</th>
                    <th className="text-end">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {order?.items.length > 0 &&
                    order?.items.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.service?.title}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.price}</td>
                        <td className="amount">{item?.total}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div
                className="total_amount"
                style={{
                  textAlign: "end",
                  marginTop: "4px",
                  fontSize: "17px",
                  fontFamily: "semibold",
                  lineHeight: "26px",
                }}
              >
                Total : ₹ {order?.totalAmount || ""}
              </div>
              <h6
                style={{
                  fontSize: "14px",
                  fontFamily: "regular",
                  color: "#807E7E",
                  marginTop: "16px",
                  lineHeight: "21px",
                }}
              >
                Thanks for your purchase.
              </h6>
              <h6
                style={{
                  fontSize: "12px",
                  fontFamily: "regular",
                  color: "#807E7E",
                  marginTop: "22px",
                  textAlign: "center",
                  lineHeight: "18px",
                }}
              >
                BusinessBaab.com
              </h6>
            </div>
          </div>
        </div>
        {/* invoice jsx end*/}
      </Modal.Body>
      <Modal.Footer style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "15px",
        padding:"15px"
      }}>
        <div className="modal_btn" onClick={handleShareClick} style={{
          width:"fit-content"
        }}>
          Share
        </div>
        <div className="modal_btn" onClick={handleCaptureClick} style={{
          width:"fit-content"
        }}>
          Download
        </div>

      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceModal;
