const initialState = {
  error: "",
  userInfo: {},
  tempInfo: {},
  isMaintenance: false,
  loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "NUMBER_VALIDATE_SUCCESS":
      return {
        ...state,
        error: null,
        tempInfo: { ...action.payload },
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        error: action.err.message,
      };
    case "LOGIN_SUCCESSS":
      return {
        ...state,
        error: null,
        userInfo: { ...action.payload },
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        error: "",
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        error: null,
        userInfo: { ...action.payload },
      };
    case "SIGNOUT_SUCCESS":
      return {
        ...state,
        error: "",
        userInfo: {},
        tempInfo: {},
      };
    case "PENDING_OPEN_MAINTENANCE":
      return {
        ...state,
        loading: true,
      };
    case "FULFILLED_OPEN_MAINTENANCE":
      return {
        ...state,
        loading: false,
        isMaintenance: action.payload.data,
      };
    case "REJECTED_OPEN_MAINTENANCE":
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

module.exports = authReducer;
