import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import usePageTitle from "../../common/hooks/usePageTitle";
import { connect } from "react-redux";
import { getDashboardItems } from "../../store/actions/homeActions";
import ActivityLoader from "../../shared/ActivityLoader/ActivityLoader";
import { getDigitFormat } from "../../common/helpers/_helper";

const Analytics = ({ loading, counts, getDashboardItems, userDetails }) => {
  useEffect(() => {
    getDashboardItems({});
  }, [getDashboardItems]);
  usePageTitle("Analytics");
  return (
    <div className="pganalytics">
      {loading ? <ActivityLoader /> : null}
      <section className="total_visitor">
        {/* <Link to="/account/filter" className="my_filter filter_end">
          <img src=src={ASSETS.filterIcon} alt="" />
          <span className="grad_text gt_border">Filter</span>
        </Link> */}

        <div className="tv_data text-center">
          <div className="tvd_inner">
            <h6 className="blue sb40">
              {getDigitFormat(counts?.totalVisitors || 0)}
            </h6>
          </div>
          <h6 className="r14 blue mt-1">Total Views</h6>
        </div>
        <div className="vg22"></div>
      </section>
      <section className="big_cards">
        <div className="bc_single">
          <div className="inner">
            <h5 className="sb25">{counts?.totalLeads || 0}</h5>
            <h6 className="r14">Total Leads</h6>
            <Link
              to={`/account/orders/pending`}
              className="grad_text gt_border"
            >
              See Leads &rarr;{" "}
            </Link>
          </div>
        </div>
        <div className="bc_single">
          <div className="inner">
            <h5 className="sb25">{counts?.totalDoneOrders || 0}</h5>
            <h6 className="r14">Done Orders</h6>
            <Link
              to={`/account/orders/complete`}
              className="grad_text gt_border"
            >
              See Orders &rarr;{" "}
            </Link>
          </div>
        </div>
      </section>
      <section className="long_card_parent">
        <div className="long_card_single">
          <h4 className="sb25">
            {getDigitFormat(counts?.totalRevenue || 0)}&nbsp;{" "}
            <span className="r14">Total Revenue</span>{" "}
          </h4>
        </div>
      </section>
      <div className="vg22"></div>
      {userDetails?.publicIdentifier && (
        <Link to={`/${userDetails?.publicIdentifier}`} className="theme_btn">
          View Your Website
        </Link>
      )}
    </div>
  );
};

const mapStateToProps = ({ dashboard: { loading, counts } }) => {
  return {
    loading,
    counts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardItems: (payload) => dispatch(getDashboardItems(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
