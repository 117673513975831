import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { sendPromoterDetails } from "../../store/actions/contactActions";
import { connect } from "react-redux";
import ActivityIndigator from "../../shared/ActivityIndigator";
import TextError from "../../shared/TextError";
import { promoterSchema } from "../../common/validations/formValidation";
import {
  getCities,
  getCountries,
  getStates,
} from "../../store/actions/commonActions";
import { PAYMENT_PLATFORMS, VALID_IMAGE } from "../../common/constants";
import ASSETS from "../../common/images";
import { showError } from "../../store/common/helpers/responseHelper";

const RefferalForm = ({
  sendPromoterDetails,
  submitting,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
}) => {
  const [image, setImage] = useState(null);
  const imageRef = useRef(null);
  useEffect(() => {
    getCountries();
    getStates(101);
  }, [getCountries, getStates]);
  const initialValues = {
    name: "",
    profession: "personal",
    phoneNumber: "",
    countryId: "101",
    stateId: "",
    cityId: "",
    fullAddress: "",
    numberOfDays: "15",
    upiIdOrNumber: "",
    paymentMethod: "",
    termsAndConditions: false,
    profilePic: "",
  };

  const onFileChange = (event, setFieldValue) => {
    const image = event.target.files[0];
    if (!image.type.startsWith("image/")) {
      showError(VALID_IMAGE);
      return;
    }
    setFieldValue("profilePic", image);
    let reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(image);
  };

  const handleSubmit = (values, { resetForm }) => {
    sendPromoterDetails({ ...values }, resetForm, setImage);
  };
  const handleCountryChange = (value, setFieldValue) => {
    if (value) {
      getStates(value);
      setFieldValue("countryId", value);
      setFieldValue("stateId", "");
      setFieldValue("cityId", "");
    }
  };

  const handleStateChange = (value, setFieldValue) => {
    if (value) {
      getCities(value);
      setFieldValue("stateId", value);
      setFieldValue("cityId", "");
    }
  };
  return (
    <section className="promoter_form">
      <Formik
        initialValues={initialValues}
        validationSchema={promoterSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form
            onSubmit={handleSubmit}
            style={{
              maxWidth: "520px",
              width: "100%",
              margin: "auto",
              padding: "0px 15px",
            }}
          >
            <div className="row">
              <div className="col-12">
                <h6 className="m18">
                  Promoter Form
                  <div className="vg22"></div>
                </h6>
              </div>
              <div className="col-6">
                <div className="form_field">
                  <label htmlFor="name">Name</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter name"
                  />{" "}
                  <ErrorMessage name="name" component={TextError} />
                </div>
                <div className="ff_gap"></div>
              </div>
              <div className="col-6 pl0">
                <div className="form_field">
                  <label htmlFor="phoneNumber">Whatsapp Number*</label>
                  <Field
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Enter whatsapp"
                  />
                  <ErrorMessage name="phoneNumber" component={TextError} />
                </div>
                <div className="ff_gap"></div>
              </div>
              <div className="col-6">
                <div className="form_field">
                  <label htmlFor="paymentMethod">
                    Receiver payment method*
                  </label>
                  <Field as="select" name="paymentMethod" id="type">
                    <option value="">Select</option>
                    {PAYMENT_PLATFORMS.map((p, p_key) => (
                      <option value={p.value} key={p_key}>
                        {p.label}
                      </option>
                    ))}
                  </Field>{" "}
                  <ErrorMessage name="paymentMethod" component={TextError} />
                </div>
                <div className="ff_gap"></div>
              </div>
              <div className="col-6 pl0">
                <div className="form_field mt-420">
                  <label htmlFor="upiIdOrNumber">UPI ID / Number*</label>
                  <Field
                    type="text"
                    name="upiIdOrNumber"
                    id="upiIdOrNumber"
                    placeholder="Enter number"
                  />{" "}
                  <ErrorMessage name="upiIdOrNumber" component={TextError} />
                </div>
                <div className="ff_gap"></div>
              </div>
              <div className="col-4">
                <div className="form_field">
                  <label htmlFor="businesscountry">Country</label>
                  <Field
                    as="select"
                    name="countryId"
                    id="countryId"
                    onChange={(e) =>
                      handleCountryChange(e.target.value, setFieldValue)
                    }
                    value={values.countryId}
                  >
                    {" "}
                    <option value="">Select</option>
                    {(countries ?? []).map((country, i) => (
                      <option key={`country_${i}`} value={country.countryId}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="countryId" component={TextError} />
                </div>
                <div className="ff_gap"></div>
              </div>
              <div className="col-4 pl0">
                <div className="form_field">
                  <label htmlFor="businessstate">State*</label>
                  <Field
                    as="select"
                    name="stateId"
                    id="stateId"
                    onChange={(e) =>
                      handleStateChange(e.target.value, setFieldValue)
                    }
                    value={values.stateId}
                  >
                    {" "}
                    <option value="">Select</option>
                    {(states ?? []).map((state, i) => (
                      <option key={`state_${i}`} value={state.stateId}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="stateId" component={TextError} />
                </div>
                <div className="ff_gap"></div>
              </div>
              <div className="col-4 pl0">
                <div className="form_field">
                  <label htmlFor="businesscity">City*</label>
                  <Field as="select" name="cityId" id="cityId">
                    <option value="">Select</option>
                    {(cities ?? []).map((city, i) => (
                      <option key={`city_${i}`} value={city.cityId}>
                        {city.name}
                      </option>
                    ))}
                  </Field>{" "}
                  <ErrorMessage name="cityId" component={TextError} />
                </div>
              </div>
              <div className="col-12">
                <div className="form_field">
                  <label htmlFor="fullAddress" className="">
                    Full Address*
                  </label>
                  <Field
                    as="textarea"
                    name="fullAddress"
                    id="fullAddress"
                    placeholder="Type here"
                  />{" "}
                  <ErrorMessage name="fullAddress" component={TextError} />
                </div>
                <div className="ff_gap"></div>
              </div>
              <div className="col-12">
                <label htmlFor="">Select your profession</label>
                <div className="inline_radio_group with_bg">
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="profession" value="personal" />
                      Personal
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field
                        type="radio"
                        name="profession"
                        value="businessman"
                      />
                      Businessman
                    </label>
                  </div>
                </div>
              </div>
              <div className="vg12"></div>
              <div className="col-12">
                <label htmlFor="">Receive payment condition</label>
                <div className="inline_radio_group with_bg">
                  {" "}
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="numberOfDays" value="15" />
                      15 Days
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="numberOfDays" value="7" />7 Days
                    </label>
                  </div>{" "}
                  {/* <div className="radio_field">
                    <label>
                      <Field type="radio" name="numberOfDays" value="1" />
                      Daily Basis
                    </label>
                  </div> */}
                </div>
              </div>
              <div className="vg12"></div>
              <div className="col-12">
                <div
                  className="upload_photo"
                  onClick={() => imageRef.current.click()}
                >
                  <input
                    type="file"
                    hidden
                    ref={imageRef}
                    onChange={(e) => onFileChange(e, setFieldValue)}
                  />
                  <div className="img_container" id="upload">
                    <img src={image ? image : ASSETS.uploadIconNew} alt="" />
                  </div>
                  <label htmlFor="upload">Upload Your Photo*</label>
                </div>
              </div>
              <div className="vg12"></div>
              <div className="col-12">
                <div className="form_field mt-1">
                  <Field name="termsAndConditions">
                    {({ field }) => (
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          {...field}
                          checked={field.value}
                          id="t_c"
                        />
                        <label for="t_c">
                          I have read{" "}
                          <Link
                            to="/terms-conditions"
                            target="_blank"
                            className="grad_text "
                          >
                            Terms & Conditions{" "}
                          </Link>
                          and{" "}
                          <Link
                            to="/refund-policy"
                            target="_blank"
                            className="grad_text "
                          >
                            Refund Policy
                          </Link>
                        </label>
                      </div>
                    )}
                  </Field>{" "}
                  <ErrorMessage
                    name="termsAndConditions"
                    component={TextError}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="vg22"></div>

              <button
                type="submit"
                className="theme_btn short_btn"
                disabled={submitting}
              >
                {submitting ? <ActivityIndigator /> : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};
const mapStateToProps = ({ form, common }) => {
  return { submitting: form.submitting, ...common };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
    sendPromoterDetails: (payload, onAction, setImage) =>
      dispatch(sendPromoterDetails(payload, onAction, setImage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefferalForm);
