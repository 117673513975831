import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ASSETS from "../../../common/images";
import Modal from "react-bootstrap/Modal";

const ProductSingle = ({ service, backendAssetUrl, username }) => {
  const [showMyProduct, setShowMyProduct] = useState(false);

  const handleCloseMyProductModal = () => setShowMyProduct(false);
  const handleShowMyProductModal = () => setShowMyProduct(true);

  return (
    <div className={`product_single `}>
      <div className="product_img">
        <img
          src={
            service?.thumbnail
              ? `${backendAssetUrl}/${service.thumbnail}`
              : ASSETS.dummyproductimg
          }
          alt={service?.title}
          onClick={handleShowMyProductModal}
        />
      </div>
      {/* modal for product image  */}
      <Modal
        show={showMyProduct}
        onHide={handleCloseMyProductModal}
        className="my_modal modal_top"
      >
        <Modal.Header className="d-flex">
          <div
            onClick={handleCloseMyProductModal}
            className="modal_btn modal_btn_border modal_top_btn"
          >
            Close
          </div>

          <Link
            // to={`/${username}/product/${service?._id}`}
            to={`/${username}/travel/details/${service?._id}`}
            className="modal_btn modal_top_btn"
          >
            View
          </Link>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6>
            {service?.thumbnail ? (
              <>{/* {service?.title} product image */}</>
            ) : (
              "Image is not available :("
            )}
          </h6>
          {service?.thumbnail && (
            <div className="modal_product_img">
              <img
                src={`${backendAssetUrl}/${service.thumbnail}`}
                alt={service?.title}
                className="w-100"
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
      <div className="product_desc">
        <div className="pd_inner">
          <h5 className="price sb22">
            {service?.type === "vehicleInfo" && (
              <span>
                {service?.pricePerKm ? (
                  <span>
                    ₹{service?.pricePerKm}
                    <span className="m12">/km</span>
                  </span>
                ) : (
                  <span className="m14">Price Negotiable</span>
                )}
                {/* {service?.minimumPrice || ""} */}
              </span>
            )}{" "}
            {service?.type === "packageInfo" && (
              <>
                <span>₹{service?.price || ""}</span>
                {service?.totalPerson && (
                  <div className="r12 grey">
                    ( For {service.totalPerson} person )
                  </div>
                )}
              </>
            )}
          </h5>
          <h6 className="r14 only_one_line mt-1">{service?.title || ""}</h6>
        </div>{" "}
        <div className="vg8"></div>
        <Link
          to={`/${username}/travel/details/${service?._id}`}
          className="theme_btn short_btn view_btn "
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default ProductSingle;
