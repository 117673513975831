import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";

// vendor navbar
import {
  CATEGORY_LISTS,
  DESIGNATION_LISTS,
  IMAGE_SETTINGS,
  VALID_IMAGE,
} from "../../../common/constants";
import { businessInfoSchema } from "../../../common/validations/formValidation";
import TextError from "../../../shared/TextError";
import ASSETS from "../../../common/images";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { connect } from "react-redux";
import {
  deleteBusinessLogo,
  deleteSideImage,
  saveBusinessInfo,
} from "../../../store/actions/userActions";

import {
  getCities,
  getStates,
  getCountries,
} from "../../../store/actions/commonActions";
import { NAVIGATION_ROUTE } from "../../../store/common/constants";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { LABELS } from "../../../common/label";
// import ImageCropper from "../../../shared/Model/ImageCropper";
import {
  STATIC_BUSINESS_BIO,
  generateBio,
} from "../../../common/helpers/_content";
import CommonImageCropper from "../../../shared/Model/CommonImageCropper";
import { showError } from "../../../store/common/helpers/responseHelper";

const BussinessInfo = ({
  heading,
  userDetails,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  submitting,
  saveBusinessInfo,
  categoryName,
}) => {
  const [logo, setLogo] = useState(null);
  const [images, setImages] = useState([]);
  const imageRef = useRef(null);
  const imagesRef = useRef(null);
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const [slideModelOpen, setSlideModelOpen] = useState(false);
  // temp original file object and url
  const [originSelectImage, setOriginalSelectImage] = useState(null);
  const [originSelectUrl, setOriginalSelectUrl] = useState(null);
  const [contentIndex, setContentIndex] = useState(0);

  useEffect(() => {
    getCountries();
    if (userDetails !== undefined) {
      if (userDetails?.businessInfo?.address?.countryId) {
        getStates(userDetails?.businessInfo?.address?.countryId);
      } else {
        getStates(101);
      }
      if (userDetails?.businessInfo?.address?.stateId) {
        getCities(userDetails?.businessInfo?.address?.stateId);
      }

      if (userDetails?.businessInfo?.logo !== "") {
        setLogo(userDetails?.businessInfo?.logo);
      }
      if ((userDetails?.businessInfo?.images || []).length > 0) {
        let images = (userDetails?.businessInfo?.images ?? []).map((url) => ({
          url,
          isBackendUrl: true,
        }));
        setImages(images);
      }
    }
  }, [getCountries, userDetails]);
  const initialValues = {
    title: userDetails?.businessInfo?.title || "",
    designation: userDetails?.businessInfo?.designation || "",
    bio: userDetails?.businessInfo?.bio || "",
    email: userDetails?.businessInfo?.email || "",
    countryId: userDetails?.businessInfo?.address?.countryId || "101",
    stateId: userDetails?.businessInfo?.address?.stateId || "",
    cityId: userDetails?.businessInfo?.address?.cityId || "",
    fullAddress: userDetails?.businessInfo?.address?.fullAddress || "",
    googleLink: userDetails?.businessInfo?.address?.googleLink || "",
    logo: "",
    images: (userDetails?.businessInfo?.images ?? []).map((i) => "") || [],
  };

  const onFileChange = (event, setFieldValue) => {
    const image = event.target.files[0];
    if (!image.type.startsWith("image/")) {
      showError(
        "uploaded file is not valid. only jpg, jpeg, png, svg, webp files are allowed"
      );
      return;
    }

    // setFieldValue("logo", image);
    setOriginalSelectImage(image);
    let reader = new FileReader();
    reader.onload = (e) => {
      // setLogo(e.target.result);
      setOriginalSelectUrl(e.target.result);
      setModalOpen(true);
    };
    reader.readAsDataURL(image);
  };

  // const onFileSelect_ = (event, values, setFieldValue) => {
  //   const image = event.target.files[0];
  //   let fimages = [...values.images, image];
  //   setFieldValue("images", fimages);
  //   let reader = new FileReader();
  //   reader.onload = (e) => {
  //     let pimages = [...images, { url: e.target.result, isBackendUrl: false }];
  //     setImages(pimages);
  //   };
  //   reader.readAsDataURL(image);
  // };

  const onFileSelect = (event, values, setFieldValue) => {
    const image = event.target.files[0];
    if (!image.type.startsWith("image/")) {
      showError(VALID_IMAGE);
      return;
    }
    setOriginalSelectImage(image);
    let reader = new FileReader();
    reader.onload = (e) => {
      setOriginalSelectUrl(e.target.result);
      setSlideModelOpen(true);
    };
    reader.readAsDataURL(image);
  };

  const actionSliderHandler = (data, type) => {
    if (type === "save" && data !== null) {
      let pimages = [...images, { url: data, isBackendUrl: false }];
      setImages(pimages);
    }
    setSlideModelOpen(false);
    setOriginalSelectImage(null);
    setOriginalSelectUrl(null);
  };
  const setSliderImages = (values, image, setFieldValue) => {
    let fimages = [...values.images, image];
    setFieldValue("images", fimages);
  };

  const removeBusinessLogo = (setFieldValue) => {
    deleteBusinessLogo().then((res) => {
      if (res) {
        setLogo("");
      }
    });
  };

  const removeSelectedImage = (index, values, setFieldValue) => {
    const newPImages = [...images];
    let selectedItem = newPImages[index] || {};
    if (Object.keys(selectedItem).length > 0) {
      if (selectedItem.isBackendUrl) {
        // call backend api for url
        deleteSideImage(index).then((res) => {
          if (res) {
            if (newPImages.length === 1) {
              setFieldValue("images", []);
            }
            newPImages.splice(index, 1);
            setImages(newPImages);
          }
        });
      } else {
        newPImages.splice(index, 1);
        setImages(newPImages);

        const newFImages = [...values.images];
        newFImages.splice(index, 1);
        setFieldValue("images", newFImages);
      }
    }
  };

  const handleSubmit = (values) => {
    saveBusinessInfo(values, goBackOrNext);
  };

  const goBackOrNext = () => {
    if (heading === undefined) {
      if (CATEGORY_LISTS.TRAVEL === userDetails?.categoryName) {
        navigate(NAVIGATION_ROUTE.VEHICLE_INFO);
      } else {
        navigate(NAVIGATION_ROUTE.SERVICE_INFO);
      }
    } else {
      navigate(NAVIGATION_ROUTE.USER_ACCOUNT);
    }
  };

  usePageTitle(heading ?? "Business Info");
  const handleCountryChange = (value, setFieldValue) => {
    if (value) {
      getStates(value);
      setFieldValue("countryId", value);
      setFieldValue("stateId", "");
      setFieldValue("cityId", "");
    }
  };

  const handleStateChange = (value, setFieldValue) => {
    if (value) {
      getCities(value);
      setFieldValue("stateId", value);
      setFieldValue("cityId", "");
    }
  };

  const actionHandler = (data, type) => {
    if (type === "save" && data !== null) {
      setLogo(data);
    }
    setModalOpen(false);
    setOriginalSelectImage(null);
    setOriginalSelectUrl(null);
  };
  return (
    <div className="info_forms">
      <Formik
        initialValues={initialValues}
        validationSchema={businessInfoSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="upload_logo text-center ">
                  <div className="img_wrapper relative">
                    <input
                      type="file"
                      hidden
                      ref={imageRef}
                      onChange={(e) => onFileChange(e, setFieldValue)}
                    />

                    <img
                      className="image_profile_pic"
                      src={logo ? logo : ASSETS.businessLogo}
                      alt=""
                    />
                    <div
                      className="edit"
                      onClick={() => imageRef.current.click()}
                    >
                      <span className="material-symbols-outlined">edit</span>
                    </div>
                    {userDetails?.businessInfo?.logo && (
                      <div
                        className="close_media"
                        onClick={() => removeBusinessLogo()}
                      >
                        <span className="material-symbols-outlined">close</span>
                      </div>
                    )}
                  </div>

                  <div className="vg8"></div>
                  <h6 className="r12 grey">Logo (optional)</h6>
                </div>
              </div>
              {modalOpen && (
                // <ImageCropper
                //   show={modalOpen}
                //   actionHandler={actionHandler}
                //   onHide={() => actionHandler(null, "close")}
                //   image={logo}
                //   setFieldValue={setFieldValue}
                // />
                <CommonImageCropper
                  show={modalOpen}
                  actionHandler={actionHandler}
                  onHide={() => actionHandler(null, "close")}
                  image={originSelectUrl}
                  setFieldValue={(file) => setFieldValue("logo", file)}
                  originalImage={originSelectImage}
                  imageSettings={IMAGE_SETTINGS.SIZE_200_X_200}
                />
              )}

              <div className="vg22"></div>
              <div className="col-8">
                <div className="form_field">
                  {/* <label htmlFor="title">
                    {LABELS.BUSINESS_NAME[categoryName] || ""}*
                  </label> */}
                  <label htmlFor="title">{LABELS.BUSINESS_TITLE[categoryName] || ""} Name*</label>
                  <Field
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Enter name"
                  />{" "}
                  <ErrorMessage name="title" component={TextError} />
                </div>
              </div>
              <div className="col-4 pl0">
                <div className="form_field">
                  <label htmlFor="designation">designation*</label>
                  <Field
                    as="select"
                    name="designation"
                    id="businessdesignation"
                  >
                    {" "}
                    <option value="">Please select a designation</option>
                    {DESIGNATION_LISTS.map((d, i) => (
                      <option key={i} value={d}>
                        {d}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="designation" component={TextError} />
                </div>
              </div>
              <div className="ff_gap"></div>
              <div className="col-12">
                <div className="form_field">
                  <label
                    htmlFor="businessbio"
                    className="d-flex justify-content-between mb-2"
                  >
                    <div>
                      Bio of {LABELS.BUSINESS_TITLE[categoryName] || ""}*
                    </div>
                    <div
                      className="grad_text gt_border"
                      onClick={() => {
                        if (contentIndex !== 7) {
                          setContentIndex(contentIndex + 1);
                        } else {
                          setContentIndex(0);
                        }

                        let content = generateBio(
                          contentIndex,
                          values.title,
                          LABELS.SINGLE_SERVICE_HEADER[categoryName],
                          categoryName
                        );
                        setFieldValue("bio", content);
                        setAttempt(attempt + 1);
                      }}
                 
                    >
                      {attempt > 0 ? "Try more" : "Writing using AI"} {"-"}{" "}
                      {contentIndex}/{STATIC_BUSINESS_BIO.length}
                    </div>
                  </label>
                  <Field
                    as="textarea"
                    name="bio"
                    id="businessbio"
                    placeholder="Describe your business"
                    style={{ whiteSpace: "pre-line", resize: "none" }}
                    wrap="soft"
                  />
                  <ErrorMessage name="bio" component={TextError} />
                </div>
              </div>
              <div className="ff_gap"></div>
              <div className="col-12">
                <div className="form_field">
                  <label htmlFor="whatsapNumber">
                    Email
                    <span>(optional)</span>
                  </label>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    placeholder="eg. abc@gmail.com"
                  />{" "}
                  <ErrorMessage name="email" component={TextError} />
                </div>
              </div>
              <div className="ff_gap"></div>
              <div className="col-4">
                <div className="form_field">
                  <label htmlFor="countryId">Country</label>
                  <Field
                    as="select"
                    name="countryId"
                    id="countryId"
                    onChange={(e) =>
                      handleCountryChange(e.target.value, setFieldValue)
                    }
                    value={values.countryId}
                  >
                    {" "}
                    <option value="">Select</option>
                    {(countries ?? []).map((country, i) => (
                      <option key={`country_${i}`} value={country.countryId}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="countryId" component={TextError} />
                </div>
              </div>
              <div className="col-4 pl0">
                <div className="form_field">
                  <label htmlFor="stateId">State*</label>
                  <Field
                    as="select"
                    name="stateId"
                    id="stateId"
                    onChange={(e) =>
                      handleStateChange(e.target.value, setFieldValue)
                    }
                    value={values.stateId}
                  >
                    {" "}
                    <option value="">Select</option>
                    {(states ?? []).map((state, i) => (
                      <option key={`state_${i}`} value={state.stateId}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="stateId" component={TextError} />
                </div>
              </div>
              <div className="col-4 pl0">
                <div className="form_field">
                  <label htmlFor="cityId">City*</label>
                  <Field as="select" name="cityId" id="cityId">
                    <option value="">Select</option>
                    {(cities ?? []).map((city, i) => (
                      <option key={`city_${i}`} value={city.cityId}>
                        {city.name}
                      </option>
                    ))}
                  </Field>{" "}
                  <ErrorMessage name="cityId" component={TextError} />
                </div>
              </div>
              <div className="ff_gap"></div>
              <div className="col-12">
                <div className="form_field">
                  <label htmlFor="fullAddress">
                    Full Address of {LABELS.BUSINESS_TITLE[categoryName] || ""}*
                  </label>
                  <Field
                    as="textarea"
                    name="fullAddress"
                    id="fullAddress"
                    placeholder="Enter address"
                  />{" "}
                  <ErrorMessage name="fullAddress" component={TextError} />
                </div>
              </div>
              <div className="ff_gap"></div>
              <div className="col-12">
                <div className="form_field">
                  <label
                    htmlFor="businessgoogleLink"
                    className="field_optional"
                  >
                    Google Map Link (optional)
                  </label>
                  <Field
                    type="text"
                    name="googleLink"
                    id="googleLink"
                    placeholder="Paste map link"
                  />{" "}
                  <ErrorMessage name="googleLink" component={TextError} />
                </div>
              </div>
              <div className="ff_gap"></div>
              <div className="col-12">
                <div className="form_field">
                  <label htmlFor="slider_img" className="field_optional">
                    Upload Slider Images (optional)
                  </label>
                  <div className="vg8"></div>
                  {/* <div className="v_slider_img">
                      {[
                        "./assets/img/home_banner.jpg",
                        "./assets/img/home_banner2.jpg",
                        "./assets/img/home_banner3.jpg",
                      ].map((image, img_index) => (
                        <div className="vsi_single" key={img_index}>
                          <img src={image} alt="" />
                        </div>
                      ))}
                    </div> */}
                  <div className="v_slider_img">
                    {images.map((image, img_index) => (
                      <div className="vsi_single" key={img_index}>
                        <img src={image.url} alt="" />

                        <div
                          className="close_media"
                          onClick={() =>
                            removeSelectedImage(
                              img_index,
                              values,
                              setFieldValue
                            )
                          }
                        >
                          <span className="material-symbols-outlined">
                            close
                          </span>
                        </div>
                      </div>
                    ))}
                    {images.length < 3 && (
                      <div className="">
                        <input
                          type="file"
                          hidden
                          ref={imagesRef}
                          onChange={(e) =>
                            onFileSelect(e, values, setFieldValue)
                          }
                        />

                        <div
                          className="vsi_single"
                          onClick={() => imagesRef.current.click()}
                        >
                          <img src={ASSETS.uploadIcon} alt="" />
                        </div>
                      </div>
                    )}
                    {slideModelOpen && (
                      <CommonImageCropper
                        show={slideModelOpen}
                        actionHandler={actionSliderHandler}
                        onHide={() => actionSliderHandler(null, "close")}
                        image={originSelectUrl}
                        setFieldValue={(file) =>
                          setSliderImages(values, file, setFieldValue)
                        }
                        originalImage={originSelectImage}
                        imageSettings={IMAGE_SETTINGS.SIZE_300_X_200}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="vg22"></div>
              <div className="col-12">
                <button
                  type="submit"
                  className="theme_btn"
                  disabled={submitting}
                >
                  {submitting ? (
                    <ActivityIndigator />
                  ) : (
                    <>{heading !== undefined ? "Save" : "Save & Next"}</>
                  )}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ form, common }) => {
  return { submitting: form.submitting, ...common };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
    saveBusinessInfo: (payload, goBackOrNext) =>
      dispatch(saveBusinessInfo(payload, goBackOrNext)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BussinessInfo);
