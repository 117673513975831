import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateShoppingCart } from "../store/actions/publicActions";
import { showSuccess } from "../store/common/helpers/responseHelper";

const AddToCartButton = ({ item, qty, setQty }) => {
  const dispatch = useDispatch();
  const [hasCartItem, setHasCartItem] = useState(false);

  useEffect(() => {
    if (item?._id) {
      let userId = sessionStorage.getItem("userId") || undefined;
      let cart = JSON.parse(sessionStorage.getItem(userId)) || [];
      let cartItems = [...cart];
      const existingItemIndex = cartItems.findIndex(
        (cartItem) => cartItem._id === item._id
      );

      if (existingItemIndex !== -1) {
        setQty(cartItems[existingItemIndex].qty);
        setHasCartItem(true);
      } else {
        setHasCartItem(false);
      }
    }
  }, [item]);

  const handleAddToCart = () => {
    if (item !== undefined && Object.keys(item).length === 0) {
      return;
    }
    let userId = sessionStorage.getItem("userId") || undefined;
    if (userId === undefined) return;
    let cart = JSON.parse(sessionStorage.getItem(userId)) || [];
    let updatedCart = [...cart];

    // Check if the item already exists in the cart
    const existingItemIndex = updatedCart.findIndex(
      (cartItem) => cartItem._id === item._id
    );
    if (existingItemIndex !== -1) {
      // If item exists, update its quantity
      updatedCart[existingItemIndex].qty = qty;
      showSuccess("Cart Item Updated");
    } else {
      // If item doesn't exist, add it to the cart
      updatedCart.push({ ...item, qty: qty });
      showSuccess("Item added in the cart");
    }

    sessionStorage.setItem(userId, JSON.stringify(updatedCart));
    // disptach action
    dispatch(updateShoppingCart());
  };

  return (
    <button
      type="button"
      className="theme_btn btn_border btn_with_icon"
      onClick={handleAddToCart}
      disabled={qty === 0}
      style={{
        gap:"10px",
        paddingLeft:"0px",
        paddingRight:"0px"
      }}
    >
      <span class="material-symbols-outlined">
shopping_cart
</span>
      {hasCartItem ? "Update Cart" : "Add to Cart"}
    </button>
  );
};

export default AddToCartButton;
