import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getSession } from "../store/actions/authActions";
import Modal from "react-bootstrap/Modal";
// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import SiteFooterTop from "./SiteFooterTop";
import GoogleMetaTag from "../component/GoogleMetaTag";
import useScrollToTop from "../common/hooks/useScrollToTop";
import LiveChatScript from "./LiveChatScript";

const SiteAboutUs = () => {
  let { Authorization } = getSession();
  useScrollToTop();

  //   modal for view demo website links
  const [showDemoWebsite, setShowDemoWebsite] = useState(false);

  const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
  const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
  //   modal for view demo website links
  return (
    <div className="website_pages site_aboutus">
      <GoogleMetaTag title={"BusinessBaab- Your Go-To Service for Website"} content={"To build your dream website, trust BusinessBaab. Our platform lets you create a website without any coding. Design your site easily, on your own schedule."} />
      <SiteHeader />
      <div className="page">
        <div className="container">
          <div className="text-center">
            <h1 className="pgtitle">About BusinessBaab</h1>
          </div>
          <div className="sitevg40"></div>
          <div className="pgtitle_b_desc">
            {/* यहाँ आप बिना किसी कोडिंग ज्ञान या बिना किसी आईटी कंपनी की सहायता के,
            केवल 10 मिनट में अपनी खुद की पेशेवर वेबसाइट बना सकते हैं वो भी सिर्फ
            ₹150 माह में । */}
            Here you can create your own professional website in just 15 minutes without any coding knowledge or help from any IT company and that too for just ₹150 per month.
          </div>
          <div className="sitevg30"></div>
          <div className="about_banner">
            <img src="./assets/bbsite_images/about_main_img.svg" alt="" />
          </div>
          <div className="vg22"></div>
          <div className="theme_btn_site text-center">
            {Authorization ? (
              <Link to="/account"> My Account</Link>
            ) : (
              <Link to="/region">Create Website @ just ₹150/m</Link>
            )}
          </div>
          {/* {!Authorization && (
            <>
              <div className="vg12"></div>
              <Link to="/region" className="animated_text text-center">
                Free Trial Available for 7 Days
              </Link>
            </>
          )} */}
          <div className="vg12"></div>
          <div
            className="grad_text d-flex align-items-center justify-content-center"
            style={{
              gap: "5px",
            }}
            onClick={handleShowDemoWebsiteModal}
          >
            <span
              class="material-symbols-outlined"
              style={{
                fontSize: "20px",
              }}
            >
              visibility
            </span>
            <span className="gt_border grad_text">View Demo Website</span>
          </div>
          <div className="sect_gap"></div>
        </div>
        <section
          className="why_businessbaab"
          style={{
            background: "#D6EFFF",
          }}
        >
          <div class="text-center">
            <h1 class="pgtitle">Why BusinessBaab</h1>
          </div>
          <div className="sitevg30"></div>
          <div className="container">
          <div className="row vertical_center">
            <div className="col-lg-4 left">
              <h5>
                {/* BusinessBaab में, हम आज की ऑनलाइन दुनिया में छोटे व्यवसायों के
                सामने आने वाली चुनौतियों को समझते हैं। इसीलिए हम लाए हैं एक ऐसा
                प्लेटफ़ॉर्म, जो वेबसाइट बनाने की प्रक्रिया को सरल बनाता है। आप
                बस अपने बिज़नेस पर ध्यान दो, टेक्नोलॉजी की सारी प्रोब्लेम्स हम पर
                छोड़ दो सिर्फ ₹150 माह में। */}
                At BusinessBaab, we understand the challenges small businesses face in today's online world. That's why we have come up with a platform that simplifies the process of building a website. You just focus on your business, leave all the technology problems to us for just ₹ 150 per month.
              </h5>
            </div>
            <div className="col-lg-4">
              <div className="about_tech_img">
                <img src="./assets/bbsite_images/about_tech_img.svg" alt="" />
              </div>
            </div>
            <div className="col-lg-4 right">
              <h5>
                {/* आज ही BusinessBaab से जुड़ें और अपने बिज़नेस को ऑनलाइन ले जाने की
                दिशा में पहला कदम बढ़ाएं। आइए हम आपकी ऑनलाइन उपस्थिति बनाने और
                सफलता की नई ऊंचाइयों तक पहुंचने में आपकी सहायता करें। */}
                Join BusinessBaab today and take the first step towards taking your business online. Let us help you build your online presence and reach new heights of success.
              </h5>
              <div className="vg22"></div>
              <div className="theme_btn_site">
                {Authorization ? (
                  <Link to="/account"> My Account</Link>
                ) : (
                  <Link to="/region">Create Website @ just ₹150/m</Link>
                )}

              </div>
              {/* {!Authorization && (
                <>
                  <div className="vg12"></div>
                  <Link to="/region" className="animated_text">
                    Free Trial Available for 7 Days
                  </Link>
                </>)} */}
              <div className="vg12"></div>
              <div className="grad_text d-flex align-items-center justify-content-sm-center justify-content-md-start justify-content-lg-start
              justify-content-center" style={{
                  gap: "5px"
                }}
                onClick={handleShowDemoWebsiteModal}>
                <span class="material-symbols-outlined" style={{
                  fontSize: "20px"
                }}>
                  visibility
                </span>
                <span className="gt_border grad_text">View Demo Website</span>
              </div>
            </div>
          </div>
          </div>
        </section>
        <section className="vision_mission">
          <div className="container">
            <div className="row vertical_center">
              <div className="col-md-6">
                <div className="left">
                  <div className="theme_title">
                    <h2 className="white">Vision and Mission</h2>
                  </div>
                  <div className="content">
                    <p>
                      Our vision is to empower individuals and businesses to
                      build their online presence effortlessly and affordably.
                    </p>
                    <p>
                      Our mission is to provide a user-friendly platform that
                      enables anyone to create a website quickly and easily,
                      allowing them to focus on their passion and expertise.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right mission_img">
                  <img src="./assets/bbsite_images/mission.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <SiteFooterTop />
      </div>
      <SiteFooter />
      {/* <LiveChatScript/> */}
      {/* modal for show demo website links  */}
      <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
            यहाँ कुछ ऑरिजिनल वेबसाइट्स की लिंक्स दी गयी हैं, जो BusinessBaab के
            द्वारा बनाई गयी हैं।
          </h6>
          <h6 className="r16 text-start">
            आपकी वेबसाइट भी ऐसी ही दिखेगी, इन्हें आप देख सकते हैं।
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to="https://businessbaab.com/absne" className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">visibility</span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">arrow_forward</span>
            </Link>
            <Link
              to="https://businessbaab.com/shine.collections"
              className="dwl_single"
            >
              <div className="left">
                <span class="material-symbols-outlined">visibility</span>
                Shine Collection
              </div>

              <span class="material-symbols-outlined">arrow_forward</span>
            </Link>
            <Link
              to="https://businessbaab.com/shree_sanwariya_decorations"
              className="dwl_single"
            >
              <div className="left">
                <span class="material-symbols-outlined">visibility</span>
                <span>Shree Sanwariya Decoration</span>
              </div>

              <span class="material-symbols-outlined">arrow_forward</span>
            </Link>
            <Link to="https://businessbaab.com/a2z" className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">visibility</span>A 2 Z
                Collections
              </div>

              <span class="material-symbols-outlined">arrow_forward</span>
            </Link>
            <Link
              to="https://businessbaab.com/bushra-graphics"
              className="dwl_single"
            >
              <div className="left">
                <span class="material-symbols-outlined">visibility</span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">arrow_forward</span>
            </Link>
            <Link
              to="https://businessbaab.com/almadeeha"
              className="dwl_single"
            >
              <div className="left">
                <span class="material-symbols-outlined">visibility</span>
                Al-Madeeha Perfumes
              </div>

              <span class="material-symbols-outlined">arrow_forward</span>
            </Link>
          </div>  
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}>Okay, Got it.</div>
      </Modal>
    </div>
  );
};

export default SiteAboutUs;
