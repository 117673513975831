import axios from "axios";
import { BASE_URL } from "./constants";
import { getSession } from "../actions/authActions";

const instance = axios.create({
  baseURL: BASE_URL,
  // timeout: 5000, // Timeout in milliseconds
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
});

// Add request interceptor
instance.interceptors.request.use(
  (config) => {
    let { Authorization } = getSession();
    if (Authorization) {
      config.headers.Authorization = `${Authorization}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setToken = () => {
  let { Authorization } = getSession();
  return (instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${Authorization}`);
};

export default instance;
