import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import AddToCartButton from "../../../component/AddToCartButton";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import {
  getPublicServiceDetail,
  resetSinglServiceDetails,
  updateShoppingCart,
} from "../../../store/actions/publicActions";
import { connect, useDispatch } from "react-redux";
import ASSETS from "../../../common/images";
import { LABELS } from "../../../common/label";
import useScrollToTop from "../../../common/hooks/useScrollToTop";
import { onShareHelper } from "../../../common/helpers/_share";

const VehiclePackageSingleDetails = ({
  getPublicServiceDetail,
  loading,
  singleService,
  categoryName,
  resetSinglServiceDetails,
}) => {
  // add class before min width 992  start
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // add class before min width 992  end
  const [qty, setQty] = useState(1);
  let { _id, username } = useParams();
  const navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    getPublicServiceDetail(_id);
    return () => resetSinglServiceDetails();
  }, [_id, getPublicServiceDetail]);

  useScrollToTop();

  const onShare = async () => {
    onShareHelper(`${window.location}`);
  };
  let labels = {
    packageInfo: "Package Details",
    vehicleInfo: "Vehicle Details",
    serviceInfo: "Service Details",
  };

  const handleContinue = () => {
    let userId = sessionStorage.getItem("userId") || undefined;
    if (userId === undefined) return;
    let cart = JSON.parse(sessionStorage.getItem(userId)) || [];
    let updatedCart = [...cart];

    // Check if the item already exists in the cart
    const existingItemIndex = updatedCart.findIndex(
      (cartItem) => cartItem._id === singleService._id
    );
    if (existingItemIndex !== -1) {
      // If item exists, update its quantity
      updatedCart[existingItemIndex].qty = qty;
    } else {
      // If item doesn't exist, add it to the cart
      updatedCart.push({ ...singleService, qty: qty });
    }

    sessionStorage.setItem(userId, JSON.stringify(updatedCart));
    // disptach action
    dispatch(updateShoppingCart());

    sessionStorage.setItem("serviceType", singleService?.type);

    if (singleService?.type === "serviceInfo") {
      navigate(`/${username}/booknow?title=${singleService?.title}`);
    } else {
      navigate(`/${username}/checkout`);
    }
  };

  //   modal
  const [showMyProduct, setShowMyProduct] = useState(false);

  const handleCloseMyProductModal = () => setShowMyProduct(false);
  const handleShowMyProductModal = () => setShowMyProduct(true);
  //   modal

  return (
    <div className="portal_page_spacing">
      {loading ? <ActivityLoader /> : null}
      <div className={isMobile ? "" : "container"}>
      <PortalNavbar
        title={labels[singleService.type] || ""}
        backgroundColor="#D6EFFF"
        backArrow="arrow_back"
      />
      </div>
      <div className="desktop_sect_width dtp">
      <div className="psdetail">
        <div className="product_short_info relative tt_vehicle_single">
          <div className="pic_price">
            {singleService.type !== "serviceInfo" && (
              <div className="pic">
                <img
                  src={
                    singleService?.thumbnail
                      ? singleService?.thumbnail
                      : ASSETS.dummyproductimg
                  }
                  alt={singleService?.title}
                />
                {singleService?.thumbnail && (
                  <>
                    <div className="vg6"></div>
                    <div className={isMobile ? "text-center" : ""}>
                      <div className="grad_text  gt_border"
                        onClick={handleShowMyProductModal}>
                        View
                      </div>
                    </div>
                  </>
                )}

                {/* modal for product image  */}
                <Modal
                  show={showMyProduct}
                  onHide={handleCloseMyProductModal}
                  className="my_modal modal_top"
                >
                  <Modal.Header className="d-flex">
                    {/* <Modal.Title className="m18">{singleService?.thumbnail ? (
                    ""
                  ) : (
                    "Alert"
                  )}</Modal.Title> */}
                    <span
                      class="material-symbols-outlined modal_close red"
                      onClick={handleCloseMyProductModal}
                    >
                      close
                    </span>
                  </Modal.Header>
                  <div className="vg22"></div>
                  <Modal.Body>
                    <h6>
                      {singleService?.thumbnail ? (
                        <>
                          {/* {singleService.title} product image */}
                        </>
                      ) : (
                        "Image is not available :("
                      )}
                    </h6>
                    {singleService?.thumbnail &&
                      <div className="modal_product_img">
                        <img
                          src={
                            singleService?.thumbnail
                          }
                          alt={singleService?.title}
                          className="w-100"
                        />
                      </div>
                    }

                  </Modal.Body>
                </Modal>
              </div>
            )}
            <div className={`price ${singleService?.type}`}>
              <div className="tt_ss_top">
                {singleService?.title === "Hotel Booking" && (
                  <img src="/assets/img/service_hotel.png" alt="" />
                )}
                {singleService?.title === "Railway Ticket Booking" && (
                  <img src="/assets/img/service_train.png" alt="" />
                )}
                {singleService?.title === "Air Ticket Booking" && (
                  <img src="/assets/img/service_plain.png" alt="" />
                )}
                {singleService?.title === "Bus Ticket Booking" && (
                  <img src="/assets/img/service_bus.png" alt="" />
                )}
                <h5 className="m18">
                  {singleService?.title || ""}
                </h5>
              </div>
              {singleService?.type === "vehicleInfo" && (
                <>
                  <h6 className="">
                    <span className="purple sb14">
                      {singleService?.pricePerKm ? (
                        <span>₹{singleService?.pricePerKm || 0}/km</span>
                      )
                        :
                        "Price Negotiable"}


                    </span>{" "}
                    {singleService?.minimumPrice > 0 && (
                      <span className="r14
                      ">(₹{singleService?.minimumPrice} min.)</span>
                    )}
                  </h6>
                  <div className="r12
                  ">
                    {singleService?.totalSeats || 0} seats
                    {singleService?.features?.length > 0 && (
                      <span> | {singleService?.features.join(" | ")}</span>
                    )}
                  </div>
                </>
              )}
              {singleService?.type === "packageInfo" && (
                <>
                  <h6 className="sb14 purple">₹{singleService?.price || 0} </h6>
                  <div className="r12">
                    {singleService?.totalPerson || 0} person
                    {singleService?.isDoorToDoor && <span> | Door to Door</span>}
                  </div>
                </>
              )}

            </div>
          </div>
          <div className="vg12"></div>
          <div className="product_id">
            ID : #{singleService?.serviceNo || 0}
          </div>
          <div className="vg12"></div>
          <p className="r14 textarea_text">
            {singleService?.description || ""}
          </p>
          <div className="vg22"></div>

          <div className="row">
            {/* {singleService?.type !== "serviceInfo" && (
              <div className="col-6">
                <AddToCartButton
                  item={singleService}
                  qty={qty}
                  setQty={setQty}
                />
              </div>
            )} */}
            <div className="col-6">
              <button
                type="button"
                onClick={handleContinue}
                className="theme_btn"
              >
                {singleService?.type !== "serviceInfo"
                  ? "Continue"
                  : "Book Now"}
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
  
    </div>
  );
};

const mapStateToProps = ({ profile: { singleService, loading } }) => {
  return {
    loading,
    singleService,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPublicServiceDetail: (_id) => dispatch(getPublicServiceDetail(_id)),

    resetSinglServiceDetails: () => dispatch(resetSinglServiceDetails()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclePackageSingleDetails);
