import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import vendor navbar
import VendorNavbar from "./VendorNavbar";
import usePageTitle from "../common/hooks/usePageTitle";
const Filter = () => {
  const initialValues = {};

  const handleSubmit = (values) => {};

  //   modal
  const [showCustomDate, setShowCustomDate] = useState(false);

  const handleCloseCustomDateModal = () => setShowCustomDate(false);
  const handleShowCustomDateModal = () => setShowCustomDate(true);
  //   modal
  usePageTitle("Filters");
  return (
    <div className="filter relative">
      <div className="clear_filter grad_text gt_border">Clear</div>
      <div className="filter_title blue r14">Total Orders = 1,522</div>
      <div className="vg30"></div>
      <div className="all_filters">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="filter_single">
              <h6 className="filter_name r14 blue">Period</h6>
              <div className="vg12"></div>
              <div className="filter_fields with_custom_filter">
                <div className="radio_group">
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="Period" value="lifetime" />
                      Lifetime
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="Period" value="last7days" />
                      Last 7 Days
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="Period" value="last_30_days" />
                      Last 30 Days
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="Period" value="last_3_month" />
                      Last 3 Months
                    </label>
                  </div>
                </div>
                <div
                  className="custom_filter grad_text gt_border"
                  onClick={handleShowCustomDateModal}
                >
                  custom date
                </div>
              </div>
              {/* modal for delete order  */}
              <Modal
                show={showCustomDate}
                onHide={handleCloseCustomDateModal}
                className="my_modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title className="m18">Custom Date</Modal.Title>
                  <span
                    class="material-symbols-outlined modal_close red"
                    onClick={handleCloseCustomDateModal}
                  >
                    close
                  </span>
                </Modal.Header>
                <div className="vg22"></div>
                <Modal.Body>
                  <div className="row">
                    <div className="col-6">
                      <div className="form_field">
                        <label htmlFor="from_date">From</label>
                        <Field name="fromDate">
                          {({ form, field }) => (
                            <DatePicker
                              id="fromDate"
                              {...field}
                              placeholderText="dd/MM/yyyy"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="col-6 pl0">
                      <div className="form_field">
                        <label htmlFor="from_date">To</label>
                        <Field name="toDate">
                          {({ form, field }) => (
                            <DatePicker
                              id="toDate"
                              {...field}
                              placeholderText="dd/MM/yyyy"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <div className="vg22"></div>
                <div className="modal_btn">Apply</div>
              </Modal>
            </div>
            <div className="sect_divider"></div>
            <div className="filter_single">
              <h6 className="filter_name r14 blue">Order by</h6>
              <div className="vg12"></div>
              <div className="filter_fields">
                <div className="radio_group">
                  <div className="radio_field">
                    <label>
                      <Field
                        type="radio"
                        name="order_by"
                        value="newest_first"
                      />
                      Newest first
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field
                        type="radio"
                        name="order_by"
                        value="oldest_first"
                      />
                      Oldest first
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="sect_divider"></div>
            <div className="filter_single">
              <h6 className="filter_name r14 blue">Country</h6>
              <div className="vg12"></div>
              <div className="filter_fields">
                <div className="radio_group">
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      All
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      India<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      Sri Lanka<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      pakistan<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      nepal<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      bangladesh<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      U.K.<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="country" value="" />
                      USA<span>(556)</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="sect_divider"></div>
            <div className="filter_single">
              <h6 className="filter_name r14 blue">State</h6>
              <div className="vg12"></div>
              <div className="filter_fields">
                <div className="radio_group">
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      All
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      Madhya pradesh<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      uttar pradesh<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      jharkhand<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      oddisa<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      rajasthan<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      mumbai<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="state" value="" />
                      goa<span>(556)</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="sect_divider"></div>
            <div className="filter_single">
              <h6 className="filter_name r14 blue">City</h6>
              <div className="vg12"></div>
              <div className="filter_fields">
                <div className="radio_group">
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      all
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      Ujjain<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      indore<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      bhopal<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      vidisha<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      shajapur<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      dewas<span>(556)</span>
                    </label>
                  </div>
                  <div className="radio_field">
                    <label>
                      <Field type="radio" name="city" value="" />
                      gwalior<span>(556)</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="vg30"></div>
            <button className="theme_btn">Apply Filter</button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Filter;
