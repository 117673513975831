import { APIs } from "../common/constants";
import { showError, showThrowError } from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
export const getCategoryList = () => async (dispatch) => {
  try {
    // setToken();
    dispatch({
      type: "FETCH_CATEGORY_LIST",
    });
    const { data } = await axios.get(APIs.GET_ALL_CATEGORIES);

    if (data.status) {
      dispatch({
        type: "SET_CATEGORY_LIST",
        payload: data.data || [],
      });
    }
    if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "SET_CATEGORY_FAILURE",
      err,
    });
    showThrowError(err);
  }
};
