import React from 'react'
import { Link } from 'react-router-dom'

const WhatsappIcon = () => {
    return (


        <Link to="https://wa.me/917415693026" className='footer_whatsapp' target='_blank'>
            <img src="/assets/img/footer_whatsapp.png" alt="" />
        </Link>

    )
}

export default WhatsappIcon
