import React from "react";
import VendorNavbar from "../component/VendorNavbar";
import { Link } from "react-router-dom";

const PreventProfile = ({ type }) => {
  return (
    <div className="vendor_page_spacing">
      <div className="create_protal align_center_pg">
        <div className="cp_inner acp_inner text-center">
          <div className="vg22"></div>
          <h5 className="m15 line_hight">
            {type === "stopped" && <span>Temporary Stopped</span>}
            {type === "blocked" && <span>Profile Blocked</span>}
          </h5>
          <div className="vg22"></div>
          <Link to="/" className="theme_btn m-auto">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};
export default PreventProfile;
