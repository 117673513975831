import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = ({ service, username }) => {

  return (
    <div className="product_desc">
      <div className="left">
        {service?.title === "Hotel Booking" && (
          <img src="/assets/img/service_hotel.png" alt="" />
        )}
        {service?.title === "Railway Ticket Booking" && (
          <img src="/assets/img/service_train.png" alt="" />
        )}
        {service?.title === "Air Ticket Booking" && (
          <img src="/assets/img/service_plain.png" alt="" />
        )}
        {service?.title === "Bus Ticket Booking" && (
          <img src="/assets/img/service_bus.png" alt="" />
        )}

      </div>
      <div className="right d-flex justify-content-between align-items-center" style={{
        width:"100%"
      }}>
        <div>
          <h5 className="price m16">{service?.title || ""}</h5>
          {/* <h6 className="r14 only_one_line">{service?.description || ""}</h6> */}
          <div className="vg8"></div>
          <Link
            to={`/${username}/travel/details/${service?._id}`}
            className="theme_btn short_btn view_btn "
          >
            Know More
          </Link>
          </div>
        <span class="material-symbols-outlined arrow">
          arrow_forward
        </span>
      </div>



    </div>
  );
};

export default ServiceCard;
