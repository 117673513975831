import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Formik, Field, Form } from "formik";

import Switch from "react-switch"; // npm install react-switch
import usePageTitle from "../../../common/hooks/usePageTitle";
import { connect, useDispatch } from "react-redux";
import { changeUserStatus } from "../../../store/actions/userActions";
import AccountDelete from "./AccountDelete";
import {
  sendOtpForDeleteAccount,
  sentOTPForConfirmNumber,
  verifyActionOTP,
} from "../../../store/actions/authActions";
import ChangeMobileNumber from "./ChangeMobileNumber";

const Settings = ({
  userDetails,
  tempInfo,
  submitting,
  sendOtpForDeleteAccount,
  verifyActionOTP,
  sentOTPForConfirmNumber,
}) => {
  const dispatch = useDispatch();
  const handleChange = (checked) => {
    dispatch(
      changeUserStatus({
        type: "hideMe",
        hideMe: checked,
      })
    );
  };
  // react switch
  usePageTitle("Settings");
  return (
    <div className="pg_setting">
      <Accordion defaultActiveKey="" className="theme_accordion style_2">
        <ChangeMobileNumber
          submitting={submitting}
          tempInfo={tempInfo}
          userDetails={userDetails}
          sentOTPForConfirmNumber={sentOTPForConfirmNumber}
          verifyActionOTP={verifyActionOTP}
        />
        <Accordion.Item eventKey="1" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Temporary Stop my Account</h5>
            </div>
            <div className="right">
              <span className="material-symbols-outlined open icon_grad">
                add
              </span>
              <span className="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex align-items-center">
              <h6 className="r14" style={{
                opacity: userDetails?.hideMe ? "1" : "0.5"
              }}>             
                Temporary Stop
              </h6>
              <div
                className={`theme_switch my_switch ${
                  userDetails?.hideMe ? "off" : "on"
                }`}
                style={{
                  marginLeft:"10px",
                  lineHeight:"0px",
                  marginRight:"10px"
                }}
              >
                <div className="switch">
                  <Switch
                    onChange={handleChange}
                    checked={userDetails?.hideMe || false}
                  />
                </div>
              </div>
              <h6 className="r14"
              style={{
                opacity: userDetails?.hideMe ? "0.5" : "1"
              }}>             
                Active
              </h6>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <AccountDelete
          submitting={submitting}
          sendOtpForDeleteAccount={sendOtpForDeleteAccount}
          tempInfo={tempInfo}
          verifyActionOTP={verifyActionOTP}
        />
      </Accordion>
    </div>
  );
};
const mapStateToProps = ({ form, auth }) => {
  return {
    submitting: form.submitting,
    success: form.success,
    tempInfo: auth.tempInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    verifyActionOTP: (data, onAction) =>
      dispatch(verifyActionOTP(data, onAction)),
    sendOtpForDeleteAccount: (setShowOtp) =>
      dispatch(sendOtpForDeleteAccount(setShowOtp)),
    sentOTPForConfirmNumber: (payload, setStep) =>
      dispatch(sentOTPForConfirmNumber(payload, setStep)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);















