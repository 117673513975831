import React, { useState, useEffect } from "react";

// portal navbar
import PortalNavbar from "../PortalNavbar";

const PAboutUs = ({ profile: { personalInfo, businessInfo } }) => {
    // add class before min width 992  start
const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
// add class before min width 992  end
  return (
    <div className="portal_page_spacing">
    <div className={isMobile ? "" : "container"}>
    <PortalNavbar
        title="About Us"
        backgroundColor="#D6EFFF"
        backArrow="arrow_back"
      />
      <div className="desktop_sect_width dtp">
      <div className="paboutus">
        <div className="about_protal">
          <h5 className="m16 purple">{businessInfo?.title || ""}</h5>
          <div className="vg8"></div>
          <p className="r14">{businessInfo?.bio || ""}</p>
        </div>
        <div className="sect_divider"></div>
        <div className="about_portal">
          <h5 className="m16 purple">Owner</h5>
          <div className="vg8"></div>
          <h5
            className="m16"
            style={{
              marginBottom: "3px",
            }}
          >
            {personalInfo?.name || ""}
          </h5>
          <h6
            className="r14"
            style={{
              marginBottom: "1px",
            }}
          >
            {businessInfo?.designation || ""}
          </h6>
          <h6 className="r14">{businessInfo?.title || ""}</h6>
        </div>
      </div>
      </div>
      
    </div>
    </div>
  );
};

export default PAboutUs;
