import React from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { Accordion } from "react-bootstrap";

const Business = () => {
  usePageTitle("Business");
  return (
    <div className="pg_business">
      {/* <Accordion defaultActiveKey="0" className="theme_accordion style_2">
        <Accordion.Item eventKey="0" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">All Services</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Online Store</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Retail Shop</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Restaurant</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Doctor</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Hotels</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">School / College</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Travel</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Coaching</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Farming</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="height_limit">
              <li>
                <span class="material-symbols-outlined">done</span> IT Company
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Banking
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> All
                Electronic items
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Carpenter
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lawyer
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Video &
                Photography
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Housekeeping
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Vehicle
                Services
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
      <Accordion defaultActiveKey="0" className="theme_accordion style_2">
              <Accordion.Item eventKey="0" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Online Store</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Apparel
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Footwear
                    </li>                 
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Jewellery
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Electronics
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Boutique
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Plants
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">All Services</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Banking Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Electronic Item Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      IT Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Vehicle Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Furniture Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Home Decor Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Construction Works
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Home Cleaning Services 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Paint Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Clothes
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Entertainment & Media
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Policies Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Retail Shop</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Electronics
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Apparel 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Footwear 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Hardware 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Paints
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Boutique
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Undergarments
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Gift
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      General Items
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Home Decor
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Stationary
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Milk Parlour
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Vegetable and Fruits
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Computer Hardware 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Furniture 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Everfresh
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Medical
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Tour & Travels</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Travels
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Flight, Train, Bus and Taxi Booking
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Taxi Booking
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Personal Cab
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Persona Auto Rikshaw
                    </li>                    
                    <li>
                      <span class="material-symbols-outlined">done</span> And many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Restaurant</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Restaurant
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Dhaba
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Cafe
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Bakery
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Food & Snacks
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Education</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      School
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      College
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Tutorials
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Institute 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Personal Tuition
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Health Sector</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Hospital
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Physiotherapy
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Doctor's
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Clinic
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Laboratories 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
    </div>
  );
};

export default Business;
