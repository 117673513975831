import React, { useState } from "react";
import ASSETS from "../../../common/images";
import ProductForm from "../ProfileProcess/ProductForm";

const ProductItem = ({
  data,
  categoryName,
  thumbnail,
  handleSubmit,
  submitting,
}) => {
  const [editMode, setEditMode] = useState(false);
  return (
    <>
      {!editMode ? (
        <div className="my_accordion_body">
          <div className="product_short_info relative">
            <div className="name r14">{data?.title || ""}</div>
            <div className="vg8"></div>
            <div className="pic_price">
              <div className="pic">
                <img
                  src={thumbnail ? thumbnail : ASSETS.uploadSmallIcon}
                  alt=""
                />
              </div>
              <div className="price">
                <h5 className="sb16">₹ {data?.price || 0}</h5>
                {data?.quantity !== null && (
                  <h6 className="r14">{data?.quantity || 0} Pieces</h6>
                )}
              </div>
            </div>
            <div className="view" onClick={() => setEditMode(!editMode)}>
              <img src={ASSETS.editIcon} alt="" />
            </div>
          </div>
          <div className="ab_divider"></div>
          <div className="contact_person_info">
            <p className="r12">{data?.description || ""}</p>
          </div>
        </div>
      ) : (
        <div className="add_more_items_div relative">
          <div className="close_media" onClick={() => setEditMode(!editMode)}>
            <span className="material-symbols-outlined">close</span>
          </div>

          <ProductForm
            data={{ ...data, thumbnail }}
            handleSubmit={(values) => {
              handleSubmit(values);
              setEditMode(!editMode);
            }}
            submitting={submitting}
            index={data?._id}
            categoryName={categoryName}
          />
        </div>
      )}
    </>
  );
};

export default ProductItem;
