const initialState = {
  error: "",
  loading: false,
  lists: [],
  details: {},
  totalDocuments: 0,
  imageBackendUrl: null,
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SERVICE_LIST":
      return {
        ...state,
        loading: true,
      };
    case "SET_SERVICE_LIST":
      return {
        ...state,
        lists: [...action.payload],
        ...action.pagination,
        loading: false,
      };

    case "FETCH_SERVICE_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
        totalDocuments: 0,
      };
    case "SET_SERVICE_DETAIL":
      return { ...state, loading: false, details: action.payload };
    case "RESET_SERVICE_DETAIL":
      return { ...state, loading: false, details: {} };
    default:
      return state;
  }
};

export default serviceReducer;
