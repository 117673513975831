import { min } from "lodash";
import * as Yup from "yup";
const re3 =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z/]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
const re = /^(ftp|http|https):\/\/[^ "]+$/;
export const phoneNumberValidate = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(13, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 10 digits."),
});
export const personalInfoSchema = Yup.object().shape({
  profile_pic: Yup.string(),
  name: Yup.string().required("please enter your name").label("name"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),
  whatsapNumber: Yup.string()
    .required("Please enter valid whatsap number ")
    .label("whatsap number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a whatsap number")
    .min(10, "whatsap number must be at least 10 digits.")
    .max(13, "whatsap number must not be more than 13 digits."),

  // aboutMe: Yup.string()
  //   .required("please enter your short intro")
  //   .label("short bio"),

  // countryId: Yup.string().required("Please select a country").label("country"),
  // stateId: Yup.string().required("Please select a state").label("state"),
  // cityId: Yup.string().required("Please select a city").label("city"),
  // fullAddress: Yup.string()
  //   .required("please enter your full address")
  //   .label("full address"),
  social: Yup.array().of(
    Yup.object().shape({
      type: Yup.string()
        // .required("Please select a type")
        .label("type"),
      link: Yup.string()
        .matches(re, "please enter valid link only")
        // .required()
        .label("link"),
    })
  ),
});

export const businessInfoSchema = Yup.object().shape({
  profile_pic: Yup.string(),
  title: Yup.string().required("please enter your name").label("title"),
  designation: Yup.string()
    .required("please select your designation")
    .label("designation"),
  bio: Yup.string().required("please enter your bio").label("bio"),
  countryId: Yup.string().required("Please select a country").label("country"),
  stateId: Yup.string().required("Please select a state").label("state"),
  cityId: Yup.string().required("Please select a city").label("city"),
  fullAddress: Yup.string().required("please enter your full address"),
  googleLink: Yup.string().matches(
    re,
    "please enter valid google map link only"
  ),
});

export const paymentInfoSchema = Yup.object().shape({
  type: Yup.string().required("Please select a type").label("type"),
  link: Yup.string()
    // .matches(re, "please enter valid link only")
    .required("please enter your id")
    .label("link"),
});
export const serviceInfoSchema = Yup.object().shape({
  title: Yup.string().required("title is a required field").label("title"),
  description: Yup.string()
    // .required("description is a required field")
    .label("description"),
  quantity: Yup.number()
    .min(0)
    // .required("quantity is a required field")
    .typeError("quantity must be number"),
  price: Yup.number()
    .required("price is a required field")
    .typeError("price must be number"),
});

export const placeOrderSchema = Yup.object().shape({
  name: Yup.string().required("please enter your name").label("name"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),
  countryId: Yup.string().required("Please select a country").label("country"),
  stateId: Yup.string().required("Please select a state").label("state"),
  cityId: Yup.string().required("Please select a city").label("city"),
  fullAddress: Yup.string().required("please enter your full address"),
});

export const contactSchema = Yup.object().shape({
  name: Yup.string().required("please enter your name").label("name"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),

  message: Yup.string().required("please enter your query"),
});
export const promoterSchema = Yup.object().shape({
  name: Yup.string().required("please enter your name").label("name"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),
  countryId: Yup.string().required("Please select a country").label("country"),
  stateId: Yup.string().required("Please select a state").label("state"),
  cityId: Yup.string().required("Please select a city").label("city"),
  fullAddress: Yup.string().required("please enter your full address"),
  paymentMethod: Yup.string().required("please select a payment method"),
  upiIdOrNumber: Yup.string().required("please enter your upi id or number"),

  termsAndConditions: Yup.boolean()
    .oneOf([true], "You must agree to the terms and conditions")
    .required("You must agree to the terms and conditions"),
});
export const helpSupportSchema = Yup.object().shape({
  name: Yup.string().required("please enter your name").label("name"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),
  reason: Yup.string().required("please select your reason"),
  message: Yup.string().required("please enter your query"),
});

export const otpPhoneNumberValidate = Yup.object().shape({
  otp: Yup.string().required("please enter your otp"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(13, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 10 digits."),
});
export const vehicleInfoSchema = Yup.object().shape({
  title: Yup.string().required("title is a required field").label("title"),
  description: Yup.string()
    // .required("description is a required field")
    .label("description"),
  totalSeats: Yup.number()
    .min(0)
    .required("total seats is a required field")
    .typeError("value must be number"),
  minimumPrice: Yup.number()
    .required("minimum price is a required field")
    .typeError("minimum price must be number"),
  priceType: Yup.string(),

  pricePerKm: Yup.string().when("priceType", {
    is: (val) => val === "perkm",
    then: () =>
      Yup.number()
        .required("price per km is a required field")
        .typeError("price per km must be number"),
  }),
});
export const packageInfoSchema = Yup.object().shape({
  title: Yup.string().required("title is a required field").label("title"),
  description: Yup.string()
    // .required("description is a required field")
    .label("description"),
  totalPerson: Yup.number()
    .min(0)
    // .required("total person is a required field")
    .typeError("value must be number"),
  price: Yup.number()
    .required("price is a required field")
    .typeError("price must be number"),
});
export const travelServiceInfoSchema = Yup.object().shape({
  title: Yup.string().required("Please select a service").label("title"),
  description: Yup.string()
    .required("description is a required field")
    .label("description"),
});

export const bookingSchema = Yup.object().shape({
  name: Yup.string().required("please enter your name").label("name"),
  phoneNumber: Yup.string()
    .required("Please enter valid phone number ")
    .label("phone number")
    .matches(/^[+0-9\( )]*$/gi, "That doesn't look like a phone number")
    .min(10, "Phone number must be at least 10 digits.")
    .max(13, "Phone number must not be more than 13 digits."),

  otherDetails: Yup.object().shape({
    bookingType: Yup.string(),
    toDate: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () => Yup.string().required("Field is required"),
    }),
    fromCountryId: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () => Yup.string().required("Field is required"),
    }),
    toCountryId: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () => Yup.string().required("Field is required"),
    }),
    fromStateId: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () => Yup.string().required("Field is required"),
    }),
    toStateId: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () => Yup.string().required("Field is required"),
    }),
    fromCityId: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () => Yup.string().required("Field is required"),
    }),
    toCityId: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () => Yup.string().required("Field is required"),
    }),
    isReturn: Yup.boolean(),
    // returnDate: Yup.string().when("bookingType", {
    //   is: (val) => val === "other",
    //   then: () => Yup.string().required("Field is required"),
    // }),

    returnDate: Yup.string().when("bookingType", {
      is: (val) => val === "other",
      then: () =>
        Yup.string().when("isReturn", {
          is: (val) => val === true,
          then: () => Yup.string().required("Field is required"),
        }),
    }),

    //hotel booking
    checkInDate: Yup.string().when("bookingType", {
      is: (val) => val === "hotel",
      then: () => Yup.string().required("Field is required"),
    }),
    checkOutDate: Yup.string().when("bookingType", {
      is: (val) => val === "hotel",
      then: () => Yup.string().required("Field is required"),
    }),
    cityName: Yup.string().when("bookingType", {
      is: (val) => val === "hotel",
      then: () => Yup.string().required("Field is required"),
    }),
    totalAdult: Yup.number().when("bookingType", {
      is: (val) => val === "hotel",
      then: () =>
        Yup.number().required("Field is required").positive().integer(),
    }),
    totalChild: Yup.number().when("bookingType", {
      is: (val) => val === "hotel",
      then: () =>
        Yup.number().required("Field is required").positive().integer(),
    }),
  }),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required().min(6),
});
