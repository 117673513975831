import React, { useState } from "react";
import ASSETS from "../../../common/images";
import VehhicleForm from "../ProfileProcess/Travel/VehicleForm";

const VehicleItem = ({
  data,
  categoryName,
  thumbnail,
  handleSubmit,
  submitting,
}) => {
  const [editMode, setEditMode] = useState(false);
  return (
    <>
      {!editMode ? (
        <div className="my_accordion_body myvehicle">
          <div className="product_short_info relative">
            <div className="name m14 blue">{data?.title || ""}</div>
            <div className="vg8"></div>
            <div className="pic_price">
              <div className="pic">
                <img
                  src={thumbnail ? thumbnail : ASSETS.uploadSmallIcon}
                  alt=""
                />
              </div>
              <div className="price">
                <h6 className="">
                 <span className="sb14 purple"> ₹{data?.pricePerKm || 0}/km{" "}</span>
                  {data?.minimumPrice > 0 && (
                    <span className="r12">(₹{data?.minimumPrice} min.)</span>
                  )}
                </h6>
                <div className="r12">
                  {data?.totalSeats || 0} seats
                  {data?.features?.length > 0 && (
                    <span> | {data?.features.join(" | ")}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="view" onClick={() => setEditMode(!editMode)}>
              <img src={ASSETS.editIcon} alt="" />
            </div>
          </div>
          <div className="ab_divider"></div>
          <div className="contact_person_info">
            <p className="r12">{data?.description || ""}</p>
          </div>
        </div>
      ) : (
        <div className="add_more_items_div relative">
          <div className="close_media" onClick={() => setEditMode(!editMode)}>
            <span className="material-symbols-outlined">close</span>
          </div>

          <VehhicleForm
            data={{ ...data, thumbnail }}
            handleSubmit={(values) => {
              handleSubmit(values);
              setEditMode(!editMode);
            }}
            submitting={submitting}
            index={data?._id}
            categoryName={categoryName}
          />
        </div>
      )}
    </>
  );
};

export default VehicleItem;
