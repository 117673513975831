import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Modal from "react-bootstrap/Modal";

import { PAYMENT_PLATFORMS } from "../../../common/constants";
import { paymentInfoSchema } from "../../../common/validations/formValidation";
import TextError from "../../../shared/TextError";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { connect } from "react-redux";
import {
  addEditPaymentInfo,
  deletePaymentInfo,
} from "../../../store/actions/userActions";
import ConfirmationAlert from "../../../shared/Model/ConfirmationAlert";

const PaymentInfo = ({
  heading,
  paymentInfo,
  submitting,
  addEditPaymentInfo,
  deletePaymentInfo,
}) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const handleSubmit = (values) => {
    let editMode = !showAddForm;
    addEditPaymentInfo(values, !editMode ? handleAddAction : undefined);
  };

  const handleDeleteClick = (event, data) => {
    event.stopPropagation();
    if (data?._id) {
      deleteHandler(data?._id);
    }
  };

  const deleteHandler = (deleteId) => {
    setDeleteId(deleteId);
    setShowConfirm(true);
  };

  const deleteActionHanlder = () => {
    setDeleting(true);
    deletePaymentInfo(deleteId, onAction);
  };

  const onAction = () => {
    setShowConfirm(false);
    setDeleteId(null);
    setDeleting(false);
  };

  const handleAddAction = () => {
    setShowAddForm(!showAddForm);
  };
  usePageTitle(heading ?? "Payment Info");

  useEffect(() => {
    setShowAddForm(false);
    if (paymentInfo.length === 0) {
      setShowAddForm(true);
    }
  }, [paymentInfo]);

  return (
    <div className="info_forms">
      {!showAddForm ? (
        <div className="info_form_inner">
          {" "}
          {(paymentInfo || []).length > 0 ? (
            <Accordion className="theme_accordion relative">
              {(paymentInfo || []).map((info, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    <div className="left">
                      <h5 className="product_name">{info.type}</h5>
                    </div>
                    <div className="right">
                      <span
                        className="material-symbols-outlined red"
                        onClick={(e) => handleDeleteClick(e, info)}
                      >
                        delete_forever
                      </span>
                      <span className="material-symbols-outlined open icon_grad">
                        add
                      </span>
                      <span className="material-symbols-outlined close icon_grad">
                        remove
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Formik
                      initialValues={info}
                      validationSchema={paymentInfoSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-4">
                              <div className="form_field">
                                <label htmlFor={`type${index}`}>Payment*</label>
                                <Field
                                  as="select"
                                  name="type"
                                  id={`type${index}`}
                                >
                                  <option value="">Select</option>
                                  {PAYMENT_PLATFORMS.map((p, p_key) => (
                                    <option value={p.value} key={p_key}>
                                      {p.label}
                                    </option>
                                  ))}
                                </Field>{" "}
                                <ErrorMessage
                                  name="type"
                                  component={TextError}
                                />
                              </div>
                            </div>
                            <div className="col-8 pl0">
                              <div className="form_field">
                                <label htmlFor={`link${index}`}>
                                  UPI ID / Number*
                                </label>
                                <Field
                                  type="text"
                                  name="link"
                                  id={`link${index}`}
                                  placeholder="Enter"
                                />{" "}
                                <ErrorMessage
                                  name="link"
                                  component={TextError}
                                />
                              </div>
                            </div>
                            <div className="vg22"></div>
                            <div className="col-12">
                              <button
                                type="submit"
                                className="theme_btn short_btn"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <div>No Payment link Added yet</div>
          )}
          <div className="bottom_plus" onClick={handleAddAction}>
            <span className="material-symbols-outlined">add</span>
          </div>
          <div className="vg22"></div>
          <div className="vg22"></div>
          {!heading && (
            <div className="col-12">
              <Link to="/account/set-custom-url" className="theme_btn">
                Next
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="add_more_items_div relative">
          <div className="close_media" onClick={handleAddAction}>
            <span className="material-symbols-outlined">close</span>
          </div>
          <Formik
            initialValues={{
              type: "",
              link: "",
            }}
            validationSchema={paymentInfoSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-4">
                    <div className="form_field">
                      <label htmlFor="payment">Payment*</label>
                      <Field as="select" name="type" id="type">
                        <option value="">Select</option>
                        {PAYMENT_PLATFORMS.map((p, p_key) => (
                          <option value={p.value} key={p_key}>
                            {p.label}
                          </option>
                        ))}
                      </Field>{" "}
                      <ErrorMessage name="type" component={TextError} />
                    </div>
                  </div>
                  <div className="col-8 pl0">
                    <div className="form_field">
                      <label htmlFor="upiid">UPI ID / Number*</label>
                      <Field
                        type="text"
                        name="link"
                        id="link"
                        placeholder="Enter"
                      />{" "}
                      <ErrorMessage name="link" component={TextError} />
                    </div>
                  </div>
                  <div className="vg22"></div>
                  <div className="col-12">
                    <button type="submit" className="theme_btn short_btn">
                      Add
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {showConfirm && (
        <ConfirmationAlert
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
          actionHandler={deleteActionHanlder}
          deleting={deleting}
          labelName={"Payment"}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ form }) => {
  return { submitting: form.submitting };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addEditPaymentInfo: (payload, goBackOrNext) =>
      dispatch(addEditPaymentInfo(payload, goBackOrNext)),
    deletePaymentInfo: (_id, onAction) =>
      dispatch(deletePaymentInfo(_id, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
