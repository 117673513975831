import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getSession } from "../store/actions/authActions";

import { Link } from "react-router-dom";

// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import SiteFooterTop from "./SiteFooterTop";
import ContactForm from "./Form/ContactForm";
import useScrollToTop from "../common/hooks/useScrollToTop";
import GoogleMetaTag from "../component/GoogleMetaTag";
import LiveChatScript from "./LiveChatScript";

const SiteContactUs = () => {
  let { Authorization } = getSession();
  useScrollToTop();
      //   modal for view demo website links
      const [showDemoWebsite, setShowDemoWebsite] = useState(false);

      const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
      const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
      //   modal for view demo website links
  return (
    <div className="website_pages site_contactus">
        <GoogleMetaTag title={"Contact Us for Your New Website - BusinessBaab"} content={"Option 1: Need more information on how to build a website on BusinessBaab? Talk to our experts with WhatsApp chat or direct call us."} />
      <SiteHeader />
      <div className="page">
        <div className="container">
          <div className="text-center">
            <h1 className="pgtitle">Contact Us</h1>
            <div className="contact_info">
              <h3>BusinessBaab</h3>
              <h4>16/1, Varah Mihir Marg, Ujjain - 456010 (M.P.) - INDIA</h4>
              {/* <h4>+91 98277 93026</h4> */}
              <h4>businessbaab@gmail.com</h4>
              <div className="vg12"></div>
              <h4>Phone: +91 74156 93026</h4>
            </div>
          </div>
          <div className="sitevg40"></div>
          <div className="pgtitle_b_desc">
            Have questions or need assistance? We're here to help! Use the form
            below to send us a message directly. Our team will get back to you
            as soon as possible.
          </div>
          <div className="sitevg40"></div>
          <ContactForm />
          <div className="sitevg40"></div>
          <div className="sitevg30"></div>
         <SiteFooterTop/>
        </div>
      </div>
      <SiteFooter />
      {/* <LiveChatScript/> */}
          {/* modal for show demo website links  */}
          <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
            यहाँ कुछ ऑरिजिनल वेबसाइट्स की लिंक्स दी गयी हैं, जो BusinessBaab के द्वारा बनाई गयी हैं।
          </h6>
          <h6 className="r16 text-start">
            आपकी वेबसाइट भी ऐसी ही दिखेगी, इन्हें आप देख सकते हैं।
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to='https://businessbaab.com/absne' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shine.collections' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
            Shine Collection
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                <span>
                Shree Sanwariya Decoration
                </span>
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/a2z' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
          A 2 Z Collections
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/almadeeha' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Al-Madeeha Perfumes
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
          </div>
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}>Okay, Got it.</div>
      </Modal>
    </div>
  );
};

export default SiteContactUs;
