const initialState = {
  error: "",
  loading: false,
  contacts: [],
  totalContacts: 0,
};
const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USER_CONTACT":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SET_CONTACT_LISTS":
      return { ...state, loading: false, ...action.payload };
    case "SAVE_CONTACT_ERROR":
      return { ...state, loading: false, error: action.err.message };
    case "FILTER_CONTACT_LISTS":
      let contacts = state.contacts.filter((c) => c._id !== action.payload);
      return {
        ...state,
        loading: false,
        contacts,
        totalContacts: contacts.length,
      };
    default:
      return state;
  }
};
export default contactReducer;
