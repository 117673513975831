import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { logout } from "../store/actions/authActions";
import { useDispatch } from "react-redux";

const VendorNavbar = ({ title, backgroundColor, backArrow }) => {
  const location = useLocation(); // Get the current location

  // goback code
  const navigate = useNavigate(); // Use useNavigate hoo
  const handleGoBack = () => {
    navigate(-1); // Navigate back one step
  };
  // goback code

  //   modal
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseMenuModal = () => setShowMenu(false);
  const handleShowMenuModal = () => setShowMenu(true);
  //   modal
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout(navigate));
    handleCloseMenuModal();
  };

  return (
    <div className="vendor_header">
      <div className="header" style={{ background: backgroundColor }}>
        <div className="header_left">
          <span
            className="material-symbols-outlined back pointer"
            onClick={handleGoBack}
          >
            {backArrow}
          </span>
          <h6 className="m18">{title}</h6>
        </div>
        <div className="header_right">
          {/* <Link to="/cart" className="shopping_cart relative">
            <img src="./assets/img/shopping-cart.png" alt="" />
            <div className="number">2</div>
          </Link> */}

          <div className="language pointer">
            <img src="./assets/img/language.svg" alt="" />
          </div>
          <div className="huburger_icon pointer" onClick={handleShowMenuModal}>
            <img src="./assets/img/menu_icon.svg" alt="" />
          </div>
        </div>
        {/* modal for menu */}
        <Modal
          show={showMenu}
          onHide={handleCloseMenuModal}
          className="my_modal menu_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="m18"></Modal.Title>
            <span
              className="material-symbols-outlined modal_close red"
              onClick={handleCloseMenuModal}
            >
              close
            </span>
          </Modal.Header>
          <div className="vg22"></div>
          <Modal.Body>
            <h5 className="r18 grad_text">
              Hello Mr.{" "}
              <span
                style={{
                  textTransform: "capitalize",
                }}
              >
                Noor
              </span>
            </h5>
            <div className="redirect_button">
              <Link
                to="/dashboard"
                className="modal_btn"
                onClick={handleCloseMenuModal}
              >
                Dashboard
              </Link>
              <Link
                to="/analytics"
                className="modal_btn"
                onClick={handleCloseMenuModal}
              >
                Analytics
              </Link>
              <Link
                to="/orders"
                className="modal_btn"
                onClick={handleCloseMenuModal}
              >
                Orders
              </Link>
              <Link
                to="/myproduct"
                className="modal_btn"
                onClick={handleCloseMenuModal}
              >
                My Products
              </Link>
              <Link to="" className="modal_btn" onClick={handleCloseMenuModal}>
                Add More Product
              </Link>
            </div>
            <div className="menus">
              <Link className="menu_single" onClick={handleCloseMenuModal}>
                <div className="icon">
                  <img src="./assets/img/menu_icon/home.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">Home</h6>
              </Link>
              <Link
                to="/aboutus"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/user.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">About us</h6>
              </Link>
              <Link
                to="/business"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/business.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">business</h6>
              </Link>
              <Link
                to="/notification"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/notification.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">Notification</h6>
              </Link>
              <Link
                to="/faq"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/faq.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">FAQ</h6>
              </Link>
              <Link
                to="/setting"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/settings.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">Setting</h6>
              </Link>
              <Link
                to="/termsandpolicy"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/terms.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">Terms & Conditions</h6>
              </Link>
              <Link
                to="/contactus"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/contact2.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">Contact us</h6>
              </Link>
            </div>
            <div className="sect_divider"></div>
            <div className="menus">
              <Link className="menu_single" onClick={handleCloseMenuModal}>
                <div className="icon">
                  <img src="./assets/img/menu_icon/share.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">share my portal</h6>
              </Link>
              <Link className="menu_single" onClick={handleCloseMenuModal}>
                <div className="icon">
                  <img src="./assets/img/menu_icon/qr.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">dowanlod your OR code</h6>
              </Link>
              <Link
                to="/language"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/language.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">language</h6>
              </Link>
              <Link
                to="https://www.businessbaab.com/"
                className="menu_single"
                onClick={handleCloseMenuModal}
              >
                <div className="icon">
                  <img src="./assets/img/menu_icon/web.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">know more</h6>
              </Link>
              <span className="menu_single" onClick={onLogout}>
                <div className="icon">
                  <img src="./assets/img/menu_icon/logout.svg" alt="" />
                </div>
                <h6 className="r16 text_capital">logoutt</h6>
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default VendorNavbar;
