import React from "react";
import { Link } from "react-router-dom";
import VendorNavbar from "../../../../component/VendorNavbar";
import ASSETS from "../../../../common/images";

const NoplanAlert = () => {
  return (
    <div className="create_protal align_center_pg">
      <div className="cp_inner acp_inner text-center">
        <div className="">
          <img src={ASSETS.notFoundError} alt="" />
        </div>
        <div className="vg22"></div>
        <h5 className="r20">You don't have any plan!</h5>
        <div className="vg22"></div>
        <Link to="/account/choose-plan" className="theme_btn m-auto">
          Purchase Plan
        </Link>
        <div className="vg30"></div>
        <div className="vg12"></div>
        <div className="d-flex justify-content-center">
          <Link
            to="https://www.businessbaab.com/"
            className="grad_text gt_border"
            style={{
              marginRight: "15px",
            }}
          >
            Know more
          </Link>
          <Link
            to="/account/help-supports"
            className="grad_text gt_border"
            style={{
              marginLeft: "15px",
            }}
          >
            Help & Support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoplanAlert;
