import React from "react";
import { Link, useNavigate } from "react-router-dom";
import VendorNavbar from "../../../../component/VendorNavbar";
import ASSETS from "../../../../common/images";
import { useDispatch, useSelector } from "react-redux";
import { allowFreeTrial } from "../../../../store/actions/paymentActions";
import ActivityIndigator from "../../../../shared/ActivityIndigator";

const PurchasePlan = () => {
  const { submitting } = useSelector((state) => state.form);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleFreeTrial = () => {
    dispatch(
      allowFreeTrial(
        {
          profileLastStep: "choose-plan",
          profileStep: 1,
          subscription: {
            ...(user.subscription || {}),
            isFreeTrial: true,
          },
        },
        navigate
      )
    );
  };

  return (
    <div className="create_protal align_center_pg">
      <div className="cp_inner acp_inner text-center">
        <div className="logo">
          <img src={ASSETS.businessBaapLogo} alt="" />
        </div>
        <div className="vg12"></div>
        <h2 className="m20">Payment method</h2>
        <div className="vg22"></div>
        <div className="vg12"></div>
        <Link to="/account/select-plan" className="theme_btn">
          Purchase Plan
        </Link>
        <div className="d-none">
        {/* free_plan_hide */}
          <div className="vg8"></div>
          <h6 className="m16">Or</h6>
          <div className="vg8"></div>
          <button
            type="button"
            className="theme_btn btn_border"
            onClick={handleFreeTrial}
            disabled={submitting}
          >
            {submitting ? (
              <ActivityIndigator />
            ) : (
              "   I want to free trial for 7 Days"
            )}
          </button>
        </div>
        <div className="vg22"></div>
        <Link to="/" className="grad_text gt_border">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default PurchasePlan;
