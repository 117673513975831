import { combineReducers } from "redux";
import formReducer from "./formReducer";
import authReducer from "./authReducer";
import categoryReducer from "./categoryReducer";
import userReducer, { dashboardReducer } from "./userReducer";
import accountReducer from "./accountReducer";
import {
  commonReducer,
  profileReducer,
  shoppingCartReducer,
} from "./publiceReducer";
import serviceReducer from "./serviceReducer";
import contactReducer from "./contactReducer";
import orderReducer from "./orderReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  form: formReducer,
  auth: authReducer,
  category: categoryReducer,
  user: userReducer,
  common: commonReducer,
  service: serviceReducer,
  profile: profileReducer,
  shoppingCart: shoppingCartReducer,
  dashboard: dashboardReducer,
  contact: contactReducer,
  order: orderReducer,
});
export default rootReducer;
