import { APIs, NAVIGATION_ROUTE } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
import { getUserInfo } from "./userActions";
export const allowFreeTrial =
  (payload, navigate = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(APIs.ALLOW_FREE_TRIAL, { ...payload });
      dispatch({
        type: "FORM_SUCCESS",
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        if (navigate !== undefined) {
          navigate(
            `${NAVIGATION_ROUTE.CONGRADULATION}?expiresAt=${data?.data?.expiresAt}&type=${data?.data?.type}`
          );
          dispatch({
            type: "APPEND_PROFILE_DATA",
            payload: {
              ...payload,
            },
          });
        } else {
          showSuccess(data.msg);
        }
      }
      if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      showThrowError(err);
    }
  };

export const purchasePlan =
  (payload, navigate = undefined, type = null) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(APIs.PURCHASE_SUBSCRIPTION, {
        ...payload,
      });
      dispatch({
        type: "FORM_SUCCESS",
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        if (navigate !== undefined) {
          if (type === "recharge") {
            dispatch(getUserInfo());
          }
          let route =
            type === "recharge"
              ? NAVIGATION_ROUTE.PAYMENT_SUCCESS
              : NAVIGATION_ROUTE.CONGRADULATION;
          navigate(`${route}?expiresAt=${data?.data?.expiresAt}`);
        } else {
          showSuccess(data.msg);
        }
      }
      if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      showThrowError(err);
    }
  };
export const createOrder = async (payload, setCreating) => {
  try {
    const { data } = await axios.post(APIs.PURCHASE_SUBSCRIPTION, {
      ...payload,
    });
    if (data.status) {
      setCreating(false);
      return data?.data || {};
    }
    if (!data.status) {
      showError(data.msg);
      setCreating(false);
    }
  } catch (err) {
    showThrowError(err);
    setCreating(false);
  }
};
export const verifyPayment =
  (payload, navigate = undefined, type = null) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(
        APIs.VERIFY_PAYMENT,
        {
          ...payload,
        },
        {
          headers: {
            "x-razorpay-signature": payload.signature,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch({
        type: "FORM_SUCCESS",
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        if (navigate !== undefined) {
          if (type === "recharge") {
            dispatch(getUserInfo());
          }
          if (payload.localDetails) {
            dispatch({
              type: "APPEND_PROFILE_DATA",
              payload: {
                ...(payload.localDetails ?? {}),
              },
            });
          }
          let route =
            type === "recharge"
              ? NAVIGATION_ROUTE.PAYMENT_SUCCESS
              : NAVIGATION_ROUTE.CONGRADULATION;
          navigate(`${route}?expiresAt=${data?.data?.expiresAt}`);
        } else {
          showSuccess(data.msg);
        }
      }
      if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      showThrowError(err);
    }
  };
