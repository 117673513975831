import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { otpPhoneNumberValidate } from "../../../common/validations/formValidation";
import PhoneInput from "react-phone-number-input";
import TextError from "../../../shared/TextError";
import OTPInput from "otp-input-react";
import ActivityIndigator from "../../../shared/ActivityIndigator";
const ChangeMobileNumber = ({
  userDetails,
  submitting,
  sentOTPForConfirmNumber,
  verifyActionOTP,
  tempInfo,
}) => {
  const [step, setStep] = useState(0);
  const [OTP, setOTP] = useState("");
  const [hasError, setHasError] = useState(false);
  const handleSubmit = (values) => {
    verifyActionOTP(
      {
        action: "confirmOtpFromChangeNumber",
        ...values,
      },
      onActionOne
    );
  };
  const onActionOne = () => {
    setStep(2);
  };

  const sendOtpForConfirmation = () => {
    sentOTPForConfirmNumber(
      {
        type: "sentOTPForConfirmNumber",
      },
      setStep
    );
  };

  const handleChange = (otp) => {
    setHasError(false);
    setOTP(otp);
  };
  const handleVerify = (e) => {
    e.preventDefault();
    let phoneNumber =
      sessionStorage.getItem("changeReqNumber") || tempInfo?.phoneNumber;
    if (e.key === "Enter") {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyActionOTP(
          {
            action: "confirmOtpFromNewNumber",
            otp: OTP,
            phoneNumber,
          },
          onAction
        );
      }
    } else {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyActionOTP(
          {
            action: "confirmOtpFromNewNumber",
            otp: OTP,
            phoneNumber,
          },
          onAction
        );
      }
    }
  };
  const onAction = () => {
    setHasError(false);
    setStep(0);
  };
  return (
    <Accordion.Item eventKey="0" className="relative">
      <Accordion.Header>
        <div className="left">
          <h5 className="product_name">Change Mobile Number</h5>
          <span>{tempInfo?.OTP}</span>
        </div>
        <div className="right">
          <span className="material-symbols-outlined open icon_grad">add</span>
          <span className="material-symbols-outlined close icon_grad">
            remove
          </span>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        {step === 0 && (
          <div>
            <small class="normal_font">
              OTP will send on {userDetails?.phoneNumber} mobile number.
            </small>
            <button
              type="button"
              onClick={sendOtpForConfirmation}
              disabled={submitting}
              className="theme_btn short_btn mt-2"
            >
              {submitting ? <ActivityIndigator /> : "Change"}
            </button>
          </div>
        )}
        {step === 1 && (
          <Formik
            initialValues={{
              otp: "",
              phoneNumber: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={otpPhoneNumberValidate}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="form_field">
                  <label htmlFor="cn_otp">OTP</label>
                  <Field name="otp">
                    {({ field, form }) => (
                      <OTPInput
                        {...field}
                        inputStyle="inputStyle"
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        isInputNum={true}
                        placeholder="0000"
                        errorStyle="error"
                        onChange={(value) =>
                          form.setFieldValue(field.name, value)
                        }
                      />
                    )}
                  </Field>{" "}
                  <ErrorMessage name="otp" component={TextError} />
                </div>{" "}
                <div className="ff_gap"></div>
                <div className="form_field">
                  <label htmlFor="new_number">New Mobile Number</label>
                  <Field name="phoneNumber">
                    {({ field, form }) => (
                      <PhoneInput
                        {...field}
                        international
                        defaultCountry="IN"
                        withCountryCallingCode={true}
                        countryCallingCodeEditable={false}
                        id="phoneNumber"
                        name="phoneNumber"
                        onBlur={field.onBlur}
                        onChange={(value) =>
                          form.setFieldValue(field.name, value)
                        }
                      />
                    )}
                  </Field>{" "}
                  <ErrorMessage name="phoneNumber" component={TextError} />
                </div>
                <div className="vg22"></div>
                <button
                  type="submit"
                  disabled={submitting}
                  className="theme_btn short_btn"
                >
                  {submitting ? <ActivityIndigator /> : "submit"}
                </button>
              </Form>
            )}
          </Formik>
        )}
        {step === 2 && (
          <Form onSubmit={handleVerify}>
            <div className="ff_gap"></div>
            <div className="form_field">
              <label htmlFor="cn_otp">OTP</label>
              <OTPInput
                className={`otp_input ${hasError ? "b-red" : ""}`}
                inputStyle="inputStyle"
                value={OTP}
                onChange={handleChange}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
                isInputNum={true}
                hasErrored={hasError}
                placeholder="0000"
                errorStyle="error"
              />
              {hasError && <span className="err_red ">enter valid otp</span>}
            </div>
            <div className="vg22"></div>

            <button disabled={submitting} className="modal_btn">
              {submitting ? <ActivityIndigator /> : "Change"}
            </button>
          </Form>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ChangeMobileNumber;
