import React from "react";
import ASSETS from "../../../common/images";
import { Link } from "react-router-dom";

const SocialLinks = ({ socialLinks }) => {
  if ((socialLinks || []).filter((s) => s.type !== "").length === 0)
    return null;
  return (
    <>
      {" "}
      <div className="sect_divider"></div>
      <h3 className="sect_title">Social Media </h3>
      <div className="social_media">
        {socialLinks.map((link, i) => (
          <div className="social_media_single" key={`social-${i}`}>
            <Link className="pay_me_single text-center" to={link.link}>
              <img src={ASSETS[link.type]} alt={link.type} />           
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default SocialLinks;
