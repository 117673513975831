import React from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";

const RefundAndPolicy = () => {
  usePageTitle("Refund Policy");
  return (
    <div className="pg_tp static_pg">
      <p className="r14">
      Thank you for considering BusinessBaab for your online presence. We want to ensure that you have a seamless experience with us. Therefore, we encourage you to fully explore our platform and services before making a purchase. Once you are fully satisfied with our offerings, you can confidently select a plan that best suits your needs.
      </p>
      <div className="vg12"></div>
      <p className="r14">
      We don't offer refunds for any purchases made on our platform. This includes subscription fees, one-time payments, and any other charges.
      </p>
      <div className="vg12"></div>
      <p className="r14">
      We believe that this approach allows our customers to make informed decisions and ensures a more fulfilling experience with BusinessBaab. If you have any questions or need assistance in selecting the right plan for you, please don't hesitate to contact our customer support team.
      </p>
      <div className="vg12"></div>
      <p className="r14">
      Thank you for your understanding and for choosing BusinessBaab.
      </p>
    
    </div>
  );
};

export default RefundAndPolicy;
