import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import Modal from "react-bootstrap/Modal";

// import vendornavbar
import VendorNavbar from "../component/VendorNavbar";

const PGHelpAndSupport = () => {
  const initialValues = {};

  const handleSubmit = (values) => {
    // Handle form submission logic here
    console.log(values);
  };

  //   modal
  const [showDeleteProduct, setShowDeleteProduct] = useState(false);

  const handleCloseDeleteProductModal = () => setShowDeleteProduct(false);
  const handleShowDeleteProductModal = () => setShowDeleteProduct(true);
  //   modal
  return (
    <div className="vendor_page_spacing">
      <VendorNavbar
        title="Help & Support"
        backgroundColor="white"
        backArrow="arrow_back"
      />
      <div className="pghelp">
        <h6 className="r14">Describe your query, what you want.</h6>
        <div className="vg22"></div>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="form_field">
              <label htmlFor="username">Name</label>
              <Field
                type="text"
                name="username"
                id="username"
                placeholder="Enter your name"
              />
            </div>

            <div className="ff_gap"></div>

            <div className="form_field">
              <label htmlFor="yourmobile">Registered mobile number</label>
              <Field
                type="number"
                name="yourmobile"
                id="yourmobile"
                placeholder="Your number"
              />
            </div>
            <div className="ff_gap"></div>
            <div className="form_field">
              <label htmlFor="selectquerytype">Select query type</label>
              <Field
                as="select"
                name="selectquerytype"
                id="businessselectquerytype"
              >
                <option value="">Select</option>
                <option value="">query1</option>
                <option value="">query2</option>
              </Field>
            </div>
            <div className="ff_gap"></div>

            <div className="form_field">
              <label htmlFor="describequery">Describe your query</label>
              <Field
                as="textarea"
                name="describequery"
                id="describequery"
                placeholder="Describe your query"
              />
            </div>

            <div className="vg22"></div>
            <div className="col-12">
              <button
                type="submit"
                className="theme_btn"
                onClick={handleShowDeleteProductModal}
              >
                Submit
              </button>
            </div>
          </Form>
        </Formik>
        {/* modal for delete product  */}
        <Modal
          show={showDeleteProduct}
          onHide={handleCloseDeleteProductModal}
          className="my_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="m18">Help & Support</Modal.Title>
            <span
              class="material-symbols-outlined modal_close red"
              onClick={handleCloseDeleteProductModal}
            >
              close
            </span>
          </Modal.Header>
          <div className="vg22"></div>
          <Modal.Body>
            <h6 className="r16 green">
              Your query has successfully submitted. Our team will contact you
              soon.
            </h6>
          </Modal.Body>
        </Modal>
        {/* modal for delete product  */}
      </div>
    </div>
  );
};

export default PGHelpAndSupport;
