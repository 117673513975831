import React from "react";
import { Modal } from "react-bootstrap";
import ActivityIndigator from "../ActivityIndigator";

const ConfirmationAlert = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} className="my_modal">
      <Modal.Header closeButton>
        <Modal.Title className="m18">Alert</Modal.Title>
        <span
          className="material-symbols-outlined modal_close red"
          onClick={props.onHide}
        >
          close
        </span>
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body>
        <h6 className="r16">
          Are you sure you want to delete this{" "}
          {props?.labelName !== undefined ? props.labelName : "item"}?
        </h6>
      </Modal.Body>
      <div className="vg22"></div>

      <button
        className="modal_btn"
        onClick={props.actionHandler}
        disabled={props.deleting}
      >
        {props.deleting ? <ActivityIndigator /> : "Delete"}
      </button>
    </Modal>
  );
};

export default ConfirmationAlert;
