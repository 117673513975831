import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";

import { personalInfoSchema } from "../../../common/validations/formValidation";
import TextError from "../../../shared/TextError";
import { SOCIAL_PLATFORMS } from "../../../common/constants";
import usePageTitle from "../../../common/hooks/usePageTitle";

import {
  getCities,
  getStates,
  getCountries,
} from "../../../store/actions/commonActions";
import { connect } from "react-redux";
import { savePersonalInfo } from "../../../store/actions/userActions";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { NAVIGATION_ROUTE } from "../../../store/common/constants";
import PhoneInput from "react-phone-number-input";

// vendor navbar

const PersonalInfo = ({
  heading,
  userDetails,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  submitting,
  savePersonalInfo,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    getCountries();
    if (userDetails !== undefined) {
      if (userDetails?.personalInfo?.address?.countryId) {
        getStates(userDetails?.personalInfo?.address?.countryId);
      } else {
        getStates(101);
      }
      if (userDetails?.personalInfo?.address?.stateId) {
        getCities(userDetails?.personalInfo?.address?.stateId);
      }
    }
  }, [getCountries, userDetails]);
  let socialLinks =
    (userDetails?.social || []).length > 0
      ? userDetails?.social
      : [{ type: "", link: "" }];
  const initialValues = {
    gender: userDetails?.personalInfo?.gender || "male",
    name: userDetails?.personalInfo?.name || "",
    phoneNumber: userDetails?.personalInfo?.phoneNumber || "",
    whatsapNumber: userDetails?.personalInfo?.whatsapNumber || "",
    email: userDetails?.personalInfo?.email || "",
    aboutMe: userDetails?.personalInfo?.aboutMe || "",
    countryId: userDetails?.personalInfo?.address?.countryId || "101",
    stateId: userDetails?.personalInfo?.address?.stateId || "",
    cityId: userDetails?.personalInfo?.address?.cityId || "",
    fullAddress: userDetails?.personalInfo?.address?.fullAddress || "",
    social: socialLinks || [{ type: "", link: "" }],
  };

  const handleSubmit = (values) => {
    savePersonalInfo(values, goBackOrNext);
  };

  const goBackOrNext = () => {
    if (heading === undefined) {
      navigate(NAVIGATION_ROUTE.BUSINESS_INFO);
    } else {
      navigate(NAVIGATION_ROUTE.USER_ACCOUNT);
    }
  };

  usePageTitle(heading ?? "Personal Info");

  const handleCountryChange = (value, setFieldValue) => {
    if (value) {
      getStates(value);
      setFieldValue("countryId", value);
      setFieldValue("stateId", "");
      setFieldValue("cityId", "");
    }
  };

  const handleStateChange = (value, setFieldValue) => {
    if (value) {
      getCities(value);
      setFieldValue("stateId", value);
      setFieldValue("cityId", "");
    }
  };

  return (
    <div className="info_forms">
      <Formik
        initialValues={initialValues}
        validationSchema={personalInfoSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-4">
                <div className="form_field">
                  <label htmlFor="gender">Gender</label>
                  <Field as="select" name="gender" id="gender">
                    <option value="male">Mr.</option>
                    <option value="female">Mrs.</option>
                  </Field>
                </div>
              </div>
              <div className="col-8 pl0">
                <div className="form_field">
                  <label htmlFor="name">Your Name*</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter name"
                  />
                </div>
                <ErrorMessage name="name" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <label htmlFor="phoneNumber">Mobile Number</label>
                <Field
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="+91 9827793026"
                  
                />
                <ErrorMessage name="phoneNumber" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <label htmlFor="whatsapNumber">
                  Whatsap Number*
                  <span>(All your orders will go to on this number)</span>
                </label>
                {/* <Field
                  type="number"
                  name="whatsapNumber"
                  id="whatsapNumber"
                  placeholder="+91 9827793026"
                />{" "} */}
                <Field name="whatsapNumber">
                  {({ field, form }) => (
                    <PhoneInput
                      {...field}
                      international
                      defaultCountry="IN"
                      withCountryCallingCode={true}
                      countryCallingCodeEditable={false}
                      id="whatsapNumber"
                      name="whatsapNumber"
                      onBlur={field.onBlur}
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      
                      }
                      className="input_with_country"
                    />
                  )}
                </Field>
                <ErrorMessage name="whatsapNumber" component={TextError} />
              </div>
            </div>{" "}
            {/* <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <label htmlFor="whatsapNumber">
                  Email
                  <span>(optional)</span>
                </label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  placeholder="eg. abc@gmail.com"
                />{" "}
                <ErrorMessage name="email" component={TextError} />
              </div>
            </div> */}
            <div className="ff_gap"></div>
            {/* <div className="col-12">
              <div className="form_field">
                <label htmlFor="aboutMe" className="field_optional">
                  Short Bio (optional){" "}
                </label>
                <Field
                  as="textarea"
                  name="aboutMe"
                  id="aboutMe"
                  placeholder="Describe yourself"
                />
                <ErrorMessage name="aboutMe" component={TextError} />
              </div>
            </div> */}
            {/* <div className="ff_gap"></div>
            <div className="row">
              <div className="col-4">
                <div className="form_field">
                  <label htmlFor="countryId">Country</label>
                  <Field
                    as="select"
                    name="countryId"
                    id="countryId"
                    onChange={(e) =>
                      handleCountryChange(e.target.value, setFieldValue)
                    }
                    value={values.countryId}
                  >
                    {" "}
                    <option value="">Select</option>
                    {(countries ?? []).map((country, i) => (
                      <option key={`country_${i}`} value={country.countryId}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="countryId" component={TextError} />
                </div>
              </div>
              <div className="col-4 pl0">
                <div className="form_field">
                  <label htmlFor="stateId">State*</label>
                  <Field
                    as="select"
                    name="stateId"
                    id="stateId"
                    onChange={(e) =>
                      handleStateChange(e.target.value, setFieldValue)
                    }
                    value={values.stateId}
                  >
                    {" "}
                    <option value="">Select</option>
                    {(states ?? []).map((state, i) => (
                      <option key={`state_${i}`} value={state.stateId}>
                        {state.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="stateId" component={TextError} />
                </div>
              </div>
              <div className="col-4 pl0">
                <div className="form_field">
                  <label htmlFor="cityId">City*</label>
                  <Field as="select" name="cityId" id="cityId">
                    <option value="">Select</option>
                    {(cities ?? []).map((city, i) => (
                      <option key={`city_${i}`} value={city.cityId}>
                        {city.name}
                      </option>
                    ))}
                  </Field>{" "}
                  <ErrorMessage name="cityId" component={TextError} />
                </div>
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <label htmlFor="fullAddress">Full Address* </label>
                <Field
                  as="textarea"
                  name="fullAddress"
                  id="fullAddress"
                  placeholder="Enter address"
                />
                <ErrorMessage name="fullAddress" component={TextError} />
              </div>
            </div> */}
            {/* <div className="ff_gap"></div> */}
            <div className="col-12">
              <div className="form_field">
                <label htmlFor="socialMediaLinks" className="field_optional">
                  Social Media Links (optional){" "}
                </label>
              </div>
            </div>
            <div className="col-12 relative">
              <FieldArray name="social">
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { social } = values;
                  return (
                    <>
                      {social.map((s, index) => (
                        <div
                          className="row social_media relative mb-1"
                          key={`social_${index}`}
                        >
                          <div className="col-4">
                            <div className="form_field">
                              <Field as="select" name={`social[${index}].type`}>
                                <option value="">Select</option>
                                {SOCIAL_PLATFORMS.map((d, s_key) => (
                                  <option value={d.value} key={s_key}>
                                    {d.label}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name={`social[${index}].type`}
                                component={TextError}
                              />
                            </div>
                          </div>
                          <div className="col-8 pl0">
                            <div className="form_field">
                              <Field
                                type="text"
                                name={`social[${index}].link`}
                                placeholder="Paste URL"
                              />
                              <ErrorMessage
                                name={`social[${index}].link`}
                                component={TextError}
                              />
                            </div>
                          </div>
                          {index > 0 && (
                            <div
                              className="close_media"
                              onClick={() => remove(index)}
                            >
                              <span className="material-symbols-outlined">
                                {" "}
                                close{" "}
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                      <h6
                        className="grad_text gt_border add_more"
                        onClick={() =>
                          push({
                            type: "",
                            link: "",
                          })
                        }
                      >
                        + Add more
                      </h6>
                    </>
                  );
                }}
              </FieldArray>
            </div>
            <div className="vg22"></div>
            <div className="vg22"></div>
            <div className="col-12">
              <button type="submit" className="theme_btn" disabled={submitting}>
                {submitting ? (
                  <ActivityIndigator />
                ) : (
                  <>{heading !== undefined ? "Save" : "Save & Next"}</>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
const mapStateToProps = ({ form, common }) => {
  return { submitting: form.submitting, ...common };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
    savePersonalInfo: (payload, goBackOrNext) =>
      dispatch(savePersonalInfo(payload, goBackOrNext)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
