import { ErrorMessage, Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import TextError from "../../../shared/TextError";
import { serviceInfoSchema } from "../../../common/validations/formValidation";
import ASSETS from "../../../common/images";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { LABELS } from "../../../common/label";
import { isShowStockEntity } from "../../../common/utils/utils";
import CommonImageCropper from "../../../shared/Model/CommonImageCropper";
import { IMAGE_SETTINGS, VALID_IMAGE } from "../../../common/constants";
import { showError } from "../../../store/common/helpers/responseHelper";

const ProductForm = ({
  data,
  handleSubmit,
  index,
  submitting,
  categoryName,
}) => {
  const imageRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [originSelectImage, setOriginalSelectImage] = useState(null);
  const [originSelectUrl, setOriginalSelectUrl] = useState(null);

  const onFileChange = (event, setFieldValue) => {
    const image = event.target.files[0];
    if (!image.type.startsWith("image/")) {
      showError(VALID_IMAGE);
      return;
    }
    // setFieldValue("image", image);
    setOriginalSelectImage(image);
    let reader = new FileReader();
    reader.onload = (e) => {
      // setFieldValue("thumbnail", e.target.result);
      setOriginalSelectUrl(e.target.result);
      setModalOpen(true);
    };
    reader.readAsDataURL(image);
  };

  const actionHandler = (data, type) => {
    // if (type === "save" && data !== null) {
    // setLogo(data);
    // }
    setModalOpen(false);
    setOriginalSelectImage(null);
    setOriginalSelectUrl(null);
  };

  const setFile = (file, setFieldValue) => {
    setFieldValue("image", file);
    setFieldValue("thumbnail", URL.createObjectURL(file));
  };

  return (
    <Formik
      initialValues={{
        ...data,
        quantity: data.quantity !== null ? data.quantity : "",
        image: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={serviceInfoSchema}
      enableReinitialize={true}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form_field">
                <label htmlFor={`title${index}`}>
                  {LABELS.SERVICE_TITLE[categoryName] || ""} Name*
                </label>
                <Field
                  type="text"
                  name="title"
                  id={`title${index}`}
                  placeholder="Enter product name"
                />{" "}
                <ErrorMessage name="title" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-6">
              <div className="form_field">
                <label htmlFor={`price${index}`}>Price (Per Piece)*</label>
                <Field
                  type="number"
                  name="price"
                  id={`price${index}`}
                  placeholder="Enter price"
                />{" "}
                <ErrorMessage name="price" component={TextError} />
              </div>
            </div>
            {isShowStockEntity() && (
              <div className="col-6 pl0">
                <div className="form_field">
                  <label htmlFor={`quantity${index}`}>Stock Available</label>
                  <Field
                    type="number"
                    name="quantity"
                    id={`quantity${index}`}
                    placeholder="Enter stock availability"
                  />{" "}
                  <ErrorMessage name="quantity" component={TextError} />
                </div>
              </div>
            )}

            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <label
                  htmlFor={`description${index}`}
                  className="field_optional"
                >
                  {LABELS.SERVICE_TITLE[categoryName] || ""} Description
                  (optional)
                </label>
                <Field
                  as="textarea"
                  name="description"
                  id={`description${index}`}
                  placeholder="Describe your product"
                  style={{ whiteSpace: "pre-line", resize: "none" }}
                  wrap="soft"
                />{" "}
                <ErrorMessage name="description" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="upload_product_img">
                <input
                  type="file"
                  hidden
                  ref={imageRef}
                  onChange={(e) => onFileChange(e, setFieldValue)}
                />
                <div
                  className="img_wrapper"
                  onClick={() => imageRef.current.click()}
                >
                  <img
                    src={
                      values?.thumbnail
                        ? values?.thumbnail
                        : ASSETS.uploadSmallIcon
                    }
                    alt=""
                  />
                  {values?.thumbnail && (
                    <div
                      className="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFieldValue("thumbnail", "");
                        setFieldValue("image", "");
                      }}
                    >
                      <span className="material-symbols-outlined">close</span>
                    </div>
                  )}{" "}
                </div>{" "}
                {modalOpen && (
                  <CommonImageCropper
                    show={modalOpen}
                    actionHandler={actionHandler}
                    onHide={() => actionHandler(null, "close")}
                    image={originSelectUrl}
                    setFieldValue={(file) => setFile(file, setFieldValue)}
                    originalImage={originSelectImage}
                    imageSettings={IMAGE_SETTINGS.SIZE_300_X_300}
                  />
                )}
                <div>
                  <h6 className="r12 grey">Upload Product Image</h6>
                  <h6
                    className="r12 grey"
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    (Square image is preferred)
                  </h6>
                  <h6
                    className="r12 grey"
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    (optional)
                  </h6>
                </div>
              </div>
            </div>
            <div className="vg22"></div>
            <div className="col-12">
              <button
                type="submit"
                className="theme_btn short_btn"
                disabled={submitting}
              >
                {submitting ? (
                  <ActivityIndigator />
                ) : (
                  <> {values?._id ? "Update" : "Add"}</>
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
