import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { getSession } from "../store/actions/authActions";


// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import SiteFooterTop from "./SiteFooterTop";
import useScrollToTop from "../common/hooks/useScrollToTop";
import GoogleMetaTag from "../component/GoogleMetaTag";
import LiveChatScript from "./LiveChatScript";

const SiteCategories = () => {
  let { Authorization } = getSession();
  useScrollToTop();
    //   modal for view demo website links
    const [showDemoWebsite, setShowDemoWebsite] = useState(false);

    const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
    const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
    //   modal for view demo website links
  return (
    <div className="website_pages site_aboutus">
      <GoogleMetaTag title={"Categories"} content={"BusinessBaab website categories"} />
      <SiteHeader />
      <div className="page">
        <div className="container">
          <div className="text-center">
            <h1 className="pgtitle">BusinessBaab Categories</h1>
          </div>
          <div className="sitevg40"></div>
          <div className="pgtitle_b_desc">
            Whatever your business, It's for everyone
          </div>
          <div className="sitevg30"></div>
          <div className="categories_in_accordion curve_style ">
            <Accordion defaultActiveKey="0" className="theme_accordion style_2">
              <Accordion.Item eventKey="0" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Online Store</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="points">
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span> Apparel
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Footwear
                    </li>                
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Jewellery
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Electronics
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Boutique
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Plants
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And
                      many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">All Services</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="points">
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Banking Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Electronic Item Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      IT Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Vehicle Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Furniture Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Home
                      Decor Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Construction Works
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Home
                      Cleaning Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Paint
                      Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Clothes
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Entertainment & Media
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Policies Services
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And
                      many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Retail Shop</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="points">
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Electronics
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Apparel
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Footwear
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Hardware
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Paints
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Boutique
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Undergarments
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Gift
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      General Items
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Home
                      Decor
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Stationary
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Milk
                      Parlour
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Vegetable and Fruits
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Computer Hardware
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Furniture
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Everfresh
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Medical
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And
                      many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Tour & Travels</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="points">
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Travels
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Flight, Train, Bus and Taxi Booking
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Taxi
                      Booking
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Personal Cab
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Persona Auto Rikshaw
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And
                      many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Restaurant</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="points">
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Restaurant
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Dhaba
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Cafe
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Bakery
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Food & Snacks
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And
                      many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Education</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="points">
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span> School
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      College
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Tutorials
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Institute
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Personal Tuition
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And
                      many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="relative">
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">Health Sector</h5>
                  </div>
                  <div className="right">
                    <span class="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span class="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="points">
                  <ul className="height_limit">
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Hospital
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Physiotherapy
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>
                      Doctor's
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> Clinic
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span>{" "}
                      Laboratories 
                    </li>
                    <li>
                      <span class="material-symbols-outlined">done</span> And
                      many more...
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="sitevg22"></div>
          <h6 className="r14 text-center">
            Can't find your business? <br /> Email Us at{" "}
            <Link className="grad_text">
              info@businessbaab.com
              <div className="vg12"></div>
              <Link to="/contact-us" className="grad_text gt_border">
                Or Contact US
              </Link>
            </Link>
          </h6>
          <div className="sect_gap"></div>
        </div>

        {/* <section className="testimonial">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="theme_title text-center">
                  <h2>Business Success Stories</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="stories">
                  <div className="video_single">
                    <video
                      src="./assets/videos/dummy_video.mp4"
                      controls
                    ></video>
                  </div>
                  <div className="video_single">
                    <video
                      src="./assets/videos/dummy_video.mp4"
                      controls
                    ></video>
                  </div>
                  <div className="video_single">
                    <video
                      src="./assets/videos/dummy_video.mp4"
                      controls
                    ></video>
                  </div>
                  <div className="video_single">
                    <video
                      src="./assets/videos/dummy_video.mp4"
                      controls
                    ></video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
       <SiteFooterTop/>
      </div>
      <SiteFooter />
      {/* <LiveChatScript/> */}
          {/* modal for show demo website links  */}
          <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
            यहाँ कुछ ऑरिजिनल वेबसाइट्स की लिंक्स दी गयी हैं, जो BusinessBaab के द्वारा बनाई गयी हैं।
          </h6>
          <h6 className="r16 text-start">
            आपकी वेबसाइट भी ऐसी ही दिखेगी, इन्हें आप देख सकते हैं।
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to='https://businessbaab.com/absne' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shine.collections' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
            Shine Collection
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                <span>
                Shree Sanwariya Decoration
                </span>
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/a2z' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
          A 2 Z Collections
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/almadeeha' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Al-Madeeha Perfumes
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
          </div>
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}> Okay, Got it.</div>
      </Modal>
    </div>
  );
};

export default SiteCategories;
