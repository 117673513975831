import React, { useState } from "react";
import { Link } from "react-router-dom";
import GoogleMetaTag from '../component/GoogleMetaTag'

const Region = () => {
// on select region
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);   
  }; 
  // on select region

  return (
    <div className="vendor_page_spacing">    
      <GoogleMetaTag title={"Login"} content={"Login page of businessbaab website content"} />
      <div className="create_protal align_center_pg">
        <div className="cp_inner acp_inner text-center">
          <div className="logo">
            <img src="./assets/img/logo_businessbaab.svg" alt="" />
          </div>
          <div className="vg22"></div>
          <h2 className="r20">Please Select Your Region</h2>
          <div className="vg22"></div>  
          <div className="big_cards">
            <div
              className={`bc_single pointer ${
                isActive ? "" : "select_portal"
              }`}
              onClick={handleClick}
            >
              <div className="bcs_inner">              
                <h6 className="m20">Indian</h6>
              </div>
              {!isActive && (
                <span class="material-symbols-outlined check">task_alt</span>
              )}
            </div>
            <div
              className={`bc_single pointer ${
                isActive ? "select_portal" : ""
              }`}
              onClick={handleClick}
            >
              <div className="bcs_inner">
                
                <h6 className="m20">Non-Indian</h6>
              </div>
              {isActive && (
                <span class="material-symbols-outlined check">task_alt</span>
              )}             
            </div>
          </div>
          <div className="vg22"></div>        
            <Link to={isActive ? "/login-password" : "/login"} className="theme_btn">
            Continue
            </Link>        
        </div>
      </div>
    
    </div>
  )
}

export default Region
