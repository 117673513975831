export const onShareHelper = (URL, onToggle = undefined) => {
  const data = {
    title: "Business Baab",
    // text: this.text,
    url: URL,
  };
  console.log(data);
  if (navigator.share) {
    navigator
      .share(data)
      .then(() => {
        console.log("Successful share! 🎉");
        if (onToggle !== undefined) onToggle();
      })
      .catch((err) => console.error(err));
  }
};
