import { APIs } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
export const getPublicUserInfo = (username, navigate) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${APIs.GET_USER_CARD_DETAILS}/${username}`
    );
    dispatch({
      type: "FETCH_PUBLIC_USER_PROFILE",
    });
    if (data.status) {
      dispatch({
        type: "SET_PUBLIC_USER_PROFILE",
        payload: {
          ...data.data,
        },
        extra: data?.service,
      });
      // save selected user id in session for temp data
      if (data.data?._id) {
        sessionStorage.setItem("userId", data.data?._id);
        sessionStorage.setItem("categoryName", data.data?.categoryName);
        sessionStorage.setItem("category", data.data?.category);
      }
    } else if (!data.status) {
      if (data?.data?.hideMe) {
        if (navigate !== undefined) navigate("/temporary-stopped");
      } else if (data?.data?.blocked) {
        if (navigate !== undefined) navigate("/profile-blocked");
      } else {
        showError(data.msg);
        if (navigate !== undefined) navigate("/");
      }
    }
  } catch (err) {
    dispatch({
      type: "FETCH_PUBLIC_PROFILE_ERROR",
      payload: err,
    });

    showThrowError(err);
  }
};
export const getPublicAllServices = (payload) => async (dispatch) => {
  try {
    if (payload?.pageNumber > 1) {
      dispatch({ type: "LOADING_MORE_PUBLIC_USER_SERVICES" });
    } else {
      dispatch({ type: "FETCH_PUBLIC_USER_PROFILE" });
    }

    let userId = sessionStorage.getItem("userId") || "";
    let categoryName = sessionStorage.getItem("categoryName") || "";
    const { data } = await axios.post(`${APIs.GET_USER_ALL_SERVICES}`, {
      ...payload,
      userId,
      categoryName,
    });

    if (data.status) {
      if (payload?.pageNumber > 1) {
        dispatch({
          type: "APPEND_PUBLIC_USER_SERVICES",
          payload: data.data || [],
          extra: {
            totalService: data?.totalDocuments || 0,
            backendAssetUrl: data?.backendAssetUrl || "",
            hasMore: data?.currentPage !== data?.lastPage,
          },
        });
      } else {
        dispatch({
          type: "SET_PUBLIC_USER_SERVICES",
          payload: data.data || [],
          extra: {
            totalService: data?.totalDocuments || 0,
            backendAssetUrl: data?.backendAssetUrl || "",
            hasMore: data?.currentPage !== data?.lastPage,
          },
        });
      }
    } else {
      dispatch({
        type: "SET_PUBLIC_USER_SERVICES",
        payload: [],
        extra: {
          totalService: 0,
          backendAssetUrl: "",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: "FETCH_PUBLIC_PROFILE_ERROR",
      payload: err,
    });

    showThrowError(err);
  }
};

export const getPublicServiceDetail = (_id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_PUBLIC_USER_PROFILE" });
    let categoryName = sessionStorage.getItem("categoryName") || "";
    const { data } = await axios.get(
      `${APIs.GET_USER_SINGLE_SERVICE}/${_id}?categoryName=${categoryName}`
    );
    if (data.status) {
      dispatch({
        type: "SET_SSERVICE_DETAIL",
        payload: data.data || {},
      });
    } else {
      dispatch({
        type: "SET_SSERVICE_DETAIL",
        payload: {},
      });
    }
  } catch (err) {
    dispatch({
      type: "FETCH_PUBLIC_PROFILE_ERROR",
      payload: err,
    });

    showThrowError(err);
  }
};

export const resetSinglServiceDetails = () => async (dispatch) => {
  dispatch({
    type: "RESET_SERVICE_DETAIL",
    payload: {},
  });
};

export const downloadVCard = async (_id, setSaving, name = "") => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${APIs.GENERATE_CARD_DETAILS}/${_id}`,
      responseType: "blob",
    });
    // const blob = new Blob([data.body], { type: data.body.type });
    saveBlobToFile(data, `${name}.vcf`);

    setSaving(false);
  } catch (err) {
    setSaving(false);
    showThrowError(err);
  }
};
const saveBlobToFile = (blob, filename) => {
  // Create a URL for the Blob
  const url = window.URL.createObjectURL(new Blob([blob]));

  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);

  // Append the link to the document body
  document.body.appendChild(link);

  // Simulate a click event to trigger the download
  link.click();

  // Cleanup
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};

export const updateShoppingCart = () => (dispatch) => {
  let userId = sessionStorage.getItem("userId") || undefined;
  if (userId === undefined) return;
  let cart = JSON.parse(sessionStorage.getItem(userId)) || [];
  dispatch({
    type: "SET_SHOPPING_CART_COUNT",
    payload: {
      cartItemCount: cart.length,
      items: cart,
    },
  });
};
export const clearShoppingCart = () => (dispatch) => {
  dispatch({
    type: "RESET_SHOPPING_CART_ITEM",
  });
  let userId = sessionStorage.getItem("userId") || undefined;
  if (userId === undefined) return;
  sessionStorage.removeItem(userId);
};
// place order action
export const placeOrder =
  (payload, onAction = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios.post(APIs.PLACE_ORDER, { ...payload });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        showSuccess(data.msg);
        if (onAction !== undefined) {
          onAction();
        }
        dispatch(clearShoppingCart());
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });

      showThrowError(err);
    }
  };
