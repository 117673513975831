import React, { useState, useEffect } from "react";

import { Accordion, Modal } from "react-bootstrap";
import usePageTitle from "../../../common/hooks/usePageTitle";

import { connect } from "react-redux";
import {
  addEditUserService,
  deleteUserService,
  getUserServices,
} from "../../../store/actions/travelServiceActions";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import ConfirmationAlert from "../../../shared/Model/ConfirmationAlert";
import { LABELS } from "../../../common/label";
import ProductForm from "../ProfileProcess/ProductForm";
import VehicleItem from "./VehicleItem";
import SearchFilter from "../../../shared/SearchFilter";
import ASSETS from "../../../common/images";
import { onShareHelper } from "../../../common/helpers/_share";
import VehhicleForm from "../ProfileProcess/Travel/VehicleForm";

const MyVehicles = ({
  heading,
  getUserServices,
  loading,
  lists,
  categoryName,
  imageBackendUrl,
  addEditUserService,
  submitting,
  deleteUserService,
  userDetails,
}) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [search, setSearch] = useState(null);
  let filterType = "vehicleInfo";
  useEffect(() => {
    getUserServices({
      pageNumber: 1,
      limit: 100,
      sortType: "LATEST",
      search: "",
      type: "vehicleInfo",
    });
  }, [getUserServices]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search || search !== null) {
        getUserServices(
          {
            search,
            pageNumber: 1,
            limit: 100,
            sortType: "LATEST",
            type: "vehicleInfo",
          },
          1
        );
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, getUserServices]);

  const handleSubmit = (values) => {
    let editMode = !showAddForm;
    let action = !editMode ? handleAddAction() : undefined;
    addEditUserService(values, action, callAgainApi);
  };

  const handleDeleteClick = (event, data) => {
    event.stopPropagation();
    if (data?._id) {
      deleteHandler(data?._id);
    }
  };
  const deleteHandler = (deleteId) => {
    setDeleteId(deleteId);
    setShowConfirm(true);
  };

  const deleteActionHanlder = () => {
    setDeleting(true);
    deleteUserService(deleteId, onAction);
  };

  const onAction = () => {
    setShowConfirm(false);
    setDeleteId(null);
    setDeleting(false);
    callAgainApi();
  };

  const callAgainApi = () => {
    getUserServices({
      pageNumber: 1,
      limit: 100,
      sortType: "LATEST",
      search: "",
      type: "vehicleInfo",
    });
  };
  const handleAddAction = () => {
    setShowAddForm(!showAddForm);
  };
  //   modal
  usePageTitle("My Vehicles");
  const onShare = async (id) => {
    let URL = `${window.origin}/${userDetails?.publicIdentifier}/product/${id}`;
    onShareHelper(URL);
  };
  return (
    <div className="pgmyproduct">
      {loading ? <ActivityLoader /> : null}
      {!showAddForm ? (
        <>
          <div className="bottom_plus" onClick={handleAddAction}>
            <span className="material-symbols-outlined">add</span>
          </div>
          <div className="search_with_filter">
            <SearchFilter
              setSearch={setSearch}
              search={search}
              placeholder="Search Name"
              showLabel={true}
            />
            {/* <Link to="/account/filter" className="my_filter">
        <img src={ASSETS.filterIcon} alt="" />
        <span className="grad_text gt_border">Filter</span>
      </Link> */}
          </div>
          <div className="vg12"></div>
          <div className="my_products">
            {" "}
            {lists.length > 0 ? (
              <Accordion defaultActiveKey="-1" className="theme_accordion">
                {lists.map((data, index) => {
                  let thumbnail =
                    data.thumbnail !== ""
                      ? `${imageBackendUrl}${data.thumbnail}`
                      : "";
                  return (
                    <Accordion.Item
                      eventKey={index.toString()}
                      key={index}
                      className="relative"
                    >
                      <Accordion.Header>
                        <div className="left">
                          <h5 className="product_name">{data?.title}</h5>
                          <h6 className="product_id">
                            ID : #{data?.serviceNo}
                          </h6>
                        </div>
                        <div className="right">
                          <span
                            class="material-symbols-outlined icon_color "
                            onClick={() => onShare(data._id)}
                            style={{
                              fontSize: "21px",
                              marginRight: "3px",
                            }}
                          >
                            share
                          </span>
                          <span
                            className="material-symbols-outlined red"
                            onClick={(e) => handleDeleteClick(e, data)}
                          >
                            delete_forever
                          </span>
                          <span className="material-symbols-outlined open icon_grad">
                            add
                          </span>
                          <span className="material-symbols-outlined close icon_grad">
                            remove
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <VehicleItem
                          data={data}
                          thumbnail={thumbnail}
                          categoryName={categoryName}
                          handleSubmit={handleSubmit}
                          submitting={submitting}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            ) : (
              <div>No Product Added yet</div>
            )}
          </div>
        </>
      ) : (
        <div className="add_more_items_div relative">
          <div className="close_media" onClick={handleAddAction}>
            <span className="material-symbols-outlined">close</span>
          </div>

          <VehhicleForm
            data={{
              type: filterType,
              title: "",
              totalSeats: "",
              priceType: "negotiable",
              minimumPrice: "",
              pricePerKm: "",
              description: "",
              thumbnail: "",
              features: [],
            }}
            handleSubmit={handleSubmit}
            index={-1}
            submitting={submitting}
            categoryName={categoryName}
          />
        </div>
      )}

      {showConfirm && (
        <ConfirmationAlert
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
          actionHandler={deleteActionHanlder}
          deleting={deleting}
          labelName={LABELS.SERVICE_TITLE[categoryName] || "item"}
        />
      )}
    </div>
  );
};
const mapStateToProps = ({
  form,
  service: { loading, lists, totalDocuments, imageBackendUrl },
}) => {
  return {
    submitting: form.submitting,
    loading,
    lists,
    totalDocuments,
    imageBackendUrl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserServices: (payload) => dispatch(getUserServices(payload)),

    addEditUserService: (payload, onAction, callAgainApi) =>
      dispatch(addEditUserService(payload, onAction, callAgainApi)),
    deleteUserService: (_id, onAction) =>
      dispatch(deleteUserService(_id, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyVehicles);
