import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// portal navbar
import PortalNavbar from "../PortalNavbar";
import ShoppingCartIem from "./ShoppingCartIem";
import { updateShoppingCart } from "../../../store/actions/publicActions";
import ConfirmationAlert from "../../../shared/Model/ConfirmationAlert";
import { showSuccess } from "../../../store/common/helpers/responseHelper";

const SoppingCart = () => {
  let { username } = useParams();
  let { cartItemCount, items } = useSelector((state) => state.shoppingCart);
  const [total, setTotal] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTotalAmount();
  }, [items]);
  const fetchTotalAmount = () => {
    let total = items.reduce((acc, curr) => acc + curr.price * curr.qty, 0);
    setTotal(total);
  };

  const updateCartItems = (item, qty) => {
    let userId = sessionStorage.getItem("userId") || undefined;
    if (userId === undefined) return;
    let cart = JSON.parse(sessionStorage.getItem(userId)) || [];
    let updatedCart = [...cart];

    // Check if the item already exists in the cart
    const existingItemIndex = updatedCart.findIndex(
      (cartItem) => cartItem._id === item._id
    );
    if (existingItemIndex !== -1) {
      // If item exists, update its quantity
      updatedCart[existingItemIndex].qty = qty;
    } else {
      // If item doesn't exist, add it to the cart
      updatedCart.push({ ...item, qty: qty });
    }

    sessionStorage.setItem(userId, JSON.stringify(updatedCart));
    // disptach action
    dispatch(updateShoppingCart());
  };

  const handleDeleteClick = (_id) => {
    if (_id) {
      deleteHandler(_id);
    }
  };

  const deleteHandler = (deleteId) => {
    setDeleteId(deleteId);
    setShowConfirm(true);
  };

  const deleteActionHanlder = () => {
    //delete cart item logic
    if (deleteId) {
      let userId = sessionStorage.getItem("userId") || undefined;
      if (userId === undefined) return;
      let cart = JSON.parse(sessionStorage.getItem(userId)) || [];
      let updatedCart = [...cart];
      updatedCart = updatedCart.filter((item) => item._id !== deleteId);

      sessionStorage.setItem(userId, JSON.stringify(updatedCart));
      // disptach action
      dispatch(updateShoppingCart());
      setShowConfirm(false);
      setDeleteId(null);
      showSuccess("Item deleted from the cart");
    }
  };

  return (
    <div className="portal_page_spacing">
      <PortalNavbar
        title="Cart"
        backgroundColor="#D6EFFF"
        backArrow="arrow_back"
      />
      <div
        className="cart_pg
    "
      >
        {items.length > 0 ? (
          <>
            <div className="cart_single_parent">
              {items.map((item) => (
                <ShoppingCartIem
                  key={item?._id}
                  item={item}
                  updateCartItems={updateCartItems}
                  handleDeleteClick={handleDeleteClick}
                />
              ))}
            </div>
            <div className="vg12"></div>
            <div className="total r14 text-end">
              Total = <span className="sb16">₹ {total}</span>
            </div>
            <div className="vg22"></div>
            <div className="col-12">
              <Link to={`/${username}/checkout`} className="theme_btn">
                Order Now
              </Link>
            </div>
          </>
        ) : (
          <h4 className="m14 text-center red">No Item Added yet!</h4>
        )}
      </div>
      {showConfirm && (
        <ConfirmationAlert
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
          actionHandler={deleteActionHanlder}
        />
      )}
    </div>
  );
};

export default SoppingCart;
