import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getSession } from "../store/actions/authActions";

import { Link } from "react-router-dom";

// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import SiteFooterTop from "./SiteFooterTop";
import RefferalForm from "./Form/RefferalForm";
import useScrollToTop from "../common/hooks/useScrollToTop";
import LiveChatScript from "./LiveChatScript";
import GoogleMetaTag from "../component/GoogleMetaTag";
const SiteReferEarn = () => {
  let { Authorization } = getSession();
  useScrollToTop();
      //   modal for view demo website links
      const [showDemoWebsite, setShowDemoWebsite] = useState(false);

      const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
      const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
      //   modal for view demo website links
  return (
    <div className="website_pages site_contactus">
      <GoogleMetaTag title={"Refer & Earn"} content={"BusinessBaab website refer and earn"} />
      <SiteHeader />
      <div className="page">
        <section>
          <div className="container">
            <div className="text-center">
              <h1 className="pgtitle">Earn on weekly basis with BusinessBaab</h1>
            </div>
            <div className="sitevg40"></div>
            <div className="pgtitle_b_desc">
              {/* हमारे रेफरल कार्यक्रम का उपयोग करके आज से ही अपनी कमाई शुरू करें!
              BusinessBaab के लिए एक प्रमोटर बनें और प्रत्येक सफल रेफरल के लिए
              20% कमीशन अर्जित करें। */}
              Start your earnings today by using our referral program! Become a promoter for BusinessBaab and earn 20% commission for each successful referral.
            </div>
          </div>
        </section>
        <section className="how_work">
          <div className="container">
            <div className="how_work_inner">
              {/* <h4>यह ऐसे काम करता है:</h4> */}
              <h4>Here's how it works:</h4>
              {/* <ol>
                <li>
                  <span className="semibold">अपना प्रमोटर खाता बनाएँ :</span>{" "}
                  प्रमोटर बनने के लिए नीचे दिया गया फॉर्म भरें। एक बार स्वीकृत
                  होने पर, आपको एक यूनिक QR Code और रेफरल लिंक प्राप्त होगा।
                </li>
                <li>
                  <span className="semibold">साझा करें :</span> अपना QR Code या
                  रेफरल लिंक दूसरों के साथ साझा करें। उन्हें BusinessBaab का
                  उपयोग करके एक वेबसाइट बनाने के लिए प्रोत्साहित करें।
                </li>
                <li>
                  <span className="semibold"> कमाएं :</span> जब कोई आपके लिंक या
                  QR Code का उपयोग करके वेबसाइट बनाता है और भुगतान करता है, तो
                  आपको 20% कमीशन मिलता है।
                </li>
                <li>
                  <span className="semibold">साप्ताहिक भुगतान :</span>  हम हमारे प्रमोटर्स को साप्ताहिक या 15 दिनों में भुगतान करते हैं, जैसा आप पसंद करें।
                  

                </li>
              </ol> */}
                <ol>
                <li>
                  <span className="semibold">Create your Promoter Account :</span>{" "}
                  Fill out the form below to become a Promoter. Once approved, you will receive a unique QR Code and referral link.
                </li>
                <li>
                  <span className="semibold">Share :</span> Share your QR Code or referral link with others. Encourage them to create a website using BusinessBaab.
                </li>
                <li>
                  <span className="semibold">Earn :</span> When someone creates a website using your link or QR Code and pays, you get a 20% commission. (We also offer an additional 10% discount on accounts created by you)
                </li>
                <li>
                  <span className="semibold">Weekly Payments :</span>  We pay our Promoters weekly or every 15 days, as you prefer.
                  

                </li>
              </ol>
            </div>
          </div>
        </section>
        <RefferalForm />
       <SiteFooterTop/>
      </div>
      <SiteFooter />
      {/* <LiveChatScript/> */}
            {/* modal for show demo website links  */}
            <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
            यहाँ कुछ ऑरिजिनल वेबसाइट्स की लिंक्स दी गयी हैं, जो BusinessBaab के द्वारा बनाई गयी हैं।
          </h6>
          <h6 className="r16 text-start">
            आपकी वेबसाइट भी ऐसी ही दिखेगी, इन्हें आप देख सकते हैं।
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to='https://businessbaab.com/absne' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shine.collections' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
            Shine Collection
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                <span>
                Shree Sanwariya Decoration
                </span>
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/a2z' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
          A 2 Z Collections
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/almadeeha' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Al-Madeeha Perfumes
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
          </div>
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}>Okay, Got it.</div>
      </Modal>
    </div>
  );
};

export default SiteReferEarn;
