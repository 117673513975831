import axios from "../common/api_helpers";
import { APIs, NAVIGATION_ROUTE } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import toast from "../common/toast";
export const sendOTP = (payload, resetForm, onAction) => async (dispatch) => {
  try {
    dispatch({
      type: "FORM_SUBMITTING",
    });
    const { data } = await axios.post(APIs.SEND_OTP, { ...payload });
    dispatch({
      type: "FORM_SUCCESS",
    });
    dispatch({
      type: "RESET_FORM_SUBMITTING",
    });
    if (data.status) {
      dispatch({
        type: "NUMBER_VALIDATE_SUCCESS",
        payload: {
          ...payload,
          OTP: data?.data?.otp,
        },
      });
      resetForm();
      onAction();

      dispatch({
        type: "INITIALIZE_FORM",
      });
      showSuccess(data.msg);
    } else {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "RESET_FORM_SUBMITTING",
    });
    dispatch({ type: "LOGIN_ERROR", err });
    showThrowError(err);
  }
};

export const verifyOTP =
  (OTP, navigate, setHasError) => async (dispatch, getState) => {
    try {
      const { auth } = getState();

      let payload = {
        otp: OTP,
        ...auth.tempInfo,
      };
      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(APIs.VERIFY_OTP, { ...payload });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        dispatch({
          type: "LOGIN_SUCCESSS",
          payload: {
            ...data.data,
          },
        });
        dispatch({
          type: "NUMBER_VALIDATE_SUCCESS",
          payload: {},
        });
        //save session
        saveSession(data.data);

        if (data?.data?.profileStep === 0) {
          navigate(NAVIGATION_ROUTE.CHOOSE_PLAN);
        } else if (data?.data?.profileStep === 1) {
          navigate(NAVIGATION_ROUTE.PERSONAL_INFO);
        } else {
          navigate(NAVIGATION_ROUTE.USER_ACCOUNT);
        }
        showSuccess(data.msg);
      }
      if (!data.status) {
        setHasError(true);
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      dispatch({ type: "LOGIN_ERROR", err });
      showThrowError(err);
    }
  };

export const resendOTP = (setCounter) => async (dispatch, getState) => {
  try {
    const { auth } = getState();

    let payload = {
      ...auth.tempInfo,
    };
    dispatch({
      type: "FORM_SUBMITTING",
    });
    const { data } = await axios.post(APIs.SEND_OTP, { ...payload });
    dispatch({
      type: "RESET_FORM_SUBMITTING",
    });
    if (data.status) {
      dispatch({
        type: "NUMBER_VALIDATE_SUCCESS",
        payload: {
          ...payload,
          OTP: data?.data?.otp,
        },
      });
      setCounter(25);
      showSuccess(data.msg);
    } else {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "RESET_FORM_SUBMITTING",
    });
    dispatch({ type: "LOGIN_ERROR", err });
    showThrowError(err);
  }
};

export const createAccountOrLogin =
  (payload, navigate, newAccount = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });
      let URL = newAccount ? APIs.CREATE_ACCOUNT : APIs.LOGIN;
      const { data } = await axios.post(URL, { ...payload });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        dispatch({
          type: "LOGIN_SUCCESSS",
          payload: {
            ...data.data,
          },
        });
        //save session
        saveSession(data.data);

        if (data?.data?.profileStep === 0) {
          navigate(NAVIGATION_ROUTE.CHOOSE_PLAN);
        } else if (data?.data?.profileStep === 1) {
          navigate(NAVIGATION_ROUTE.PERSONAL_INFO);
        } else {
          navigate(NAVIGATION_ROUTE.USER_ACCOUNT);
        }
        showSuccess(data.msg);
      }
      if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      dispatch({ type: "LOGIN_ERROR", err });
      showThrowError(err);
    }
  };

function saveSession(data) {
  localStorage.setItem("Authorization", data?.token);
  localStorage.setItem("phoneNumber", data?.phoneNumber);
  localStorage.setItem("profileStep", data?.profileStep);
  localStorage.setItem("_id", data?._id);
  localStorage.setItem("email", data?.email);
  localStorage.setItem("name", data?.name);
}

export const getSession = () => {
  return {
    Authorization: localStorage.getItem("Authorization"),
    user_id: localStorage.getItem("_id"),
    profileStep: localStorage.getItem("profileStep"),
    phoneNumber: localStorage.getItem("phoneNumber"),
    email: localStorage.getItem("email"),
    name: localStorage.getItem("name"),
    categoryName: localStorage.getItem("categoryName"),
  };
};
export const clearSession = () => {
  localStorage.removeItem("Authorization");
  localStorage.removeItem("id");
  localStorage.clear();
};

export const logout = (navigate) => async (dispatch) => {
  try {
    //remove cookie
    // removeCookie();
    dispatch({ type: "SIGNOUT_SUCCESS" });

    navigate("/");
    clearSession();

    dispatch({ type: "SAVE_BASIC_PROFILE", payload: {} });

    showSuccess("logged out successfully.");
  } catch (err) {
    console.log(err);
  }
};

export const sendOtpForDeleteAccount = (setShowOtp) => async (dispatch) => {
  try {
    dispatch({
      type: "FORM_SUBMITTING",
    });
    const { data } = await axios.get(
      APIs.SEND_OTP_FOR_DELETE_ACCOUNT_CONFIRMATION
    );
    dispatch({
      type: "FORM_SUCCESS",
    });
    dispatch({
      type: "RESET_FORM_SUBMITTING",
    });
    if (data.status) {
      dispatch({
        type: "NUMBER_VALIDATE_SUCCESS",
        payload: {
          OTP: data?.data?.otp,
        },
      });

      dispatch({
        type: "INITIALIZE_FORM",
      });
      showSuccess(data.msg);
      setShowOtp(true);
    } else {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({
      type: "RESET_FORM_SUBMITTING",
    });
    dispatch({ type: "LOGIN_ERROR", err });
    showThrowError(err);
  }
};

export const verifyActionOTP =
  (payload, onAction) => async (dispatch, getState) => {
    try {
      const { auth } = getState();

      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(APIs.VERIFY_ACTION_OTP, { ...payload });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        dispatch({
          type: "LOGIN_SUCCESSS",
          payload: {
            ...data.data,
          },
        });
        showSuccess(data.msg);
        if (payload.action === "deleteConfirmation") {
          clearSession();
        } else if (payload.action === "confirmOtpFromChangeNumber") {
          delete payload.otp;
          dispatch({
            type: "NUMBER_VALIDATE_SUCCESS",
            payload: {
              ...payload,
              OTP: data?.data?.otp,
            },
          });
          sessionStorage.setItem("changeReqNumber", payload.phoneNumber);
        } else if (payload.action === "confirmOtpFromNewNumber") {
          dispatch({
            type: "NUMBER_VALIDATE_SUCCESS",
            payload: {},
          });
          dispatch({
            type: "APPEND_PROFILE_DATA",
            payload: {
              phoneNumber: payload.phoneNumber,
            },
          });
          sessionStorage.removeItem("changeReqNumber");
        }
        onAction();
      }
      if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      dispatch({ type: "LOGIN_ERROR", err });
      showThrowError(err);
    }
  };

export const sentOTPForConfirmNumber =
  (payload, setStep) => async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(APIs.SENT_OTP_FOR_COMMON_ACTION, {
        ...payload,
      });
      dispatch({
        type: "FORM_SUCCESS",
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        dispatch({
          type: "NUMBER_VALIDATE_SUCCESS",
          payload: {
            OTP: data?.data?.otp,
          },
        });

        dispatch({
          type: "INITIALIZE_FORM",
        });
        showSuccess(data.msg);
        setStep(1);
      } else {
        showError(data.msg);
      }
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      dispatch({ type: "LOGIN_ERROR", err });
      showThrowError(err);
    }
  };
