import React, { useEffect, useState } from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { useDispatch, useSelector } from "react-redux";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import { changeUserStatus } from "../../../store/actions/userActions";
import { useNavigate } from "react-router-dom";
const colors = [
  "ED00FD_5200F6",
  "FDBA00_F60000",
  "_50FF6D_15D100",
  "_41FFFA_00BDB8",
  "FFA723_C87800",
  "FD2F96_DA006D",
  "B4B4B4_000000",
  "_3075FF_0036A3",
  "D3BF9C_947D53",
  "D9D9D9_979695",
];
const ChangeTheme = ({ currentTheme, currentTemplate }) => {
  const [selected, setSelected] = useState(colors[0]);
  let { submitting } = useSelector((state) => state.form);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  usePageTitle("Color Theme");

  useEffect(() => {
    if (currentTheme !== undefined && currentTheme !== "")
      setSelected(currentTheme);
  }, [currentTheme]);

  const changeTheme = (theme) => {
    setSelected(theme);
    dispatch(
      changeUserStatus(
        {
          type: "theme",
          theme: {
            template: currentTemplate ?? "",
            colorClass: theme,
          },
        },
        navigate,
        "/account"
      )
    );
  };

  return (
    <div className="changethemecolor">
      {submitting ? <ActivityLoader /> : null}
      {colors.map((color, i) => (
        <div
          className={`color_div ${color} cursor`}
          key={`color-${i}`}
          onClick={() => changeTheme(color)}
        >
          {selected === color && (
            <div className="selected_color">
              <span className="material-symbols-outlined">task_alt</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ChangeTheme;
