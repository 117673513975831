import { ErrorMessage, Field, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import TextError from "../../../../shared/TextError";
import {
  travelServiceInfoSchema,
  vehicleInfoSchema,
} from "../../../../common/validations/formValidation";
import ASSETS from "../../../../common/images";
import ActivityIndigator from "../../../../shared/ActivityIndigator";
import { LABELS } from "../../../../common/label";
import CommonImageCropper from "../../../../shared/Model/CommonImageCropper";
import { IMAGE_SETTINGS, VALID_IMAGE } from "../../../../common/constants";
import { showError } from "../../../../store/common/helpers/responseHelper";
import { Link } from "react-router-dom";

const optionLists = [
  "Air Ticket Booking",
  "Railway Ticket Booking",
  "Bus Ticket Booking",
  "Hotel Booking",
];
const VehhicleForm = ({ data, handleSubmit, index, submitting }) => {
  return (
    <Formik
      initialValues={{
        ...data,
      }}
      onSubmit={handleSubmit}
      validationSchema={travelServiceInfoSchema}
      enableReinitialize={true}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form_field">
                <label htmlFor={`title${index}`}>Service Name*</label>
                <Field as="select" name="title">
                  <option value="">Select</option>
                  {optionLists.map((o) => (
                    <option value={o} key={o}>
                      {o}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="title" component={TextError} />
              </div>
            </div>

            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field ">
                <label
                  htmlFor={`description${index}`}   
                  className="d-flex justify-content-between mb-2"              
                >
                  Service Description{" "}
                  <Link className="grad_text gt_border">Writing using AI</Link>
                </label>
                <Field
                  as="textarea"
                  name="description"
                  id={`description${index}`}
                  placeholder="Describe your service"
                  style={{ whiteSpace: "pre-line", resize: "none" }}
                  wrap="soft"
                />{" "}
                <ErrorMessage name="description" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <button
                type="submit"
                className="theme_btn short_btn"
                disabled={submitting}
              >
                {submitting ? (
                  <ActivityIndigator />
                ) : (
                  <> {values?._id ? "Update" : "Add"}</>
                )}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default VehhicleForm;
