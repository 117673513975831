import React, { useState } from "react";
import GoogleMetaTag from '../component/GoogleMetaTag'
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

// import component 
import CreateAccount from "./CreateAccount";
import LoginPassword from "./LoginPassword";

const PgEmailPassword = () => {
    // on select region
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };
    // on select region

      //   modal for view demo website links
  const [showDemoWebsite, setShowDemoWebsite] = useState(false);

  const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
  const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
  //   modal for view demo website links

    return (
        <div className="vendor_page_spacing">
            <GoogleMetaTag title={"Login"} content={"Login page of businessbaab website content"} />
            <div className="create_protal align_center_pg">
                <div className="cp_inner acp_inner text-center">
                    <div className="logo">
                        <img src="./assets/img/logo_businessbaab.svg" alt="" />
                    </div>
                    <div className="vg22"></div>
                    <h2 className="sb22">Welcome to BusinessBaab</h2>
                    <div className="vg22"></div>
                    <h4 className="r18">{!isActive ? "Signup" : "Login"}</h4>
                    <div className="vg16"></div>
                    <div className="email_password_form">
                        <div className="custom_tab">
                            <div className={`ct_single ${isActive ? "" : "active"}`} onClick={handleClick}>
                                <h6 className="m14">
                                    Create New Account
                                </h6>
                            </div>
                            <div className={`ct_single ${isActive ? "active" : ""}`} onClick={handleClick}>
                                <h6 className="m14">
                                    Website Login
                                </h6>
                            </div>
                        </div>
                        {!isActive ? (<CreateAccount />) : (<LoginPassword />)}

                    </div>
                    <div className="vg22"></div>{" "}
                    <Link to='/' className="grad_text gt_border">Go To Website</Link>
                    <div className="vg12"></div>
                    <div className="grad_text d-flex align-items-center justify-content-center" style={{
                        gap: "5px"
                    }}
                        onClick={handleShowDemoWebsiteModal}>
                        <span class="material-symbols-outlined" style={{
                            fontSize: "20px"
                        }}>
                            visibility
                        </span>
                        <span className="gt_border grad_text">View Demo Website</span>
                    </div>
                </div>
                   {/* modal for show demo website links  */}
      <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
            यहाँ कुछ ऑरिजिनल वेबसाइट्स की लिंक्स दी गयी हैं, जो BusinessBaab के द्वारा बनाई गयी हैं।
          </h6>
          <h6 className="r16 text-start">
            आपकी वेबसाइट भी ऐसी ही दिखेगी, इन्हें आप देख सकते हैं।
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to='https://businessbaab.com/absne' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shine.collections' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Shine Collection
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                <span>
                <span>
                Shree Sanwariya Decoration
                </span>
                </span>
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/a2z' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                A 2 Z Collections
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/almadeeha' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Al-Madeeha Perfumes
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
          </div>
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}>Okay, Got it.</div>
      </Modal>
            </div>

        </div>
    )
}

export default PgEmailPassword
