const initialState = {
  error: "",
  loading: false,
  user: {},
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USER_PROFILE":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SAVE_BASIC_PROFILE":
      return { ...state, loading: false, error: null, user: action.payload };
    case "SAVE_PROFILE_ERROR":
      return { ...state, loading: false, error: action.err.message };
    case "APPEND_PROFILE_DATA":
      return {
        ...state,
        error: null,
        loading: false,
        user: {
          ...state.user,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

const dashboardState = {
  counts: {
    totalVisitors: 0,
    totalDownloads: 0,
    totalLeads: 0,
    totalDoneOrders: 0,
    totalRevenue: 0,
  },
  loading: false,
  error: "",
};

export const dashboardReducer = (state = dashboardState, action) => {
  switch (action.type) {
    case "FETCH_DASHBOARD_ITEM":
      return { ...state, loading: true };
    case "SET_DASHBOARD_ITEM":
      return {
        ...state,

        counts: { ...action.counts },
        loading: false,
      };
    case "FETCH_DASHBOARD_ITEM_ERROR":
      return {
        ...state,
        counts: {
          totalVisitors: 0,
          totalDownloads: 0,
          totalLeads: 0,
          totalDoneOrders: 0,
          totalRevenue: 0,
        },
      };
    default:
      return state;
  }
};

export default userReducer;
