import { APIs } from "../common/constants";
import { showThrowError } from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
export const getDashboardItems = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_DASHBOARD_ITEM" });

    const { data } = await axios.post(`${APIs.GET_ANALYTICS_COUNTS}`, {
      ...payload,
    });

    if (data.status) {
      dispatch({
        type: "SET_DASHBOARD_ITEM",
        counts: {
          totalVisitors: data.data.totalVisitors || 0,
          totalDownloads: data.data.totalDownloads || 0,
          totalLeads: data.data.totalLeads || 0,
          totalDoneOrders: data.data.totalDoneOrders || 0,
          totalRevenue: data.data.totalRevenue || 0,
        },
      });
    } else {
      dispatch({
        type: "SET_DASHBOARD_ITEM",
        counts: {
          totalVisitors: 0,
          totalDownloads: 0,
          totalLeads: 0,
          totalDoneOrders: 0,
          totalRevenue: 0,
        },
      });
    }
  } catch (err) {
    dispatch({
      type: "FETCH_DASHBOARD_ITEM_ERROR",
      payload: err.message,
    });
    showThrowError(err.message);
  }
};
