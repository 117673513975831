import React from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";

const TermAndCondition = () => {
  usePageTitle("Terms & Conditions");
  return (
    <div className="pg_tp static_pg">
      <p className="r14">
        Welcome to BusinessBaab! Before using our platform, please read these Terms and Conditions carefully. By accessing or using BusinessBaab, you agree to be bound by these terms, which govern your use of our services.
      </p>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">1. Acceptance of Terms:</h6>
        <p className="r14">
          By accessing or using BusinessBaab, you agree to comply with these Terms and Conditions, as well as any additional terms and policies referenced herein.
          <br></br>
          We will process a refund for the remaining unused days of your subscription period within 1-7 business days after your account closure.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">2. Use of Services:</h6>
        <p className="r14">
          You agree to use BusinessBaab solely for lawful purposes and in accordance with these Terms and Conditions. You are responsible for all activities conducted under your account.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">3. Account Registration:</h6>
        <p className="r14">
          To access certain features of BusinessBaab, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to keep your account information up to date.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">4. Payment and Subscription:</h6>
        <p className="r14">
          BusinessBaab offers subscription-based services. By subscribing to our services, you agree to pay the applicable fees and charges. Payment terms and subscription details are provided during the checkout process.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">5. Intellectual Property Rights:</h6>
        <p className="r14">
          All content and materials available on BusinessBaab, including but not limited to text, graphics, logos, images, and software, are the property of BusinessBaab or its licensors and are protected by copyright and other intellectual property laws.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">6. User Content:</h6>
        <p className="r14">
          You retain ownership of any content you create or upload to BusinessBaab. By posting or submitting content, you grant BusinessBaab a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">7. Limitation of Liability:</h6>
        <p className="r14">
          BusinessBaab and its affiliates, directors, officers, employees, agents, and suppliers shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">8. Indemnification:</h6>
        <p className="r14">
          You agree to indemnify and hold harmless BusinessBaab and its affiliates, directors, officers, employees, agents, and suppliers from any claims, damages, losses, liabilities, costs, and expenses (including attorney fees) arising out of or related to your use of BusinessBaab or any violation of these Terms and Conditions.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">9. Modifications to Terms:</h6>
        <p className="r14">
          BusinessBaab reserves the right to modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting. Continued use of BusinessBaab after any such changes constitutes your acceptance of the revised Terms and Conditions.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">10. Governing Law:</h6>
        <p className="r14">
          These Terms and Conditions shall be governed by and construed in accordance with the laws of Ujjain (M.P.) INDIA, without regard to its conflict of law principles.
        </p>
      </div>
      <div>
        <div className="vg22"></div>
        <h6 className="sb14">11. Contact Us:</h6>
        <p className="r14">
          If you have any questions or concerns about these Terms and Conditions, please contact us at info@businessbaab.com

          By using BusinessBaab, you agree to abide by these Terms and Conditions. If you do not agree to these terms, please do not use our platform.
        </p>
      </div>
    </div>
  );
};

export default TermAndCondition;
