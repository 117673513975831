import React, { useState, useEffect } from "react";
import ProductSingle from "./ProductSingle";
import { Link, useParams } from "react-router-dom";
import { LABELS } from "../../../common/label";

const LimitedProducts = ({ limitedService, categoryName }) => {
   // add class before min width 992  start
   const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

   useEffect(() => {
     const handleResize = () => {
       setIsMobile(window.innerWidth < 992);
     };
 
     window.addEventListener('resize', handleResize);
 
     return () => {
       window.removeEventListener('resize', handleResize);
     };
   }, []);
   // add class before min width 992  end
  let { username } = useParams();
  let services = limitedService?.services;
  let backendAssetUrl = limitedService?.backendAssetUrl || "";

  if (services.length > 0)
    return (
      <section className="our_product sect_padding sect_bg">
        <div className={isMobile ? "" : "container"}>
          <h3 className="sect_title">
            {LABELS.HOME_LIMITED_SERVICE[categoryName] || ""}
          </h3>
          <div className="products_parent">
            {services.map((service) => (
              <ProductSingle
                service={service}
                key={service._id}
                backendAssetUrl={backendAssetUrl}
                username={username}
              />
            ))}
          </div>
          {limitedService?.totalServices > 11 && (
            <>
              <div className="vg22"></div>
              <Link to={`/${username}/products`} className="theme_btn desktop_btn">
                {LABELS.HOME_VIEW_SERVICE[categoryName] || ""}
              </Link>
            </>
          )}
        </div>
      </section>
    );
  else return null;
};

export default LimitedProducts;
