import React, { useEffect, useState } from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { useDispatch, useSelector } from "react-redux";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import { changeUserStatus } from "../../../store/actions/userActions";
import { useNavigate } from "react-router-dom";

const templates = ["template_1", "template_2", "template_3", "template_4" ];
const templateName = {
  template_1: "(Default)",
  template_2: "(Flat)",
  template_3: "(Crazy)",
  template_4: "(Casual)",
};

const ChangeTemplate = ({ currentTemplate, currentTheme }) => {
  const [selected, setSelected] = useState(templates[0]);
  const { submitting } = useSelector((state) => state.form);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  usePageTitle("Template Layout");

  useEffect(() => {
    if (currentTemplate !== undefined && currentTemplate !== "") {
      setSelected(currentTemplate);
    }
  }, [currentTemplate]);

  const changeTemplate = (template) => {
    setSelected(template);
    dispatch(
      changeUserStatus(
        {
          type: "template",
          theme: {
            colorClass: currentTheme ?? "",
            template: template,
          },
        },
        navigate,
        "/account"
      )
    );
  };

  return (
    <div className="changeTemplate">
      {submitting && <ActivityLoader />}
      <h6 className="m16 text-center">You can change theme layout anytime.</h6>
      <div className="vg22"></div>
      <div className="themes_cards">
        {templates.map((template, i) => (
          <div
            className={`tc_single ${template} ${selected === template ? "active" : ""}`}
            key={`template-${i}`}
            onClick={() => changeTemplate(template)}
          >
            <div className="tcs_inner">
              <h5>Theme {i + 1}</h5>             
              <h6 className="mt-1">{templateName[template]}</h6>
            </div>
            {selected === template && (
              <div className="selected_template">
                <span className="material-symbols-outlined">task_alt</span>
              </div>
            )}
          </div>
        ))}
        {["Theme 5", "Theme 6", "Theme 7", "Theme 8", "Theme 9"].map((theme, i) => (
          <div className="tc_single" key={i}>
            <div className="tcs_inner">
              <h5>{theme}</h5>
            </div>
            <span className="c_soon">Coming Soon</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChangeTemplate;
