import "./App.css";
import { useEffect } from "react";
// import theme css
import { getCategory } from "./common/utils/utils";
import "./theme.scss";
// portal css import
import "./portal/Portal.css";
import { Router } from "./routes/Router";

function App() {
  let categoryName = getCategory();
  // useEffect(() => {
  //   document.title = 'businessbaab'; // Set the title dynamically
  // }, []);
  return (
    <div className={`page ${categoryName}`}>
      <Router />
    </div>
  );
}

export default App;
