import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../authentication/Login";
import Otp from "../authentication/Otp";

import PGLangauge from "../pages/PGLangauge";

import PGHelpAndSupport from "../pages/PGHelpAndSupport";

import WebsiteHome from "../businessbaab/WebsiteHome";
import SiteRefundPolicy from "../businessbaab/SiteRefundPolicy";
import SiteTermsAndCondition from "../businessbaab/SiteTermsAndCondition";
import SitePrivacyPolicy from "../businessbaab/SitePrivacyPolicy";
import SiteFaq from "../businessbaab/SiteFaq";
import SiteContactUs from "../businessbaab/SiteContactUs";
import ViewDemoWebsite from "../businessbaab/ViewDemoWebsite";
import SiteAboutUs from "../businessbaab/SiteAboutUs";
import SiteCategories from "../businessbaab/SiteCategories";
import SiteReferEarn from "../businessbaab/SiteReferEarn";
import Landing from "../authentication/Landing";
import AccountLayout from "../portal/account/AccountLayout";
import ProtectedRoute from "./ProtectedRoute";
import HomeLayout from "../portal/publicPage/HomeLayout";
import PreventProfile from "../authentication/PreventProfile";
import Region from "../authentication/Region";
import PgEmailPassword from "../authentication/PgEmailPassword";
export const Router = () => {
  return (
    <BrowserRouter basename="/">
      {/* <BrowserRouter basename="/development"> */}
      <Routes>
        {/* static website pages */}

        <Route path="/" element={<WebsiteHome />}></Route>
        <Route path="/refund-policy" element={<SiteRefundPolicy />}></Route>
        <Route
          path="/terms-conditions"
          element={<SiteTermsAndCondition />}
        ></Route>
        <Route
          path="/frequently-ask-question"
          element={<SiteFaq />}
        ></Route>
        <Route
          path="/privacy-policy"
          element={<SitePrivacyPolicy />}
        ></Route>

        <Route path="/contact-us" element={<SiteContactUs />}></Route>
        <Route path="/view-demo-website" element={<ViewDemoWebsite />}></Route>



        <Route path="/about-us" element={<SiteAboutUs />}></Route>
        <Route path="/categories" element={<SiteCategories />}></Route>
        <Route path="/referrals" element={<SiteReferEarn />}></Route>
        {/* end static website pages */}

        <Route path="/language" element={<PGLangauge />}></Route>

        <Route path="/helpandsupport" element={<PGHelpAndSupport />}></Route>

        {/* portal & dynamic routes */}
        <Route path="/intro" element={<Landing />}></Route>
        <Route path="/region" element={<Region />}></Route>
        <Route path="/login-password" element={<PgEmailPassword />}></Route>
        
        <Route path="/login" element={<Login isLogin={true} />}></Route>
        
        <Route
          path="/create-account"
          element={<Login isLogin={false} />}
        ></Route>
        <Route path="/verify-otp" element={<Otp />}></Route>
        <Route
          path="/temporary-stopped"
          element={<PreventProfile type="stopped" />}
        ></Route>
        <Route
          path="/profile-blocked"
          element={<PreventProfile type="blocked" />}
        ></Route>
        {/* account layout */}
        <Route
          path="/account/*"
          element={
            <ProtectedRoute>
              <AccountLayout />
            </ProtectedRoute>
          }
        />

        {/* end portal & dynamic routes */}

        {/* public user routes */}
        <Route path="/:username/*" element={<HomeLayout />}></Route>
        {/*  public user routes end  */}
      </Routes>
    </BrowserRouter>
  );
};
