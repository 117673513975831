import React from "react";
import { Link, useLocation } from "react-router-dom";
import ASSETS from "../../../../common/images";
import queryString from "query-string";
import {
  dateToFormat,
  dateToFormatWithTime,
} from "../../../../common/helpers/_helper";
import usePageTitle from "../../../../common/hooks/usePageTitle";

const PaymentSuccess = ({ userDetails }) => {
  const useQuery = () => {
    return queryString.parse(useLocation().search);
  };
  const query = useQuery();
  usePageTitle("");
  return (
    <div className="create_protal align_center_pg">
      <div className="cp_inner acp_inner text-center">
        <div className="logo">
          <img src={ASSETS.businessBaapLogo} alt="" />
        </div>
        <div className="vg22"></div>
        <h2 className="m20 green">Congratulations!</h2>
        <div className="vg12"></div>
        <h4 className="r16 account_date">
          Your website has activated till<br></br>
          {query?.type && query?.type === "trial" ? (
            <b> {query?.expiresAt && dateToFormatWithTime(query?.expiresAt)}</b>
          ) : (
            <b> {query?.expiresAt && dateToFormat(query?.expiresAt)}</b>
          )}
        </h4>
        <div className="vg30"></div>

        {userDetails?.publicIdentifier && (
          <Link to={`/${userDetails?.publicIdentifier}`} className="theme_btn">
            View your website
          </Link>
        )}
      </div>
    </div>
  );
};

export default PaymentSuccess;
