import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "../common/validations/formValidation";
import TextError from "../shared/TextError";
import { connect } from "react-redux";
import { createAccountOrLogin } from "../store/actions/authActions";
import ActivityIndigator from "../shared/ActivityIndigator";
import { useCookies } from "react-cookie";

const CreateAccount = ({ createAccountOrLogin, submitting }) => {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  const handleSubmit = (values) => {
    // Handle form submission logic here
    createAccountOrLogin(
      {
        ...values,
        referralCode: cookies?.referralCode || "",
      },
      navigate,
      true
    );
  };
  return (
    <div className="inner">
      <Formik
        initialValues={{
          email: "",
          password: "",
          termsAndConditions: false,
          referralCode: "",
        }}
        onSubmit={handleSubmit}
        validationSchema={loginSchema}
      >
        <Form>
          <div className="row">
            <div className="col-12">
              <div className="form_field">
                <label
                  htmlFor="email"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Email ID
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                />
                <ErrorMessage name="email" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <label
                  htmlFor="password"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Create New Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                />
                <ErrorMessage name="password" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <div className="inline_checkbox_group">
                  <div className="checkbox_single">
                    <label>
                      <Field name="termsAndConditions">
                        {({ field }) => (
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              {...field}
                              checked={field.value}
                              id="t_c"
                            />
                            <label htmlFor="t_c">
                              {" "}
                              I agreed with Terms and Privacy{" "}
                              <Link
                                to="/termsandpolicy"
                                className="grad_text gt_border"
                                target="_blank"
                              >
                                See
                              </Link>
                            </label>
                          </div>
                        )}
                      </Field>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="vg22"></div>
            <div className="col-12">
              <button type="submit" disabled={submitting} className="theme_btn">
                {submitting ? <ActivityIndigator /> : "Create"}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
const mapStateToProps = ({ form, auth }) => {
  return {
    submitting: form.submitting,
    success: form.success,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createAccountOrLogin: (data, navigate, onAction) =>
      dispatch(createAccountOrLogin(data, navigate, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);
