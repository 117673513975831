import React from "react";

// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import useScrollToTop from "../common/hooks/useScrollToTop";
import LiveChatScript from "./LiveChatScript";
import GoogleMetaTag from "../component/GoogleMetaTag";

const SiteRefundPolicy = () => {
  useScrollToTop();
  return (
    <div className="website_pages terms_conditon">
         <GoogleMetaTag title={"Refund-Policy"} content={"BusinessBaab website refund and policy"} />
      <SiteHeader />
      <section className="page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page_content">
                <div className="page_title">
                  <h1>Refund Policy</h1>
                </div>
                {/* <p>Off course, we give refund. Please read below policy.</p>
                <p>
                  At BusinessBaab, we understand that circumstances may arise
                  where you may need to close your account. Therefore, we offer
                  a fair refund policy to ensure your satisfaction. Please
                  carefully review the following conditions regarding refunds:
                </p>
                <h3>1. Account Closure Refunds:</h3>
                <p>
                  If you decide to close your BusinessBaab account, our team
                  will calculate the remaining unused days from the day your
                  account is closed.
                </p>
                <p>
                  We will process a refund for the remaining unused days of your
                  subscription period within 1-7 business days after your
                  account closure.
                </p>
                <h3>2. Contacting Our Team:</h3>
                <p>
                  You can contact us through our refund form if you want to know
                  current status of your refund.
                </p>
                <h3>3. No Refunds for Utilized Services:</h3>
                <p>
                  Please note that refunds will not be provided for any services
                  or features that you have already utilized during your
                  subscription period.
                </p>
                <h3>4. Exceptional Circumstances:</h3>
                <p>
                  In exceptional cases where BusinessBaab decides to discontinue
                  its services or close your account due to policy violations or
                  other reasons, you will be entitled to a refund for the
                  remaining unused days of your subscription period.
                </p>
                <h3>5. Contact Us:</h3>
                <p>
                  If you have any questions or need further assistance regarding
                  refunds for account closure, please do not hesitate to contact
                  our customer support team. We are here to help you.
                </p>
                <p>
                  We appreciate your understanding and cooperation regarding our
                  refund policy. If you have any concerns or require
                  clarification on any aspect of this policy, please feel free
                  to get in touch with us.
                </p> */}
                <p className="mb-3">
                  Thank you for considering BusinessBaab for your online
                  presence. We want to ensure that you have a seamless
                  experience with us. Therefore, we encourage you to fully
                  explore our platform and services before making a purchase.
                  Once you are fully satisfied with our offerings, you can
                  confidently select a plan that best suits your needs.
                </p>

                <p className="mb-3">
                  We don't offer refunds for any purchases made on our platform.
                  This includes subscription fees, one-time payments, and any
                  other charges.
                </p>
                <p className="mb-3">
                  We believe that this approach allows our customers to make
                  informed decisions and ensures a more fulfilling experience
                  with BusinessBaab. If you have any questions or need
                  assistance in selecting the right plan for you, please don't
                  hesitate to contact our customer support team.
                </p>

                <p className="mb-3">
                  Thank you for your understanding and for choosing
                  BusinessBaab.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteFooter />
      {/* <LiveChatScript/> */}
    </div>
  );
};

export default SiteRefundPolicy;
