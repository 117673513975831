import React, { useState } from "react";
import { Accordion, Modal, Form } from "react-bootstrap";

import OTPInput from "otp-input-react";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { useNavigate } from "react-router-dom";
const AccountDelete = ({
  sendOtpForDeleteAccount,
  submitting,
  tempInfo,
  verifyActionOTP,
}) => {
  const [showOtp, setShowOtp] = useState(false);
  const [OTP, setOTP] = useState("");
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const sendOtpForDelete = () => {
    if (!submitting) sendOtpForDeleteAccount(setShowOtp);
  };
  const handleChange = (otp) => {
    setHasError(false);
    setOTP(otp);
  };
  const handleVerify = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyActionOTP(
          {
            action: "deleteConfirmation",
            otp: OTP,
          },
          onAction
        );
      }
    } else {
      if (OTP.length !== 4) {
        setHasError(true);
        return;
      }
      if (OTP) {
        verifyActionOTP(
          {
            action: "deleteConfirmation",
            otp: OTP,
          },
          onAction
        );
      }
    }
  };
  const onAction = () => {
    setHasError(false);
    navigate("/");
  };
  return (
    <div>
      <Accordion.Item eventKey="2" className="relative">
        <Accordion.Header>
          <div className="left">
            <h5 className="product_name">Permanent Delete my Account</h5>
          </div>
          <div className="right">
            <span className="material-symbols-outlined open icon_grad">
              add
            </span>
            <span className="material-symbols-outlined close icon_grad">
              remove
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <span>{tempInfo?.OTP}</span>
          {showOtp ? (
            <Form onSubmit={handleVerify}>
              <div className="ff_gap"></div>
              <div className="form_field">
                <label htmlFor="cn_otp">OTP</label>
                <OTPInput
                  className={`otp_input ${hasError ? "b-red" : ""}`}
                  inputStyle="inputStyle"
                  value={OTP}
                  onChange={handleChange}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  isInputNum={true}
                  hasErrored={hasError}
                  placeholder="0000"
                  errorStyle="error"
                />
                {hasError && <span className="err_red ">enter valid otp</span>}
              </div>
              <div className="vg22"></div>

              <button disabled={submitting} className="modal_btn">
                {submitting ? (
                  <ActivityIndigator />
                ) : (
                  "Permanent Delete Account"
                )}
              </button>
            </Form>
          ) : (
            <h6
              className="grad_text gt_border normal_font"
              onClick={sendOtpForDelete}
            >
              Delete my account
            </h6>
          )}
        </Accordion.Body>
        {/* modal for delete product  */}
        {/* <Modal
          show={showOtp}
          onHide={() => setShowOtp(false)}
          className="my_modal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title className="m18">Delete my Account</Modal.Title>{" "}
            <span>{tempInfo?.OTP}</span>
            <span
              className="material-symbols-outlined modal_close red"
              onClick={() => setShowOtp(false)}
            >
              close
            </span>
          </Modal.Header>
          <div className="vg22"></div>
          <Modal.Body className="text_left">
            <Form onSubmit={handleVerify}>
              <div className="ff_gap"></div>
              <div className="form_field">
                <label htmlFor="cn_otp">OTP</label>
                <OTPInput
                  className={`otp_input ${hasError ? "b-red" : ""}`}
                  inputStyle="inputStyle"
                  value={OTP}
                  onChange={handleChange}
                  autoFocus
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  isInputNum={true}
                  hasErrored={hasError}
                  placeholder="0000"
                  errorStyle="error"
                />
                {hasError && <span className="err_red ">enter valid otp</span>}
              </div>
              <div className="vg22"></div>

              <button disabled={submitting} className="modal_btn">
                {submitting ? (
                  <ActivityIndigator />
                ) : (
                  "Permanent Delete Account"
                )}
              </button>
            </Form>
          </Modal.Body>
        </Modal> */}
      </Accordion.Item>
    </div>
  );
};

export default AccountDelete;
