const getCategory = () => {
  return sessionStorage.getItem("categoryName") || "All Services";
};
const geAuthCategory = () => {
  return localStorage.getItem("categoryName") || "All Services";
};
const isShowStockEntity = () => {
  let flag = localStorage.getItem("categoryName") || "All Services";
  return flag !== "All Services";
};

let getGender = (gender) => {
  if (gender === "male") return "Mr.";
  else if (gender === "female") return "Mrs.";
  return "";
};

export { getCategory, geAuthCategory, isShowStockEntity, getGender };
