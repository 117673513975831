import React, { useState } from "react";
import ConfirmationAlert from "../../shared/Model/ConfirmationAlert";
import Accordion from "react-bootstrap/Accordion";
import { deleteContact } from "../../store/actions/contactActions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const ContactList = ({ contacts, totalContacts, deleteContact }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const handleDeleteClick = (event, data) => {
    event.stopPropagation();
    if (data?._id) {
      deleteHandler(data?._id);
    }
  };

  const deleteHandler = (deleteId) => {
    setDeleteId(deleteId);
    setShowConfirm(true);
  };

  const deleteActionHanlder = () => {
    setDeleting(true);
    deleteContact(deleteId, onAction);
  };

  const onAction = () => {
    setShowConfirm(false);
    setDeleteId(null);
    setDeleting(false);
  };
  return (
    <>
      <section className="contact_enquiry">
        <h6 className="m10 grey">
          <span className="blue m14">Contact Enquiries</span>
          &nbsp; ({totalContacts || 0})
        </h6>
        <div className="vg12"></div>
        {contacts.length > 0 ? (
          <Accordion defaultActiveKey="0" className="theme_accordion">
            {contacts.map((c, index) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={index}
                className="relative"
              >
                <Accordion.Header>
                  <div className="left">
                    <h5 className="product_name">{c?.name || ""} </h5>
                  </div>
                  <div className="right">
                    <span
                      className="material-symbols-outlined red"
                      onClick={(e) => handleDeleteClick(e, c)}
                    >
                      delete_forever
                    </span>
                    <span className="material-symbols-outlined open icon_grad">
                      add
                    </span>
                    <span className="material-symbols-outlined close icon_grad">
                      remove
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="contact_person_info my_accordion_body">
                    <h6 className="m14">{c?.name || ""}</h6>
                    <h6 className="r14 black" style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px"
                    }}>
                      {c?.phoneNumber || ""}
                      <Link
                        to={"tel:" + (c?.phoneNumber || "")}
                        className="theme_btn text_near_btn relative"
                      >
                        <span className="material-symbols-outlined">call</span>
                        Call
                      </Link></h6>
                    <p className="r12">{c?.message || ""}</p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        ) : (
          <h4 className="m14">No contact Query</h4>
        )}       
      </section>
      {showConfirm && (
        <ConfirmationAlert
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
          actionHandler={deleteActionHanlder}
          deleting={deleting}
          labelName={"contact enquiry"}
        />
      )}
    </>
  );
};
const mapStateToProps = ({ contact }) => {
  return {
    contacts: contact?.contacts || [],
    totalContacts: contact?.totalContacts || 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteContact: (deleteId, onAction) =>
      dispatch(deleteContact(deleteId, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
