import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom"; // Import useNavigate
import Modal from "react-bootstrap/Modal";
import ASSETS from "../../common/images";
import { useSelector } from "react-redux";
import { getSession } from "../../store/actions/authActions";
import { downloadVCard } from "../../store/actions/publicActions";
import { CATEGORY_LISTS } from "../../common/constants";

const PortalNavbar = ({
  title,
  backgroundColor,
  backArrow,
  isHide = false,
}) => {
  let { username } = useParams();
  let { user: profile } = useSelector((state) => state.profile);
  let { cartItemCount } = useSelector((state) => state.shoppingCart);
  const navigate = useNavigate(); // Use useNavigate hook
  const [showMenu, setShowMenu] = useState(false);
  const [saving, setSaving] = useState(false);

  const onSavingHandler = () => {
    setSaving(true);
    if (profile?._id)
      downloadVCard(profile?._id, setSaving, profile?.personalInfo?.name);
  };
  let { Authorization } = getSession();

  const onToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step
  };

  const onShare = async () => {
    let URL = `${window.origin}/${profile?.publicIdentifier}`;

    const data = {
      title: "Business Baap",
      // text: this.text,
      url: URL,
    };
    // console.log(data);
    if (navigator.share) {
      navigator
        .share(data)
        .then(() => {
          console.log("Successful share! 🎉");
        })
        .catch((err) => console.error(err));
    } else {
      console.log("no share");
    }
  };

  return (
    <div className={`portal_header  ${isHide ? "header_hide" : ""}`}>
      {Authorization ? (
        <Link to={`/account`} className="menu_icon_home desktop_hide">
          <img src={ASSETS.PERSON} alt="" />
        </Link>
      ) : (
        <div className="menu_icon_home desktop_hide" onClick={onToggle}>
          <img src={ASSETS.menuIcon} alt="" />
        </div>
      )}
      <div className="desktop_hide">
        <div className="header" style={{ background: backgroundColor }}>
          <div className="header_left">
            <span
              className="material-symbols-outlined back pointer"
              onClick={handleGoBack}
            >
              {backArrow}
            </span>
            <h6 className="m18">{title}</h6>
          </div>
          <div className="header_right">
            {profile?.categoryName !== CATEGORY_LISTS.TRAVEL && (
              <Link
                to={`/${username}/shopping-cart`}
                className="shopping_cart relative"
              >
                {/* <img src={ASSETS.shoppingCartIcon} alt="" /> */}
                <div className="navbar_icon">
                  <span class="material-symbols-outlined  icon_color">
                    shopping_cart
                  </span>
                </div>
                <div className="number">{cartItemCount}</div>
              </Link>
            )}
            {/* <div className="language pointer">
            <img src={ASSETS.languageIcon} alt="" />
          </div> */}
            {Authorization ? (
              <div className="huburger_icon pointer">
                <Link to={`/account`}>
                  <div className="navbar_icon">
                    <span class="material-symbols-outlined icon_color">
                      person
                    </span>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="huburger_icon pointer" onClick={onToggle}>
                <div className="navbar_icon">
                  <span class="material-symbols-outlined icon_color">menu</span>
                </div>
              </div>
            )}
          </div>
          {/* modal for portal menus */}
          <Modal
            show={showMenu}
            onHide={onToggle}
            className="my_modal menu_modal d_block_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title className="m18"></Modal.Title>
              <span
                className="material-symbols-outlined modal_close red"
                onClick={onToggle}
              >
                close
              </span>
            </Modal.Header>
            <div className="vg22"></div>
            <Modal.Body>
              <div className="menus">
                <Link
                  to={`/${username}`}
                  className="menu_single"
                  onClick={onToggle}
                >
                  <div className="icon">
                    <img src={ASSETS.homeIcon} alt="" />
                  </div>
                  <h6 className="r16 text_capital">Home</h6>
                </Link>
                <Link
                  to={`/${username}/about-us`}
                  className="menu_single"
                  onClick={onToggle}
                >
                  <div className="icon">
                    <img src={ASSETS.aboutUsIcon} alt="" />
                  </div>
                  <h6 className="r16 text_capital">About us</h6>
                </Link>
                <div className="menu_single" onClick={onShare}>
                  <div className="icon">
                    <img src={ASSETS.shareIcon} alt="" />
                  </div>
                  <h6 className="r16 text_capital">Share portal link</h6>
                </div>
                <Link
                  to={`/${username}/qrcode`}
                  className="menu_single"
                  onClick={onToggle}
                >
                  <div className="icon">
                    <img src={ASSETS.QRcodeIcon} alt="" />
                  </div>
                  <h6 className="r16 text_capital">QR code</h6>
                </Link>
                <Link
                  to={`/${username}/contact-us`}
                  className="menu_single"
                  onClick={onToggle}
                >
                  <div className="icon">
                    <img src={ASSETS.contactIcon} alt="" />
                  </div>
                  <h6 className="r16 text_capital">Contact us</h6>
                </Link>
                {!Authorization && (
                <Link to={`/region`} className="modal_btn" style={{
                  width: "fit-content"
                }}>
                  <h6 className="m16 text_capital mb-0">Login</h6>
                </Link>
              )}
              </div>
              <div className="sect_divider"></div>{" "}
              <button
                className="theme_btn btn_border save_info"
                disabled={saving}
                onClick={onSavingHandler}
              >
                {saving ? "Saving..." : " Save all information in your contact"}
              </button>
            
              {/* <div className="menus">
              <Link to="" className="menu_single" onClick={onToggle}>
                <div className="icon">
                  <img src={ASSETS.languagesIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">language</h6>
              </Link>
            </div> */}
            </Modal.Body>
          </Modal>
        </div>
      </div>

      {/* desktop header start  */}
      <div className="desktop_header">
        <div className="left">
          <Link to={`/${username}`} className="portal_logo" style={{
            padding: "0px"
          }}>
            {profile?.businessInfo?.logo ? (
              <img
                src={profile?.businessInfo?.logo}
                alt={profile?.businessInfo?.title}
              />
            ) : (
              <>
                <h2 className="make_logo grad_text">
                  {profile?.businessInfo?.title}
                </h2>
              </>
            )}
          </Link>
        </div>
        <div className="right">
          <div className="menus">
            <Link
              to={`/${username}`}
              className="menu_single"
            >
              <h6 className="m16 text_capital">Home</h6>
            </Link>
            {Authorization && (
              <Link to={`/account`} className="menu_single">
                <h6 className="m16 text_capital">Dashboard</h6>
              </Link>
            )}
            <Link
              to={`/${username}/about-us`}
              className="menu_single"
            >
              <h6 className="m16 text_capital">About us</h6>
            </Link>
            <div className="menu_single" onClick={onShare}>
              <h6 className="m16 text_capital">Share portal link</h6>
            </div>
            <Link
              to={`/${username}/qrcode`}
              className="menu_single"
            >
              <h6 className="m16 text_capital">QR code</h6>
            </Link>
            <Link
              to={`/${username}/contact-us`}
              className="menu_single"
            >
              <h6 className="m16 text_capital">Contact us</h6>
            </Link>
            {!Authorization && (
              <Link to={`/region`} className="theme_btn" style={{
                width: "fit-content"
              }}>
                <h6 className="m16 text_capital">Login</h6>
              </Link>
            )}
          </div>
        </div>
      </div>

      {/* desktop header start  */}


    </div>
  );
};

export default PortalNavbar;
