import { APIs, NAVIGATION_ROUTE } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";

// update user data
export const changeUserStatus =
  (payload, navigate = undefined, path = undefined) =>
  async (dispatch) => {
    try {
      // setToken();
      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(APIs.CHANGE_USER_DATA, { ...payload });
      dispatch({
        type: "FORM_SUCCESS",
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        if (payload?.type === "category") {
          localStorage.setItem("profileStep", 1);
          localStorage.setItem("categoryName", payload?.categoryName);
        }
        dispatch({
          type: "APPEND_PROFILE_DATA",
          payload: {
            ...payload,
          },
        });
        if (navigate !== undefined) {
          navigate(path);
        } else {
          showSuccess(data.msg);
        }
      }
      if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      showThrowError(err);
    }
  };

// function to save personal infor
export const savePersonalInfo =
  (payload, goBackOrNext = undefined) =>
  async (dispatch) => {
    try {
      let fd = new FormData();
      for (let key in payload) {
        if (key === "social") {
          fd.append(key, JSON.stringify(payload[key] || []));
        } else {
          fd.append(key, payload[key]);
        }
      }
      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios({
        method: "post",
        url: APIs.UPDATE_USER_PERSONAL_INFO,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        dispatch({
          type: "APPEND_PROFILE_DATA",
          payload: {
            ...payload,
          },
        });
        dispatch(getUserInfo());

        localStorage.setItem("profileStep", 2);
        showSuccess(data.msg);
        if (goBackOrNext !== undefined) {
          goBackOrNext();
        }
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      dispatch({ type: "SAVE_PROFILE_ERROR", err });
      showThrowError(err);
    }
  };

export const saveBusinessInfo =
  (payload, goBackOrNext = undefined) =>
  async (dispatch) => {
    try {
      let fd = new FormData();
      for (let key in payload) {
        if (key === "images" && payload[key].length > 0) {
          for (let image of payload[key]) {
            fd.append("images", image);
          }
        } else {
          fd.append(key, payload[key]);
        }
      }
      // for (let k of fd.entries()) {
      //   console.log(k);
      // }

      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios({
        method: "post",
        url: APIs.UPDATE_USER_BUSINESS_INFO,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        dispatch({
          type: "APPEND_PROFILE_DATA",
          payload: {
            ...payload,
          },
        });
        dispatch(getUserInfo());

        localStorage.setItem("profileStep", 2);
        showSuccess(data.msg);
        if (goBackOrNext !== undefined) {
          goBackOrNext();
        }
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      dispatch({ type: "SAVE_PROFILE_ERROR", err });
      showThrowError(err);
    }
  };

export const getUserInfo = () => async (dispatch) => {
  try {
    const { data } = await axios.get(APIs.GET_USER_DETAILS);
    dispatch({
      type: "SHOW_ACCOUNT_LOADER",
    });
    if (data.status) {
      dispatch({
        type: "APPEND_PROFILE_DATA",
        payload: {
          ...data.data,
        },
      });
      dispatch({
        type: "SET_CONTACT_LISTS",
        payload: data?.contact,
      });

      if (data?.data?.categoryName) {
        localStorage.setItem("categoryName", data?.data?.categoryName);
      }
    } else if (!data.status) {
      showError(data.msg);
    }
    dispatch({
      type: "HIDE_ACCOUNT_LOADER",
    });
  } catch (err) {
    dispatch({
      type: "HIDE_ACCOUNT_LOADER",
    });

    showThrowError(err);
  }
};

export const deleteBusinessLogo = async () => {
  try {
    const { data } = await axios.delete(`${APIs.DELETE_BUSINESS_LOGO}`);
    if (data.status) {
      showSuccess(data.msg);
      return true;
    } else {
      showError(data.msg);
      return false;
    }
  } catch (err) {
    showThrowError(err);
  }
};

export const deleteSideImage = async (index) => {
  try {
    const { data } = await axios.delete(
      `${APIs.DELETE_BUSINESS_IMAGE}/${index}`
    );
    if (data.status) {
      showSuccess(data.msg);
      return true;
    } else {
      showError(data.msg);
      return false;
    }
  } catch (err) {
    showThrowError(err);
  }
};

export const addEditPaymentInfo =
  (payload, goBackOrNext = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios.post(`${APIs.ADD_PAYMENT_INFO}`, {
        ...payload,
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        dispatch({
          type: "APPEND_PROFILE_DATA",
          payload: {
            // paymentInfo: data?.data || [],
            ...(data?.data || {}),
          },
        });

        showSuccess(data.msg);
        if (goBackOrNext !== undefined) {
          goBackOrNext();
        }
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      dispatch({ type: "SAVE_PROFILE_ERROR", err });
      showThrowError(err);
    }
  };
export const deletePaymentInfo = (_id, onAction) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`${APIs.DELETE_PAYMENT_INFO}/${_id}`);

    if (data.status) {
      dispatch({
        type: "APPEND_PROFILE_DATA",
        payload: {
          // paymentInfo: data?.data || [],
          ...(data?.data || {}),
        },
      });

      showSuccess(data.msg);
      if (onAction !== undefined) onAction();
    } else if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    dispatch({ type: "SAVE_PROFILE_ERROR", err });
    showThrowError(err);
  }
};

export const changeUserName =
  (payload, onAction = undefined, setAlreadyTaken = undefined) =>
  async (dispatch) => {
    try {
      // setToken();
      dispatch({
        type: "FORM_SUBMITTING",
      });
      const { data } = await axios.post(APIs.CHANGE_USERNAME, { ...payload });
      dispatch({
        type: "FORM_SUCCESS",
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        if (data?.data?.hasError) {
          setAlreadyTaken(data.msg);
        } else {
          dispatch({
            type: "APPEND_PROFILE_DATA",
            payload: {
              ...payload,
            },
          });
          showSuccess(data.msg);
          if (onAction !== undefined) onAction();
        }
      }
      if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      showThrowError(err);
    }
  };
