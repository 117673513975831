import React from "react";
import { Modal } from "react-bootstrap";

const PackageExampleModel = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} className="my_modal">
      <Modal.Header closeButton>
        <Modal.Title className="m18">Packages</Modal.Title>
        <span
          class="material-symbols-outlined modal_close red"
          onClick={props.onHide}
        >
          close
        </span>
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body
        style={{
          textAlign: "left",
        }}
      >
        <h6 className="r14">
          You can put your own package. Whatever you are giving, like:
        </h6>
        <div className="vg12"></div>
        <div className="theme_accordion style_2 ">
          <div
            className="accordion-body"
            style={{
              background: "none",
              padding: "0px",
            }}
          >
            <ul>
              <li>
                <span class="material-symbols-outlined">done</span> Mumbai to
                Goa
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Peaceful
                Ooty Holiday Package
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Pune to
                Khandala
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Delhi to
                Mata Veshno Devi
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Ahemadabad
                to Ujjain
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Divine Trip
                to Rameshwaram
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Lucknow to
                Ayodhya
              </li>
              <li>
                <span class="material-symbols-outlined">done</span> Indore to
                Kashmir Package
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PackageExampleModel;
