import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import { connect, useSelector } from "react-redux";
import { bookingSchema } from "../../../common/validations/formValidation";
import {
  getCities,
  getCountries,
  getStates,
} from "../../../store/actions/commonActions";
import { placeOrder } from "../../../store/actions/publicActions";
import TextError from "../../../shared/TextError";
import ActivityIndigator from "../../../shared/ActivityIndigator";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const BookNow = ({
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  submitting,
  placeOrder,
  profile,
  otherStates,
  otherCities,
}) => {
  let { username } = useParams();
  const navigate = useNavigate();
  let { cartItemCount, items } = useSelector((state) => state.shoppingCart);

  const query = useQuery();
  const selectedTitle = query.get("title");

  useEffect(() => {
    getCountries();
    getStates(101, false);
    getStates(101, true);
  }, [getCountries, getStates]);

    // add class before min width 992  start
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    // add class before min width 992  end

  const getTotalAmount = () => {
    let total = items.reduce((acc, curr) => acc + curr.price * curr.qty, 0);
    return total;
  };

  const bookingType = selectedTitle === "Hotel Booking" ? "hotel" : "other";

  const initialValues = {
    name: "",
    phoneNumber: "",

    otherDetails: {
      bookingType,
      toDate: "",
      toCountryId: "101",
      fromCountryId: "101",
      toStateId: "",
      fromStateId: "",
      fromCityId: "",
      toCityId: "",
      isReturn: false,
      returnDate: "",
      //hotel booking
      checkInDate: "",
      cityName: "",
      totalAdult: "",
      totalChild: "",
      checkOutDate: "",
    },
  };

  const handleSubmit = (values) => {
    if (items.length > 0) {
      values.items = items.map((item) => {
        return {
          serviceId: item?._id,
          price: item.price,
          quantity: item.qty,
        };
      });
      values.userId = sessionStorage.getItem("userId") || "";
      values.categoryName = sessionStorage.getItem("categoryName") || "";
      values.category = sessionStorage.getItem("category") || "";
      values.serviceType = sessionStorage.getItem("serviceType") || "";

      placeOrder(values, onActionBack);
    }
  };
  const orderNow = () => {
    let message = "Check out New Order Recieved:\n\n";
    message += items
      .map(
        (item) =>
          `ID:${item.serviceNo}, ${item.title}: ₹${item.price} x ${item.qty}\n`
      )
      .join("\n");

    let total = items.reduce((acc, curr) => acc + curr.price * curr.qty, 0);
    message += `\nTotal Amount: ₹${total}`;
    window.location.href = `https://wa.me/${profile?.personalInfo?.whatsapNumber
      }?text=${encodeURIComponent(message)} `;
  };

  const onActionBack = () => {
    // navigate(`/${username}`);
    // open whatsap link as well
    orderNow(items);
  };
  const handleCountryChange = (value, setFieldValue, type = "from") => {
    if (value) {
      let flag = type === "to" ? true : false;
      getStates(value, flag);
      setFieldValue(`otherDetails.${type}CountryId`, value);
      setFieldValue(`otherDetails.${type}StateId`, "");
      setFieldValue(`otherDetails.${type}CityId`, "");
    }
  };

  const handleStateChange = (value, setFieldValue, type = "from") => {
    if (value) {
      let flag = type === "to" ? true : false;
      getCities(value, flag);
      setFieldValue(`otherDetails.${type}StateId`, value);
      setFieldValue(`otherDetails.${type}CityId`, "");
    }
  };

  return (
    <div className="portal_page_spacing">
      <div className={isMobile ? "" : "container"}>
      <PortalNavbar
        title="Delivery Address"
        backgroundColor="#D6EFFF"
        backArrow="arrow_back"
      />
    <div className="desktop_sect_width dtp">
    <h6 className="m18">{selectedTitle} Inquiry</h6>
      <div className="vg22"></div>
      <div className="pg_deliveryaddress">
        <Formik
          initialValues={initialValues}
          validationSchema={bookingSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit, setFieldValue, values, errors }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 ">
                  <div className="form_field">
                    <label htmlFor="username">Name</label>
                    <Field
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter name"
                    />{" "}
                    <ErrorMessage name="name" component={TextError} />
                  </div>
                </div>
                <div className="ff_gap"></div>
                <div className="col-12">
                  <div className="form_field">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <Field
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      placeholder="+91 123456789"
                    />{" "}
                    <ErrorMessage name="phoneNumber" component={TextError} />
                  </div>
                </div>
              </div>
              {values.otherDetails?.bookingType === "other" && (
                <>
                  <div className="ff_gap"></div>
                  <div className="col-12 pl0">
                    <div className="form_field">
                      <label htmlFor="toDate">Journey Date</label>
                      <Field
                        type="date"
                        name="otherDetails.toDate"
                        id="toDate"
                        placeholder=""
                      />{" "}
                      <ErrorMessage
                        name="otherDetails.toDate"
                        component={TextError}
                      />
                    </div>
                  </div>
                  <div className="ff_gap"></div>
                  <div
                    style={{
                      background: "rgb(214, 239, 255)",
                      marginLeft: "-15px",
                      marginRight: "-15px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    }}
                  >
                    <div className="ff_gap"></div>
                    <h6 className="m14">
                      From
                      <div className="vg3"></div>
                    </h6>
                    <div className="row">
                      <div className="col-4 ">
                        <div className="form_field">
                          <label htmlFor="fromCountryId">Country</label>

                          <Field
                            as="select"
                            name="otherDetails.fromCountryId"
                            id="fromCountryId"
                            onChange={(e) =>
                              handleCountryChange(
                                e.target.value,
                                setFieldValue,
                                "from"
                              )
                            }
                            value={values.otherDetails.fromCountryId}
                          >
                            {" "}
                            <option value="">Select</option>
                            {(countries ?? []).map((country, i) => (
                              <option
                                key={`country_${i}`}
                                value={country.countryId}
                              >
                                {country.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="otherDetails.fromCountryId"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="col-4 ">
                        <div className="form_field">
                          <label htmlFor="fromCityId">State*</label>
                          <Field
                            as="select"
                            name="otherDetails.fromStateId"
                            id="fromStateId"
                            onChange={(e) =>
                              handleStateChange(
                                e.target.value,
                                setFieldValue,
                                "from"
                              )
                            }
                            value={values.otherDetails.fromStateId}
                          >
                            {" "}
                            <option value="">Select</option>
                            {(states ?? []).map((state, i) => (
                              <option key={`state_${i}`} value={state.stateId}>
                                {state.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="otherDetails.fromStateId"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="col-4 ">
                        <div className="form_field">
                          <label htmlFor="fromCityId">City*</label>
                          <Field
                            as="select"
                            name="otherDetails.fromCityId"
                            id="fromCityId"
                          >
                            <option value="">Select</option>
                            {(cities ?? []).map((city, i) => (
                              <option key={`city_${i}`} value={city.cityId}>
                                {city.name}
                              </option>
                            ))}
                          </Field>{" "}
                          <ErrorMessage
                            name="otherDetails.fromCityId"
                            component={TextError}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ff_gap"></div>
                    <h6 className="m14">
                      To
                      <div className="vg3"></div>
                    </h6>
                    <div className="row">
                      <div className="col-4 ">
                        <div className="form_field">
                          <label htmlFor="fromCityId">Country</label>
                          <Field
                            as="select"
                            name="otherDetails.toCountryId"
                            id="toCountryId"
                            onChange={(e) =>
                              handleCountryChange(
                                e.target.value,
                                setFieldValue,
                                "to"
                              )
                            }
                            value={values.otherDetails.toCountryId}
                          >
                            {" "}
                            <option value="">Select</option>
                            {(countries ?? []).map((country, i) => (
                              <option
                                key={`country_${i}`}
                                value={country.countryId}
                              >
                                {country.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="otherDetails.toCountryId"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="col-4 ">
                        <div className="form_field">
                          <label htmlFor="fromCityId">State*</label>
                          <Field
                            as="select"
                            name="toStateId"
                            id="otherDetails.toStateId"
                            onChange={(e) =>
                              handleStateChange(
                                e.target.value,
                                setFieldValue,
                                "to"
                              )
                            }
                            value={values.otherDetails.stateId}
                          >
                            {" "}
                            <option value="">Select</option>
                            {(otherStates ?? []).map((state, i) => (
                              <option key={`state_${i}`} value={state.stateId}>
                                {state.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="otherDetails.toStateId"
                            component={TextError}
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form_field">
                          <label htmlFor="toCityId">City*</label>
                          <Field
                            as="select"
                            name="otherDetails.toCityId"
                            id="toCityId"
                          >
                            <option value="">Select</option>
                            {(otherCities ?? []).map((city, i) => (
                              <option key={`city_${i}`} value={city.cityId}>
                                {city.name}
                              </option>
                            ))}
                          </Field>{" "}
                          <ErrorMessage
                            name="otherDetails.toCityId"
                            component={TextError}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="ff_gap"></div>
                  </div>
                  <div className="ff_gap"></div>
                  <div className="col-12">
                    <div className="form_field">
                      <div className="inline_checkbox_group">
                        <div className="checkbox_single">
                          <label>
                            <Field
                              type="checkbox"
                              name="otherDetails.isReturn"
                            />
                            Round Trip
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {values?.otherDetails?.isReturn && (
                <>
                  {" "}
                  <div className="ff_gap"></div>
                  <div className="col-12 pl0">
                    <div className="form_field">
                      <label htmlFor="returnDate">Return Date</label>
                      <Field
                        type="date"
                        name="otherDetails.returnDate"
                        id="returnDate"
                        placeholder=""
                      />{" "}
                      <ErrorMessage
                        name="otherDetails.returnDate"
                        component={TextError}
                      />
                    </div>
                  </div>
                </>
              )}
              {values.otherDetails?.bookingType === "hotel" && (
                <>
                  <div className="ff_gap"></div>
                  <div className="col-12 pl0">
                    <div className="form_field">
                      <label htmlFor="toDate">
                        City where you want to stay
                      </label>
                      <Field
                        type="text"
                        name="otherDetails.cityName"
                        id="cityName"
                        placeholder=""
                      />{" "}
                      <ErrorMessage
                        name="otherDetails.cityName"
                        component={TextError}
                      />
                    </div>
                  </div>{" "}
                  <div className="ff_gap"></div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form_field">
                        <label htmlFor="totalAdult">Total Adult</label>
                        <Field
                          type="number"
                          name="otherDetails.totalAdult"
                          id="totalAdult"
                          placeholder=""
                        />{" "}
                        <ErrorMessage
                          name="otherDetails.totalAdult"
                          component={TextError}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form_field">
                        <label htmlFor="totalChild">Total Child</label>
                        <Field
                          type="number"
                          name="otherDetails.totalChild"
                          id="totalChild"
                          placeholder=""
                        />{" "}
                        <ErrorMessage
                          name="otherDetails.totalChild"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="ff_gap"></div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form_field">
                        <label htmlFor="checkInDate">Check In Date</label>
                        <Field
                          type="date"
                          name="otherDetails.checkInDate"
                          id="checkInDate"
                          placeholder=""
                        />{" "}
                        <ErrorMessage
                          name="otherDetails.checkInDate"
                          component={TextError}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form_field">
                        <label htmlFor="checkOutDate">Check Out Date</label>
                        <Field
                          type="date"
                          name="otherDetails.checkOutDate"
                          id="checkOutDate"
                          placeholder=""
                        />{" "}
                        <ErrorMessage
                          name="otherDetails.checkOutDate"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="vg8"></div>
              {/* <div className="total r14 text-end">
                Total = <span className="sb16">₹ {getTotalAmount()}</span>
              </div> */}
              <div className="vg22"></div>
              <div className="col-12">
                <button
                  type="submit"
                  className="theme_btn"
                  disabled={submitting}
                >
                  {submitting ? <ActivityIndigator /> : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <div className="vg12"></div>
      </div>
    </div>
    </div>
    </div>
  );
};

const mapStateToProps = ({ form, common }) => {
  return { submitting: form.submitting, ...common };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId, flag) => dispatch(getStates(countryId, flag)),
    getCities: (cityId, flag) => dispatch(getCities(cityId, flag)),
    placeOrder: (payload, onAction) => dispatch(placeOrder(payload, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookNow);
