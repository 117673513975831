import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
import { APIs } from "../common/constants";
export const getOrders = (payload) => async (dispatch) => {
  try {
    if (payload?.pageNumber > 1) {
      dispatch({ type: "LOADING_MORE_ORDERS" });
    } else {
      dispatch({ type: "FETCH_ORDER_LIST" });
    }

    const { data } = await axios.post(`${APIs.GET_ALL_ORDERS}`, {
      ...payload,
    });

    if (data.status) {
      if (payload?.pageNumber > 1) {
        dispatch({
          type: "APPEND_PUBLIC_USER_SERVICES",
          payload: data.data || [],
          extra: {
            totalOrders: data?.totalOrders || 0,
            totalOtherOrders: data?.totalOtherOrders || 0,

            backendAssetUrl: data?.backendAssetUrl || "",
            hasMore: data?.currentPage !== data?.lastPage,
          },
        });
      } else {
        dispatch({
          type: "SET_ORDER_LIST",
          payload: data.data || [],
          extra: {
            totalOrders: data?.totalOrders || 0,
            totalOtherOrders: data?.totalOtherOrders || 0,
            backendAssetUrl: data?.backendAssetUrl || "",
            hasMore: data?.currentPage !== data?.lastPage,
          },
        });
      }
    } else {
      dispatch({
        type: "SET_ORDER_LIST",
        payload: [],
        extra: {
          totalOrders: 0,
          totalOtherOrders: 0,
          backendAssetUrl: "",
        },
      });
    }
  } catch (err) {
    dispatch({
      type: "SET_ORDER_FAILURE",
      payload: err,
    });

    showThrowError(err);
  }
};
export const deleteOrder = (_id, onAction) => async (dispatch) => {
  try {
    dispatch({ type: "FILTER_ORDER_LISTS", payload: _id });
    const { data } = await axios.delete(`${APIs.DELETE_ORDER}/${_id}`);

    if (data.status) {
      dispatch({ type: "FILTER_ORDER_LISTS", payload: _id });
      showSuccess(data.msg);
      if (onAction !== undefined) onAction();
    } else if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    showThrowError(err);
  }
};
export const changeOrderStatus =
  (payload, onAction = undefined) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(APIs.CHANGE_ORDER_STATUS, {
        ...payload,
      });

      if (data.status) {
        dispatch({ type: "FILTER_ORDER_LISTS", payload: payload.orderId });
        showSuccess(data.msg);
        if (onAction !== undefined) onAction();
      }
    } catch (err) {
      showThrowError(err);
    }
  };
