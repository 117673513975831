import React from "react";
import { Navigate, Route } from "react-router-dom";
import { getSession } from "../store/actions/authActions";
// const ProtectedRoute = ({ element, ...rest }) => {
//   let { Authorization } = getSession();
//   return Authorization ? (
//     <Route {...rest} element={element} />
//   ) : (
//     <Navigate to="/" replace />
//   );
// };
const ProtectedRoute = ({ children }) => {
  let { Authorization } = getSession();
  return Authorization ? <>{children}</> : <Navigate to="/" replace />;
};

export default ProtectedRoute;
