import React from "react";
import { getGender } from "../../../common/utils/utils";
import { Link } from "react-router-dom";
import { dateToFormatWithTime } from "../../../common/helpers/_helper";

const ProfileInActive = ({ profile, Authorization, user_id }) => {
  return (
    <div className="portal_page_spacing relative">
      {(!Authorization || profile._id !== user_id) && (
        <>
          <h6 className="m18">Contact to</h6>
          <div className="vg3"></div>
          <h6 className="m18">
            {getGender(profile?.gender)} {profile?.name || ""}
          </h6>
          <div className="vg22"></div>
          <div className="form_field">
            <label htmlFor="">Call Now</label>
            <Link
              to={`tel:${profile?.phoneNumber}`}
              className="theme_btn short_btn_2 btn_with_icon"
            >
              <span className="material-symbols-outlined btn_icon">call</span>
              {profile?.phoneNumber}
            </Link>
          </div>
        </>
      )}

      {Authorization && profile._id === user_id && !profile.isProfileActive && (
        <>
          <div className="vg22"></div>
          <div className="vg22"></div>
          <div className="expired_plan">
            <h6 className="m14 grey">
              Expired Plan :{" "}
              {dateToFormatWithTime(profile?.subscription?.paymentAt) || ""}
              {" - "}
              {dateToFormatWithTime(profile?.subscription?.expiresAt) || ""}
            </h6>
            <div className="vg3"></div>
            <h6 className="m16">Your plan has expired, Please Recharge Now</h6>
            <div className="vg12"></div>
            <Link to="/account/recharge" className="theme_btn">
              Recharge Now
            </Link>
            <div className="vg3"></div>
            <h6 className="r12 grey">
              Don't Worry. This box is showing only you.
            </h6>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileInActive;
