import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import { QRCodeSVG } from "qrcode.react";

const Qrcode = ({ profile }) => {
    // add class before min width 992  start
const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
// add class before min width 992  end
  let URL = `${window.origin}/${profile?.publicIdentifier}`;
  const onShare = async () => {
    const data = {
      title: "Business Baap",
      // text: this.text,
      url: URL,
    };
    // console.log(data);
    if (navigator.share) {
      navigator
        .share(data)
        .then(() => {
          console.log("Successful share! 🎉");
        })
        .catch((err) => console.error(err));
    } else {
      console.log("no share");
    }
  };

  return (
    <div className="portal_page_spacing">
       <div className={isMobile ? "" : "container"}>
      <PortalNavbar
        title="QR Code"
        backgroundColor="#D6EFFF"
        backArrow="arrow_back"
      />
    <div className="desktop_sect_width dtp">
    <div className="pg_qrocde">
        <h5 className="m18 text-center blue">QR Code</h5>
        <div className="vg22"></div>
        <div className="qrcode_img">
          <QRCodeSVG
            value={URL}
            size={220}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            // imageSettings={{
            //   src: "https://static.zpao.com/favicon.png",
            //   x: undefined,
            //   y: undefined,
            //   height: 24,
            //   width: 24,
            //   excavate: true,
            // }}
          />
        </div>
        <div className="vg22"></div>
        <div className="vg12"></div>
        <button onClick={onShare} className="theme_btn btn_with_icon desktop_btn">
          Share
          <span className="material-symbols-outlined btn_icon">share</span>
        </button>
      </div>
    </div>
      </div>
    </div>

  );
};

export default Qrcode;
