import React, { useState } from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { useDispatch, useSelector } from "react-redux";
import TextError from "../../../shared/TextError";
import {
  changeUserName,
  changeUserStatus,
} from "../../../store/actions/userActions";
import { Link, useNavigate } from "react-router-dom";
import { NAVIGATION_ROUTE } from "../../../store/common/constants";

const SetCustomUrl = ({ userDetails }) => {
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState("");
  const [hasError, setHasError] = useState(false);
  const [alreadyTaken, setAlreadyTaken] = useState(null);
  const { submitting } = useSelector((state) => state.form);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (name) {
      dispatch(
        changeUserName(
          {
            publicIdentifier: name,
            profileLastStep: "completed",
            profileStep: 8,
          },
          onAction,
          setAlreadyTaken
        )
      );
    } else {
      setHasError(true);
    }
  };

  const onAction = () => {
    navigate("/account");
  };

  usePageTitle("Website URL");
  const handleChange = (e) => {
    setName(e.target.value.toLowerCase());
    setHasError(false);
    setAlreadyTaken(null);
  };

  const handleToggle = () => {
    setToggle(!toggle);
    setName("");
  };

  const onCompleteStep = () => {
    dispatch(
      changeUserStatus(
        {
          type: "steps",
          profileLastStep: "completed",
          profileStep: 8,
        },
        navigate,
        NAVIGATION_ROUTE.USER_ACCOUNT
      )
    );
  };
  return (
    <div>
      {!toggle ? (
        <div>
          {" "}
          <h6 className="r14 grey">This is your website url:</h6>
          <div className="vg6"></div>
          <h6 className="r16">
            businessbaab.com/{userDetails?.publicIdentifier}
          </h6>
          <div className="vg22"></div>
          {/* <Link to={"/account"} className="theme_btn">
            Done
          </Link> */}
          <button type="button" onClick={onCompleteStep} className="theme_btn">
            Done
          </button>
          <div className="vg12"></div>
          <div className="text-center">
            <div className="grad_text gt_border" onClick={handleToggle}>
              I want to change my url
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="form_field custom_url">
            <label htmlFor="name">Create Custom Url</label>
            <div className="cu_inner relative">
              <span className="r14">businessbaab.com/</span>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="sohail"
                value={name}
                onChange={handleChange}
              />
              <div
                className="close_media"
                onClick={handleToggle}
                style={{
                  right: "-9px",
                }}
              >
                <span className="material-symbols-outlined">close</span>
              </div>
            </div>
            <div className="vg6"></div>
            {hasError && <TextError>Please enter a value</TextError>}
            {alreadyTaken && <TextError>{alreadyTaken}</TextError>}
            <div className="vg22"></div>
            {name && (
              <button
                type="button"
                className="theme_btn"
                onClick={handleSubmit}
                disabled={submitting}
              >
                {submitting ? <ActivityIndigator /> : "Done"}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SetCustomUrl;
