import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getSession } from "../store/actions/authActions";

const SiteHeader = () => {
  let { Authorization } = getSession();
  const location = useLocation(); // Get the current location
  return (
    <section className="header">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-xl">
              <div className="container-fluid">
                <Link className="navbar-brand logo" to="/">
                  <img
                    src="./assets/bbsite_images/logo_businessbaab.svg"
                    alt="Logo"
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarText"
                  aria-controls="navbarText"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${location.pathname === "/" ? "active" : ""
                          }`}
                        aria-current="page"
                        to="/"
                      >
                       Businessbaab
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link  className={`nav-link ${location.pathname === "/about-us" ? "active" : ""
                          }`} to="/about-us">
                        About Us
                      </Link>
                    </li>
                  
                    <li className="nav-item dropdown">
                      <a                      
                        className={`nav-link dropdown-toggle ${location.pathname === "/terms-conditions" || location.pathname === "/privacy-policy" || location.pathname === "/refund-policy" || location.pathname === "/categories"  ? "active" : ""
                          }`}
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Company Policies
                      </a>
                      <ul
                        class="dropdown-menu navbar_dropdown"
                        aria-labelledby="navbarDropdown"
                      >
                         <li >
                          <Link to="/categories"> Categories</Link>
                        </li>
                        <li className="mt-2">
                          <Link to="/terms-conditions">Terms & Conditions</Link>
                        </li>
                        <li className="mt-2">
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li className="mt-2">
                          <Link to="/refund-policy">Refund Policy</Link>
                        </li>
                       

                   
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${location.pathname === "/referrals" ? "active" : ""
                          }`} to="/referrals">
                        Refer & Earn
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className={`nav-link ${location.pathname === "businessbaab.com/blog/" ? "active" : ""
                          }`} href="https://blog.businessbaab.com/category/blog/">
                    Blog
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${location.pathname === "/frequently-ask-question" ? "active" : ""
                          }`} to="/frequently-ask-question">
                      FAQ
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className={`nav-link ${location.pathname === "/contact-us" ? "active" : ""
                          }`} to="/contact-us">
                        Contact Us
                      </Link>
                    </li>{" "}
                    <li className="nav-item menu_btn">
                      {Authorization ? (
                        <Link className="nav-link" to="/account" style={{
                          width: "fit-content"
                        }}>
                          My Account
                        </Link>
                      ) : (
                        <Link className="nav-link" to="/region" style={{
                          width: "fit-content"
                        }}>
                          Website Login
                        </Link>
                      )}
                    </li>
                    <li className="nav-item menu_btn">
                      <Link className="nav-link" to="https://console.businessbaab.com" style={{
                        width: "fit-content"
                      }}>
                        Promoter Login
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SiteHeader;
