import React from "react";

const SearchFilter = ({
  search,
  setSearch,
  placeholder,
  showLabel = false,
}) => {
  return (
    <div className="form_field">
      {showLabel && <label htmlFor="payment">Search</label>}
      <input
        type="search"
        placeholder={placeholder ? placeholder : "Search"}
        value={search ? search : ""}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
};

export default SearchFilter;
