import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { COOKIE_EXPIRED } from "../../store/common/constants";
import { useCookies } from "react-cookie";

const useRefferalCode = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const useQuery = () => {
    return queryString.parse(useLocation().search);
  };
  const query = useQuery();

  useEffect(() => {
    if (query.referralCode) {
      // console.log(query.referralCode);
      setCookie("referralCode", query.referralCode, {
        path: "/",
        maxAge: COOKIE_EXPIRED,
      });
    }
  }, [query]);

  return null;
};

export default useRefferalCode;
