import React from "react";

// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import useScrollToTop from "../common/hooks/useScrollToTop";
import LiveChatScript from "./LiveChatScript";
import GoogleMetaTag from "../component/GoogleMetaTag";

const SiteTermsAndCondition = () => {
  useScrollToTop();
  return (
    <div className="website_pages terms_conditon">
      <GoogleMetaTag title={"Terms & Condition"} content={"BusinessBaab website terms and condition"} />
      <SiteHeader />
      <section className="page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page_content">
                <div className="page_title">
                  <h1>Terms & Conditions</h1>
                </div>
                <p>
                  Welcome to BusinessBaab! Before using our platform, please
                  read these Terms and Conditions carefully. By accessing or
                  using BusinessBaab, you agree to be bound by these terms,
                  which govern your use of our services.
                </p>
                <h3>1. Acceptance of Terms:</h3>
                <p>
                  By accessing or using BusinessBaab, you agree to comply with
                  these Terms and Conditions, as well as any additional terms
                  and policies referenced herein.
                </p>
                <p>
                  We will process a refund for the remaining unused days of your
                  subscription period within 1-7 business days after your
                  account closure.
                </p>
                <h3>2. Use of Services:</h3>
                <p>
                  You agree to use BusinessBaab solely for lawful purposes and
                  in accordance with these Terms and Conditions. You are
                  responsible for all activities conducted under your account.
                </p>
                <h3>3. Account Registration:</h3>
                <p>
                  To access certain features of BusinessBaab, you may be
                  required to create an account. You agree to provide accurate
                  and complete information during the registration process and
                  to keep your account information up to date.
                </p>
                <h3>4. Payment and Subscription:</h3>
                <p>
                  BusinessBaab offers subscription-based services. By
                  subscribing to our services, you agree to pay the applicable
                  fees and charges. Payment terms and subscription details are
                  provided during the checkout process.
                </p>
                <h3>5. Intellectual Property Rights:</h3>
                <p>
                  All content and materials available on BusinessBaab, including
                  but not limited to text, graphics, logos, images, and
                  software, are the property of BusinessBaab or its licensors
                  and are protected by copyright and other intellectual property
                  laws.
                </p>
                <h3>6. User Content:</h3>
                <p>
                  You retain ownership of any content you create or upload to
                  BusinessBaab. By posting or submitting content, you grant
                  BusinessBaab a worldwide, non-exclusive, royalty-free license
                  to use, reproduce, modify, adapt, publish, translate,
                  distribute, and display such content.
                </p>
                <h3>7. Limitation of Liability:</h3>
                <p>
                  BusinessBaab and its affiliates, directors, officers,
                  employees, agents, and suppliers shall not be liable for any
                  direct, indirect, incidental, special, consequential, or
                  exemplary damages, including but not limited to damages for
                  loss of profits, goodwill, use, data, or other intangible
                  losses.
                </p>
                <h3>8. Indemnification:</h3>
                <p>
                  You agree to indemnify and hold harmless BusinessBaab and its
                  affiliates, directors, officers, employees, agents, and
                  suppliers from any claims, damages, losses, liabilities,
                  costs, and expenses (including attorney fees) arising out of
                  or related to your use of BusinessBaab or any violation of
                  these Terms and Conditions.
                </p>
                <h3>9. Modifications to Terms:</h3>
                <p>
                  BusinessBaab reserves the right to modify these Terms and
                  Conditions at any time. Any changes will be effective
                  immediately upon posting. Continued use of BusinessBaab after
                  any such changes constitutes your acceptance of the revised
                  Terms and Conditions.
                </p>
                <h3>10. Governing Law:</h3>
                <p>
                  These Terms and Conditions shall be governed by and construed
                  in accordance with the laws of Ujjain (M.P.) INDIA, without
                  regard to its conflict of law principles.
                </p>
                <h3>11. Contact Us:</h3>
                <p>
                  If you have any questions or concerns about these Terms and
                  Conditions, please contact us at info@businessbaab.com
                </p>
                <p>
                  By using BusinessBaab, you agree to abide by these Terms and
                  Conditions. If you do not agree to these terms, please do not
                  use our platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SiteFooter />
      {/* <LiveChatScript/> */}
    </div>
  );
};

export default SiteTermsAndCondition;
