import React, { useEffect } from "react";
import Navbar from "./Navbar";
import WebsiteFor from "./ProfileProcess/WebsiteFor";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PersonalInfo from "./ProfileProcess/PersonalInfo";
import BussinessInfo from "./ProfileProcess/Businessinfo";
import ProductInfo from "./ProfileProcess/ProductInfo";
import PaymentInfo from "./ProfileProcess/PaymentInfo";
import Dashboard from "./Dashboard";
import ChangeTheme from "./Settings/ChangeTheme";
import { connect } from "react-redux";
import { getUserInfo } from "../../store/actions/userActions";
import ActivityLoader from "../../shared/ActivityLoader/ActivityLoader";
import Orders from "./Orders/Orders";
import Filter from "../../component/Filter";
import Analytics from "./Analytics";
import Congradulation from "./ProfileProcess/Congradulation";
import ChoosePlan from "./ProfileProcess/Payment/ChoosePlan";
import PurchasePlan from "./ProfileProcess/Payment/PurchasePlan";
import NoplanAlert from "./ProfileProcess/Payment/NoplanAlert";
import Settings from "./Settings/Settings";
import { geAuthCategory } from "../../common/utils/utils";
import { LABELS } from "../../common/label";
import MyProducts from "./Products/MyProducts";
import AccountFaq from "./pages/AccountFaq";
import Notification from "./pages/Notification";
import TermAndCondition from "./pages/TermAndCondition";
import PrivayPolicy from "./pages/PrivayPolicy";
import RefundAndPolicy from "./pages/RefundAndPolicy";
import AccountContactUs from "./pages/AccountContactUs";
import Business from "./pages/Business";
import AccountAboutUs from "./pages/AccountAboutUs";
import HelpAndSupport from "./pages/HelpAndSupport";
import SetCustomUrl from "./ProfileProcess/SetCustomUrl";
import DownloadQR from "./DownloadQR";
import RechargeNow from "./ProfileProcess/Payment/RechargeNow";
import PaymentSuccess from "./ProfileProcess/Payment/PaymentSuccess";
import OrderDetailCard from "./Orders/OrderDetailCard";
import { CATEGORY_LISTS, PROFILE_LAST_STEPS } from "../../common/constants";
import ChangeTemplate from "./Settings/ChangeTemplate";
import VehicleInfo from "./ProfileProcess/Travel/VehicleInfo";
import PackageInfo from "./ProfileProcess/Travel/PackageInfo";
import TravelServiceInfo from "./ProfileProcess/Travel/TravelServiceInfo";
import MyVehicles from "./Travel/MyVehicles";

const AccountLayout = ({ getUserInfo, loader, userDetails }) => {
  let categoryName = geAuthCategory();
  let location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    if (userDetails && userDetails.profileLastStep) {
      let step = userDetails.profileLastStep;
      // console.log("step---", step);

      if (step !== PROFILE_LAST_STEPS.COMPLTED) {
        if (step === PROFILE_LAST_STEPS.OTP) {
          navigate("/account/choose-plan");
        } else if (step === PROFILE_LAST_STEPS.CHOOSE_PLAN) {
          navigate("/account/select-account-type");
        } else if (step === PROFILE_LAST_STEPS.CHOOSE_CATEGORY) {
          navigate("/account/personal-info");
        } else if (step === PROFILE_LAST_STEPS.SAVE_PERSONAL_INFO) {
          navigate("/account/business-info");
        } else if (step === PROFILE_LAST_STEPS.SAVE_BUSINESS_INFO) {
          if (CATEGORY_LISTS.TRAVEL === userDetails?.categoryName) {
            navigate("/account/vehicle-info");
          } else {
            navigate("/account/service-info");
          }
        } else if (
          step === PROFILE_LAST_STEPS.SAVE_SERVICE_INFO ||
          step === PROFILE_LAST_STEPS.SAVE_VEHICLE_INFO
        ) {
          navigate("/account/payment-info");
        } else if (step === PROFILE_LAST_STEPS.SAVE_PAYMENT_INFO) {
          navigate("/account/set-custom-url");
        } else if (step === PROFILE_LAST_STEPS.SAVE_CUSTOM_URL) {
          navigate("/account");
        } else {
          navigate("/account");
        }
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails && userDetails.subscription) {
      if (
        !userDetails?.subscription?.isFreeTrial &&
        !userDetails?.subscription?.isActive &&
        !userDetails.isGrantUser
      ) {
        navigate("/account/choose-plan");
        console.log("inside step---", userDetails.subscription);
      }
    }
  }, [userDetails]);

  const getBgColor = () => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 0) {
      let lastSegment = pathSegments[pathSegments.length - 1];
      if (
        [
          "about-us",
          "contact-us",
          "business",
          "faq",
          "terms-conditions",
          "privacy-policy",
          "refund-policy",
          "notification",
          "help-supports",
          "choose-plan",
          "select-plan",
          "congratulation",
          "select-account-type",
          "recharge",
          "recharge-success",
        ].includes(lastSegment)
      ) {
        return "white";
      } else if (["no-plan"].includes(lastSegment)) {
        return "";
      }
    }
    return "#D6EFFF";
  };
  const getBackArrow = () => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 0) {
      let lastSegment = pathSegments[pathSegments.length - 1];
      if (
        [
          "no-plan",
          "choose-plan",
          "select-plan",
          "congratulation",
          "select-account-type",
          "recharge",
          "recharge-success",
        ].includes(lastSegment)
      ) {
        return "";
      }
    }
    return "arrow_back";
  };

  // const getSkipPath = () => {
  //   const pathSegments = location.pathname.split("/");
  //   if (pathSegments.length > 0) {
  //     let lastSegment = pathSegments[pathSegments.length - 1];
  //     if (["business-info"].includes(lastSegment)) {
  //       return "/account/service-info";
  //     } else if (["service-info"].includes(lastSegment)) {
  //       return "/account/payment-info";
  //     } else if (["payment-info"].includes(lastSegment)) {
  //       return "/account/set-custom-url";
  //     }
  //   }
  //   return null;
  // };

  const getSkipPath = () => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 0) {
      let lastSegment = pathSegments[pathSegments.length - 1];
      if (["service-info"].includes(lastSegment)) {
        return "/account/payment-info";
      } else if (["payment-info"].includes(lastSegment)) {
        return "/account/set-custom-url";
      }
    }
    return null;
  };

  const getMenuIcon = () => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 0) {
      let lastSegment = pathSegments[pathSegments.length - 1];
      if (
        [
          "no-plan",
          "choose-plan",
          "select-plan",
          "congratulation",
          "select-account-type",
          "personal-info",
          "business-info",
          "service-info",
          "vehicle-info",
          "payment-info",
          "recharge",
          "recharge-success",
        ].includes(lastSegment)
      ) {
        return false;
      }
    }
    return true;
  };
// console.log("userdetail", userDetails);
  return (
    <div className={`vendor_page_spacing ${categoryName}`}>
      <Navbar
        backgroundColor={getBgColor()}
        backArrow={getBackArrow()}
        categoryName={categoryName}
        userDetails={userDetails}
        skipPath={getSkipPath()}
        isMenuIcon={getMenuIcon()}
      />{" "}
      {loader ? <ActivityLoader /> : null}
      <Routes>
        {" "}
        <Route
          path="/"
          element={
            <Dashboard userDetails={userDetails} categoryName={categoryName} />
          }
        />
        <Route path="/no-plan" element={<NoplanAlert />}></Route>
        <Route path="/choose-plan" element={<PurchasePlan />}></Route>
        <Route path="/select-plan" element={<ChoosePlan />}></Route>
        <Route path="select-account-type" element={<WebsiteFor />}></Route>
        <Route path="/recharge" element={<RechargeNow />}></Route>
        <Route
          path="recharge-success"
          element={<PaymentSuccess userDetails={userDetails} />}
        ></Route>
        <Route
          path="personal-info"
          element={<PersonalInfo userDetails={userDetails} />}
        ></Route>
        <Route
          path="business-info"
          element={
            <BussinessInfo
              userDetails={userDetails}
              categoryName={categoryName}
            />
          }
        ></Route>
        <Route
          path="service-info"
          element={
            <ProductInfo
              heading={LABELS.ADD_SERVICE[categoryName]}
              categoryName={categoryName}
            />
          }
        ></Route>
        <Route
          path="vehicle-info"
          element={
            <VehicleInfo
              heading={LABELS.ADD_SERVICE[categoryName]}
              categoryName={categoryName}
            />
          }
        ></Route>{" "}
        <Route
          path="payment-info"
          element={<PaymentInfo paymentInfo={userDetails?.paymentInfo ?? []} />}
        ></Route>
        <Route path="congratulation" element={<Congradulation />}></Route>
        {/* edit routes */}
        <Route
          path="edit-personal-info"
          element={
            <PersonalInfo
              heading="Edit Personal Info"
              userDetails={userDetails}
            />
          }
        ></Route>
        <Route
          path="edit-business-info"
          element={
            <BussinessInfo
              heading="Edit Business Info"
              userDetails={userDetails}
              categoryName={categoryName}
            />
          }
        ></Route>
        <Route
          path="edit-service-info"
          element={
            <ProductInfo
              heading={LABELS.EDIT_SERVICE[categoryName]}
              categoryName={categoryName}
              editMode={true}
            />
          }
        ></Route>{" "}
        <Route
          path="edit-vehicle-info"
          element={
            <VehicleInfo
              heading={LABELS.EDIT_SERVICE[categoryName]}
              categoryName={categoryName}
              editMode={true}
            />
          }
        ></Route>{" "}
        <Route
          path="edit-package-info"
          element={
            <PackageInfo
              heading={LABELS.EDIT_SERVICE[categoryName]}
              categoryName={categoryName}
              editMode={true}
            />
          }
        ></Route>{" "}
        <Route
          path="edit-travel-service-info"
          element={
            <TravelServiceInfo
              heading={LABELS.EDIT_SERVICE[categoryName]}
              categoryName={categoryName}
              editMode={true}
            />
          }
        ></Route>
        <Route
          path="edit-payment-info"
          element={
            <PaymentInfo
              heading="Edit Payment Info"
              paymentInfo={userDetails?.paymentInfo ?? []}
            />
          }
        ></Route>
        <Route
          path="/settings"
          element={<Settings userDetails={userDetails} />}
        ></Route>
        <Route
          path="/change-theme"
          element={
            <ChangeTheme
              currentTheme={userDetails?.theme?.colorClass}
              currentTemplate={userDetails?.theme?.template}
            />
          }
        ></Route>
        <Route
          path="/change-template"
          element={
            <ChangeTemplate
              currentTheme={userDetails?.theme?.colorClass}
              currentTemplate={userDetails?.theme?.template}
            />
          }
        ></Route>
        <Route
          path="/set-custom-url"
          element={<SetCustomUrl userDetails={userDetails} />}
        ></Route>
        {/* orders */}
        <Route
          path="/analytics"
          element={<Analytics userDetails={userDetails} />}
        ></Route>{" "}
        <Route
          path="/my-vehicles"
          element={
            <MyVehicles
              userDetails={userDetails}
              heading={LABELS.MY_SERVICE_TITLE[categoryName] || ""}
            />
          }
        ></Route>
        <Route
          path="/my-products"
          element={
            <MyProducts
              userDetails={userDetails}
              heading={LABELS.MY_SERVICE_TITLE[categoryName] || ""}
            />
          }
        ></Route>
        <Route
          path="/orders/:orderType?"
          element={<Orders userDetails={userDetails} />}
        ></Route>
        <Route path="/filter" element={<Filter />}></Route>
        {/* static pages */}
        <Route path="/faq" element={<AccountFaq />}></Route>
        <Route path="/notification" element={<Notification />}></Route>
        <Route
          path="/contact-us"
          element={<AccountContactUs profile={userDetails} />}
        ></Route>
        <Route path="/about-us" element={<AccountAboutUs />}></Route>
        <Route path="/business" element={<Business />}></Route>
        <Route path="/help-supports" element={<HelpAndSupport />}></Route>
        <Route path="/terms-conditions" element={<TermAndCondition />}></Route>
        <Route path="/privacy-policy" element={<PrivayPolicy />}></Route>
        <Route path="/refund-policy" element={<RefundAndPolicy />}></Route>
        <Route
          path="/qr-code"
          element={<DownloadQR userDetails={userDetails} />}
        ></Route>
      </Routes>
    </div>
  );
};
const mapStateToProps = ({ form, account, user }) => {
  return {
    loader: account.loader,
    userDetails: user?.user || {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserInfo: () => dispatch(getUserInfo()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountLayout);
