import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { geAuthCategory } from "../../../common/utils/utils";
import { getCategory } from "../../../common/utils/utils";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import { connect, useSelector } from "react-redux";
import { placeOrderSchema } from "../../../common/validations/formValidation";
import {
  getCities,
  getCountries,
  getStates,
} from "../../../store/actions/commonActions";
import { placeOrder } from "../../../store/actions/publicActions";
import TextError from "../../../shared/TextError";
import ActivityIndigator from "../../../shared/ActivityIndigator";

const DeliveryAddress = ({
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
  submitting,
  placeOrder,
  profile,
}) => {
  let { username } = useParams();
  const navigate = useNavigate();
  let { cartItemCount, items } = useSelector((state) => state.shoppingCart);
  let authCategoryName = geAuthCategory();
  let categoryName = getCategory();
  useEffect(() => {
    getCountries();
    getStates(101);
  }, [getCountries, getStates]);
  // add class before min width 992  start
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // add class before min width 992  end

  const getTotalAmount = () => {
    let total = items.reduce((acc, curr) => acc + curr.price * curr.qty, 0);
    return total;
  };

  const initialValues = {
    name: "",
    phoneNumber: "",
    countryId: "101",
    stateId: "",
    cityId: "",
    fullAddress: "",
  };

  const handleSubmit = (values) => {
    if (items.length > 0) {
      values.items = items.map((item) => {
        return {
          serviceId: item?._id,
          price: item.price,
          quantity: item.qty,
        };
      });
      values.userId = sessionStorage.getItem("userId") || "";
      values.categoryName = sessionStorage.getItem("categoryName") || "";
      values.category = sessionStorage.getItem("category") || "";
      values.serviceType = sessionStorage.getItem("serviceType") || "";
      placeOrder(values, onActionBack);
    }
  };
  const orderNow = () => {
    let message = "Check out New Order Recieved:\n\n";
    message += items
      .map(
        (item) =>
          `ID:${item.serviceNo}, ${item.title}: ₹${item.price} x ${item.qty}\n`
      )
      .join("\n");

    let total = items.reduce((acc, curr) => acc + curr.price * curr.qty, 0);
    message += `\nTotal Amount: ₹${total}`;
    window.location.href = `https://wa.me/${profile?.personalInfo?.whatsapNumber
      }?text=${encodeURIComponent(message)} `;
  };

  const onActionBack = () => {
    // navigate(`/${username}`);
    // open whatsap link as well
    orderNow(items);
  };
  const handleCountryChange = (value, setFieldValue) => {
    if (value) {
      getStates(value);
      setFieldValue("countryId", value);
      setFieldValue("stateId", "");
      setFieldValue("cityId", "");
    }
  };

  const handleStateChange = (value, setFieldValue) => {
    if (value) {
      getCities(value);
      setFieldValue("stateId", value);
      setFieldValue("cityId", "");
    }
  };
  console.log(categoryName);
  return (
    <div className="portal_page_spacing">
      <div className={isMobile ? "" : "container"}>
        <PortalNavbar
          title="Delivery Address"
          backgroundColor="#D6EFFF"
          backArrow="arrow_back"
        />
       <div className="desktop_sect_width">
       <div className="pg_deliveryaddress">
          <Formik
            initialValues={initialValues}
            validationSchema={placeOrderSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-6 ">
                    <div className="form_field">
                      <label htmlFor="username">Name</label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                      />{" "}
                      <ErrorMessage name="name" component={TextError} />
                    </div>
                  </div>
                  <div className="col-6 pl0">
                    <div className="form_field">
                      <label htmlFor="mobileNumber">Mobile Number</label>
                      <Field
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="+91 123456789"
                      />{" "}
                      <ErrorMessage name="phoneNumber" component={TextError} />
                    </div>
                  </div>
                </div>
                <div className="ff_gap"></div>
                <div className="row">
                  <div className="col-4">
                    <div className="form_field">
                      <label htmlFor="countryId">Country</label>
                      <Field
                        as="select"
                        name="countryId"
                        id="countryId"
                        onChange={(e) =>
                          handleCountryChange(e.target.value, setFieldValue)
                        }
                        value={values.countryId}
                      >
                        {" "}
                        <option value="">Select</option>
                        {(countries ?? []).map((country, i) => (
                          <option key={`country_${i}`} value={country.countryId}>
                            {country.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="countryId" component={TextError} />
                    </div>
                  </div>
                  <div className="col-4 pl0">
                    <div className="form_field">
                      <label htmlFor="stateId">State*</label>
                      <Field
                        as="select"
                        name="stateId"
                        id="stateId"
                        onChange={(e) =>
                          handleStateChange(e.target.value, setFieldValue)
                        }
                        value={values.stateId}
                      >
                        {" "}
                        <option value="">Select</option>
                        {(states ?? []).map((state, i) => (
                          <option key={`state_${i}`} value={state.stateId}>
                            {state.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="stateId" component={TextError} />
                    </div>
                  </div>
                  <div className="col-4 pl0">
                    <div className="form_field">
                      <label htmlFor="cityId">City*</label>
                      <Field as="select" name="cityId" id="cityId">
                        <option value="">Select</option>
                        {(cities ?? []).map((city, i) => (
                          <option key={`city_${i}`} value={city.cityId}>
                            {city.name}
                          </option>
                        ))}
                      </Field>{" "}
                      <ErrorMessage name="cityId" component={TextError} />
                    </div>
                  </div>
                </div>
                <div className="ff_gap"></div>
                <div className="col-12">
                  <div className="form_field">
                    <label htmlFor="fullAddress">Full Address* </label>
                    <Field
                      as="textarea"
                      name="fullAddress"
                      id="fullAddress"
                      placeholder="Enter address"
                    />
                    <ErrorMessage name="fullAddress" component={TextError} />
                  </div>
                </div>
                <div className={categoryName === "Travels" ? "d-none" : ""}>
                  <div className="vg8"></div>
                  <div className="total r14 text-end">
                    Total = <span className="sb16">₹ {getTotalAmount()}</span>
                  </div>
                </div>

                <div className="vg22"></div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="theme_btn"
                    disabled={submitting}
                  >
                    {submitting ? <ActivityIndigator /> : " Place Order"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          <div className="vg12"></div>
        </div>
       </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ form, common }) => {
  return { submitting: form.submitting, ...common };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
    placeOrder: (payload, onAction) => dispatch(placeOrder(payload, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress);
