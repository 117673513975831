import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import AddToCartButton from "../../../component/AddToCartButton";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import {
  getPublicServiceDetail,
  resetSinglServiceDetails,
  updateShoppingCart,
} from "../../../store/actions/publicActions";
import { connect, useDispatch } from "react-redux";
import ASSETS from "../../../common/images";
import { LABELS } from "../../../common/label";
import useScrollToTop from "../../../common/hooks/useScrollToTop";
import { onShareHelper } from "../../../common/helpers/_share";

const ProductSingleDetail = ({
  getPublicServiceDetail,
  loading,
  singleService,
  categoryName,
  resetSinglServiceDetails,
}) => {
  const [qty, setQty] = useState(1);

  let { _id, username } = useParams();
  const navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    getPublicServiceDetail(_id);
    return () => resetSinglServiceDetails();
  }, [_id, getPublicServiceDetail]);

  let total = qty * singleService?.price;

  const handleChange = (e) => {
    let enteredValue = Number(e.target.value);
    // if (enteredValue <= singleService?.quantity) {
    setQty(enteredValue);
    // }
  };

  // add class before min width 992  start
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // add class before min width 992  end

  const handleContinue = () => {
    let userId = sessionStorage.getItem("userId") || undefined;
    if (userId === undefined) return;
    let cart = JSON.parse(sessionStorage.getItem(userId)) || [];
    let updatedCart = [...cart];

    // Check if the item already exists in the cart
    const existingItemIndex = updatedCart.findIndex(
      (cartItem) => cartItem._id === singleService._id
    );
    if (existingItemIndex !== -1) {
      // If item exists, update its quantity
      updatedCart[existingItemIndex].qty = qty;
    } else {
      // If item doesn't exist, add it to the cart
      updatedCart.push({ ...singleService, qty: qty });
    }

    sessionStorage.setItem(userId, JSON.stringify(updatedCart));
    // disptach action
    dispatch(updateShoppingCart());
    navigate(`/${username}/checkout`);
  };
  useScrollToTop();

  const onShare = async () => {
    onShareHelper(`${window.location}`);
  };
  //   modal
  const [showMyProduct, setShowMyProduct] = useState(false);

  const handleCloseMyProductModal = () => setShowMyProduct(false);
  const handleShowMyProductModal = () => setShowMyProduct(true);
  //   modal
  return (
    <div className={isMobile ? "" : "container"}>
      <div className="portal_page_spacing">

        {loading ? <ActivityLoader /> : null}
        <PortalNavbar
          title={LABELS.SINGLE_SERVICE_HEADER[categoryName] || ""}
          backgroundColor="#D6EFFF"
          backArrow="arrow_back"
        />
        <div className="desktop_sect_width dtp">
          <div className="psdetail">
            <div className="product_short_info relative s">
              <div className="pic_price">
                <div className="pic">
                  <img
                    src={
                      singleService?.thumbnail
                        ? singleService?.thumbnail
                        : ASSETS.dummyproductimg
                    }
                    alt={singleService?.title}
                    onClick={handleShowMyProductModal}
                  />
                  <div className="vg6"></div>
                  <div className={isMobile ? "text-center" : ""}>
                    <div className="grad_text  gt_border"
                      onClick={handleShowMyProductModal}>
                      View
                    </div>
                  </div>
                  {/* modal for product image  */}
                  <Modal
                    show={showMyProduct}
                    onHide={handleCloseMyProductModal}
                    className="my_modal modal_top"
                  >
                    <Modal.Header className="d-flex">
                      {/* <Modal.Title className="m18">{singleService?.thumbnail ? (
              ""
            ) : (
              "Alert"
            )}</Modal.Title> */}
                      <span
                        class="material-symbols-outlined modal_close red"
                        onClick={handleCloseMyProductModal}
                      >
                        close
                      </span>
                    </Modal.Header>
                    <div className="vg22"></div>
                    <Modal.Body>
                      <h6>
                        {singleService?.thumbnail ? (
                          <>
                            {/* {singleService.title} product image */}
                          </>
                        ) : (
                          "Image is not available :("
                        )}
                      </h6>
                      {singleService?.thumbnail &&
                        <div className="modal_product_img">
                          <img
                            src={
                              singleService?.thumbnail
                            }
                            alt={singleService?.title}
                            className="w-100"
                          />
                        </div>
                      }

                    </Modal.Body>
                  </Modal>
                </div>
                <div className="price">
                  <h5 className="m18 mb-2">{singleService?.title || ""}</h5>
                  <h6 className="sb22 purple">₹{singleService?.price || 0} </h6>
                  <div className="vg4"></div>
                  <div className="product_id">
                    ID : #{singleService?.serviceNo || 0}
                  </div>
                </div>
              </div>
              {/* <div className="vg8"></div> */}
              {/* <div className="product_id">
      ID : #{singleService?.serviceNo || 0}
    </div> */}
              <div className="vg12"></div>
              <p className="r14 textarea_text">{singleService?.description || ""}</p>
              <div className="vg22"></div>

              <div className="row">
                <div className="col-12">
                  <div className="form_field">
                    {singleService?.quantity === null ||
                      singleService?.quantity > 0 ? (
                      <>
                        {" "}
                        <label htmlFor="quantity">Quantity</label>
                        <div className="row align-items-center">
                          <div className="col-5">
                            <input
                              type="number"
                              name="quantity"
                              id="quantity"
                              placeholder=""
                              value={qty}
                              min={0}
                              // max={singleService?.quantity}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-7 pl0">
                            <div className="total r14">
                              Total = <span className="sb16">₹ {total}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <label htmlFor="quantity">Out of stock</label>
                    )}
                  </div>

                </div>

                {(singleService?.quantity === null ||
                  singleService?.quantity > 0) && (
                    <>
                      <div className="vg22"></div>
                      <div className="col-6">
                        <AddToCartButton
                          item={singleService}
                          qty={qty}
                          setQty={setQty}
                        />
                      </div>
                      <div className="col-6 pl0">
                        <button
                          type="button"
                          className="theme_btn btn_border btn_with_icon"
                          onClick={onShare}
                          style={{
                            gap: "10px"
                          }}

                        >
                          <span class="material-symbols-outlined">
                            share
                          </span>
                          Share
                        </button>
                      </div>
                      <div className="vg12"></div>
                      <div className="col-12">
                        <button
                          type="button"
                          onClick={handleContinue}
                          className="theme_btn desktop_btn"
                        >
                          Buy Now
                        </button>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

const mapStateToProps = ({ profile: { singleService, loading } }) => {
  return {
    loading,
    singleService,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPublicServiceDetail: (_id) => dispatch(getPublicServiceDetail(_id)),

    resetSinglServiceDetails: () => dispatch(resetSinglServiceDetails()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSingleDetail);
