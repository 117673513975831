const initialState = {
  title: "",
  loader: false,
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PAGE_TITLE":
      return {
        ...state,
        title: action.payload,
      };
    case "SHOW_ACCOUNT_LOADER":
      return {
        ...state,
        loader: true,
      };
    case "HIDE_ACCOUNT_LOADER":
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};

export default accountReducer;
