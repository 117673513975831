import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// vendor navbar
import VendorNavbar from "../../../component/VendorNavbar";
import { connect } from "react-redux";
import { getCategoryList } from "../../../store/actions/categoryAction";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import { changeUserStatus } from "../../../store/actions/userActions";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { NAVIGATION_ROUTE } from "../../../store/common/constants";

const WebsiteFor = ({
  categories,
  getCategoryList,
  loading,
  changeUserStatus,
  submitting,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getCategoryList();
  }, [getCategoryList]);

  const imageMapping = {
    "All Services": "../assets/bbsite_images/cat_img_2.svg",
    Personal: "../assets/bbsite_images/cat_img_12.svg",
    "Online Store": "../assets/bbsite_images/cat_img_1.svg",
    "Retail Shop": "../assets/bbsite_images/cat_img_5.svg",
    Restaurant: "../assets/bbsite_images/cat_img_3.svg",
    Doctor: "../assets/bbsite_images/cat_img_4.svg",
    Hotels: "../assets/bbsite_images/cat_img_13.svg",
    "School/College": "../assets/bbsite_images/cat_img_6.svg",
    Travels: "../assets/bbsite_images/cat_img_7.svg",
    Teacher: "../assets/bbsite_images/cat_img_9.svg",
    Farming: "../assets/bbsite_images/cat_img_8.svg",
    Politician: "../assets/bbsite_images/cat_img_10.svg",
  };

  const saveAndNext = () => {
    if (selectedCategory) {
      changeUserStatus(
        {
          type: "category",
          category: selectedCategory?._id,
          categoryName: selectedCategory?.name,
          profileLastStep: "choose-category",
          profileStep: 2,
        },
        navigate,
        NAVIGATION_ROUTE.PERSONAL_INFO
      );
    }
  };

  return (
    <div className="create_protal">
      {loading ? <ActivityLoader /> : null}
      <div className="cp_inner">
        <div className="logo">
          <img src="../assets/img/logo_businessbaab.svg" alt="" />
        </div>
        <div className="vg22"></div>
        <h2 className="sb22 text-center">Choose Business Category</h2>
        {/* <div className="common mt-2">You can change category anytime</div> */}
        <div className="vg22"></div>
        <div className="vg22"></div>
        <div className="small_card">
          {categories &&
            categories.length > 0 &&
            categories.map((category, index) => (
              <div key={category._id} className={index <= 3 ? "" : "cs_cat"}>
                <div
                  className={`sc_single ${
                    selectedCategory?._id === category._id
                      ? "select_bussiness"
                      : ""
                  }`}
                  onClick={() => {
                    if (index <= 3) setSelectedCategory(category);
                  }}
                >
                  <div className="service_img">
                    <img
                      src={imageMapping[category?.name]}
                      alt={category?.name}
                    />
                  </div>

                  <h6 className="sb14">{category?.name}</h6>
                  <div className="csoon">Coming Soon</div>
                </div>
              </div>
            ))}
        </div>

        <div className="vg22"></div>
        <div className="vg22"></div>
        {selectedCategory !== null && (
          <button
            onClick={saveAndNext}
            className="theme_btn"
            disabled={submitting}
          >
            {submitting ? <ActivityIndigator /> : " Save & Next "}
          </button>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = ({
  form,
  category: { lists: categories, loading },
}) => {
  return {
    submitting: form.submitting,
    categories,
    loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: () => dispatch(getCategoryList()),
    changeUserStatus: (payload, navigate, path) =>
      dispatch(changeUserStatus(payload, navigate, path)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebsiteFor);
