import { useEffect } from "react";

function useScrollToTop() {
  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    window.scroll(0, 0);
  }, []);
}

export default useScrollToTop;
