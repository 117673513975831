export const STATIC_BUSINESS_BIO = [
  "Welcome to YOUR_BUSINESS_NAME, where excellence is our standard. Our SELECTED_SERVICES are crafted to fulfill your needs, ensuring exceptional results/unmatched value. Trust us to be your partner in SELECTED_SERVICE excellence.",
  "Step into a realm of possibilities with YOUR_BUSINESS_NAME. Our SELECTED_SERVICES are designed to transcend expectations, offering not just solutions but transformations. Rooted in quality, our offerings guarantee exceptional results. Experience the difference with us.",
  "Explore a world of difference with YOUR_BUSINESS_NAME. Our SELECTED_SERVICES are more than just solutions - they're experiences crafted to elevate. With an unwavering commitment to quality. Trust us to be your gateway to SELECTED_SERVICE excellence.",
  "Welcome to YOUR_BUSINESS_NAME, where we don't just offer SELECTED_SERVICES, we offer revolutions. Our solutions are crafted to revolutionize the way you fulfill your needs. With a focus on quality, we promise not just results, but revolutions in SELECTED_SERVICE. Trust us for unparalleled excellence.",
  "At YOUR_BUSINESS_NAME, we're not just offering SELECTED_SERVICES, we're offering revolutions. Our solutions are designed to revolutionize the way you fulfill your needs. With a focus on quality, we promise not just results, but revolutions in SELECTED_SERVICE. Trust us for unparalleled excellence.",
  "Welcome to YOUR_BUSINESS_NAME, where excellence is not an option - it's a standard. Our SELECTED_SERVICES are crafted to set new benchmarks, offering not just solutions but experiences. With a dedication to quality innovation. Choose us for a transformative SELECTED_SERVICE experience.",
  "Step into a world of limitless possibilities with YOUR_BUSINESS_NAME. Our SELECTED_SERVICES go beyond conventional solutions to offer experiences that captivate. With an unwavering commitment to quality, we deliver results that set new standards. Trust us to elevate your specific needs or demands like never before.",
  "Experience the extraordinary with YOUR_BUSINESS_NAME. Our SELECTED_SERVICES are more than just solutions – they're innovations that redefine standards. With an unwavering focus on quality, we promise nothing short of exceptional results. Trust us to elevate your SELECTED_SERVICE experience.",
];

export const TRAVEL_BUSINESS_BIO = [
  "YOUR_BUSINESS_NAME specializes in reliable taxi booking services, ensuring a smooth and comfortable ride. We also offer railway, flight, bus, and hotel ticket booking for comprehensive travel planning. With a focus on customer satisfaction, YOUR_BUSINESS_NAME is your trusted partner for all travel needs, making your journey effortless and enjoyable.",
  "YOUR_BUSINESS_NAME excels in taxi booking services, providing a reliable and comfortable ride every time. In addition, we offer railway, flight, bus, and hotel ticket booking, ensuring all your travel planning is comprehensive. Committed to customer satisfaction, YOUR_BUSINESS_NAME is your dependable partner for all travel needs, making your journey easy and pleasant.",
  "Specializing in reliable taxi booking, YOUR_BUSINESS_NAME ensures a smooth and cozy ride. We also handle railway, flight, bus, and hotel ticket booking for comprehensive travel planning. With a commitment to customer satisfaction, YOUR_BUSINESS_NAME is your trusted partner for all travel needs, making your journey hassle-free and enjoyable.",
  "YOUR_BUSINESS_NAME provides dependable taxi booking services for a smooth and comfortable journey. Our offerings also include railway, flight, bus, and hotel ticket booking, ensuring all your travel plans are covered. With a strong commitment to customer satisfaction, YOUR_BUSINESS_NAME stands as your reliable partner for all travel needs, making your trips hassle-free and enjoyable.",
  "Experience seamless travel with YOUR_BUSINESS_NAME, your go-to for reliable taxi booking services. We also facilitate railway, flight, bus, and hotel ticket booking to ensure comprehensive travel planning. Focused on delivering customer satisfaction, YOUR_BUSINESS_NAME is your trusted partner, making every journey effortless and pleasant.",
  "Discover seamless travel with YOUR_BUSINESS_NAME, where our specialty is reliable taxi booking for a comfortable journey. We also take care of all your travel needs with railway, flight, bus, and hotel ticket bookings. With a focus on excellence and customer satisfaction, YOUR_BUSINESS_NAME ensures every trip is convenient and pleasant.",
  "For a hassle-free travel experience, choose YOUR_BUSINESS_NAME. Our dependable taxi booking services ensure a comfortable ride, and we also offer railway, flight, hotel and bus ticket bookings. Committed to customer satisfaction, YOUR_BUSINESS_NAME is your trusted partner in making every journey seamless and enjoyable.",
  "Experience the ease of travel with YOUR_BUSINESS_NAME, known for its reliable taxi booking services. Alongside, we offer railway, flight, hotel, and bus ticket booking to meet all your travel requirements. Our commitment to customer satisfaction ensures that YOUR_BUSINESS_NAME is your trusted partner for seamless and enjoyable journeys."
];
export const generateBio = (randomIndex, businessName, categoryTitle, categoryName="" ) => {
  let bios;
if (categoryName === 'Travels') {
    bios = TRAVEL_BUSINESS_BIO;
    categoryTitle = 'Travels'
  } else {
    bios = STATIC_BUSINESS_BIO;
  }
console.log("catname2", categoryTitle );
  let randomContent = bios[randomIndex];
  if (randomContent.includes("YOUR_BUSINESS_NAME")) {
    randomContent = randomContent.replaceAll("YOUR_BUSINESS_NAME", businessName);
  }
  if (randomContent.includes("SELECTED_SERVICES")) {
    randomContent = randomContent.replaceAll("SELECTED_SERVICES", `${categoryTitle}s`);
  }
  if (randomContent.includes("SELECTED_SERVICE")) {
    randomContent = randomContent.replaceAll("SELECTED_SERVICE", categoryTitle);
  }
  return randomContent;
};
