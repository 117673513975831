import { APIs, NAVIGATION_ROUTE } from "../common/constants";
import {
  showAlert,
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";

export const getUserServices = (payload) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_SERVICE_LIST" });
    const { data } = await axios.post(`${APIs.GET_ALL_TRAVEL_SERVICES}`, {
      ...payload,
    });

    if (data.status) {
      dispatch({
        type: "SET_SERVICE_LIST",
        payload: data?.data || [],
        pagination: {
          totalDocuments: data?.totalDocuments || 0,
          imageBackendUrl: data?.imageBackendUrl || "",
        },
      });
    } else {
      dispatch({
        type: "SET_SERVICE_LIST",
        payload: [],
        pagination: {
          totalDocuments: 0,
          imageBackendUrl: "",
        },
      });
    }
  } catch (err) {
    showThrowError(err);
    dispatch({
      type: "FETCH_SERVICE_ERROR",
      payload: err,
    });
  }
};

export const addEditUserService =
  (payload, onAction = undefined, callAgainApi = undefined) =>
  async (dispatch) => {
    try {
      let fd = new FormData();
      delete payload.thumbnail;
      for (let key in payload) {
        if (key === "image") {
          fd.append("thumbnail", payload[key]);
        } else if (key === "features") {
          if (payload[key].length > 0) {
            payload[key].forEach((f, i) => {
              fd.append(`features[${i}]`, f);
            });
          }
        } else {
          fd.append(key, payload[key]);
        }
      }
      dispatch({
        type: "FORM_SUBMITTING",
      });
      let URL =
        payload?._id !== undefined
          ? `${APIs.UPDATE_EXISTING_TRAVEL_SERVICE}/${payload?._id}`
          : APIs.ADD_NEW_TRAVEL_SERVICE;

      const { data } = await axios({
        method: "post",
        url: URL,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        showSuccess(data.msg);
        if (onAction !== undefined) onAction();
        if (callAgainApi !== undefined) callAgainApi();
      } else {
        if (data?.alert) {
          showAlert(data.msg);
        } else {
          showError(data.msg);
        }
      }
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      showThrowError(err);
    }
  };
export const deleteUserService = (_id, onAction) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`${APIs.DELETE_TRAVEL_SERVICE}/${_id}`);

    if (data.status) {
      showSuccess(data.msg);
      if (onAction !== undefined) onAction();
    } else if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    showThrowError(err);
  }
};
