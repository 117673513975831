import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ServiceCard from "./ServiceCard";

const LimitedVehicles = ({
  limitedService,
  services,
  categoryName,
  heading,
  type,
}) => {
    // add class before min width 992  start
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 992);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    // add class before min width 992  end
  let { username } = useParams();

  if (services.length > 0)
    return (
      <section className="our_product sect_bg travel_services">
        <h3 className="sect_title text-center">Our {heading}</h3>
        <div className={isMobile ? "" : "desktop_sect_width"}>
        <div className="products_parent">
          {services.map((service) => (
            <ServiceCard
              key={service._id}
              service={service}
              username={username}
            />
          ))}
        </div>
        {limitedService?.totalServices > 5 && type === "serviceInfo" && (
          <>
            <div className="vg22"></div>
            <Link to={`/${username}/services`} className="theme_btn desktop_btn">
              View All {heading}
            </Link>
          </>
        )}{" "}
        </div>
      </section>
    );
  else return null;
};

export default LimitedVehicles;
