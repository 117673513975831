import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";

// portal navbar
import PortalNavbar from "../PortalNavbar";
import ASSETS from "../../../common/images";
import { contactSchema } from "../../../common/validations/formValidation";
import { sendContactDetails } from "../../../store/actions/contactActions";
import TextError from "../../../shared/TextError";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { connect } from "react-redux";

const PContactUs = ({ profile, submitting, sendContactDetails }) => {
      // add class before min width 992  start
const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

useEffect(() => {
  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);
// add class before min width 992  end
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    sendContactDetails(
      { ...values, userId: profile?._id, senderType: "visitor" },
      resetForm
    );
  };
  return (
    <div className="portal_page_spacing">
      <div className={isMobile ? "" : "container"}>
      <PortalNavbar
        title="Contact Us"
        backgroundColor="#D6EFFF"
        backArrow="arrow_back"
      />

     <div className="desktop_sect_width dtp">
     <div className="pcontact">
        <div className="address">
          <h5 className="m16 purple">{profile?.businessInfo?.title || ""}</h5>
          <div className="vg12"></div>
          <ul>
            <li className="relative">
              <span className="material-symbols-outlined location_icon">
                location_on
              </span>
              <h6 className="r14">
                {profile?.businessInfo?.address?.fullAddress || ""}
              </h6>
              <div className="vg8"></div>
              <h6 className="r14">
                {profile?.businessInfo?.address?.cityName || ""},&nbsp;
                {profile?.businessInfo?.address?.stateName || ""} -{" "}
                {profile?.businessInfo?.address?.countryName || ""}
              </h6>
              <div className="vg8"></div>
              {profile?.businessInfo?.address?.googleLink && (
                <Link
                  to={profile?.businessInfo?.address?.googleLink}
                  className="theme_btn short_btn view_btn"
                >
                  Google Map
                </Link>
              )}
            </li>
          </ul>
        </div>
        <div className="sect_divider"></div>
        <div className="contact_detail">
          <h5 className="m16 purple">Contact Now</h5>
          <div className="vg12"></div>
          <div className="contact_parent">
            <Link
              to={`tel:${profile?.personalInfo?.phoneNumber || ""}`}
              className="contact_single text-center pointer"
            >
              <div className="cs_inner">
                <img src={ASSETS.callIcon} alt="" />
              </div>
              <div className="vg12"></div>
              <h6 className="grad_text">Call</h6>
            </Link>
            <Link
              to={`https://wa.me/${
                profile?.personalInfo?.whatsapNumber || ""
              }/?text=Hello`}
              className="contact_single text-center pointer"
            >
              <div className="cs_inner">
                <img src={ASSETS.whatsappIcon} alt="" />
              </div>
              <div className="vg12"></div>
              <h6 className="grad_text">Whatsapp</h6>
            </Link>
            {profile?.businessInfo?.email && (
              <Link
                to={`mailto:${profile?.businessInfo?.email || ""}`}
                className="contact_single text-center pointer"
              >
                <div className="cs_inner">
                  <img src={ASSETS.emailIcon} alt="" />
                </div>
                <div className="vg12"></div>
                <h6 className="grad_text">Email</h6>
              </Link>
            )}
          </div>
        </div>
        <div className="sect_divider"></div>
        <div className="contact_form">
          <h5 className="m16 purple">Any query? Leave message here</h5>
          <div className="vg12"></div>

          <Formik
            initialValues={initialValues}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="form_field">
                      <label htmlFor="name">Name</label>
                      <Field
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                      />{" "}
                      <ErrorMessage name="name" component={TextError} />
                    </div>
                  </div>
                  <div className="ff_gap"></div>
                  <div className="col-12">
                    <div className="form_field">
                      <label htmlFor="phoneNumber">Mobile Number</label>
                      <Field
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter number"
                      />
                      <ErrorMessage name="phoneNumber" component={TextError} />
                    </div>
                  </div>
                  <div className="ff_gap"></div>
                  <div className="col-12">
                    <div className="form_field">
                      <label htmlFor="message">Message</label>
                      <Field
                        as="textarea"
                        name="message"
                        id="message"
                        placeholder="Message here"
                      />{" "}
                      <ErrorMessage name="message" component={TextError} />
                    </div>
                  </div>
                  <div className="vg22"></div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="theme_btn desktop_btn"
                      disabled={submitting}
                      
                    >
                      {submitting ? <ActivityIndigator /> : "Submit"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
     </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ form }) => {
  return { submitting: form.submitting };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendContactDetails: (payload, onAction) =>
      dispatch(sendContactDetails(payload, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PContactUs);
