import React from "react";
import Accordion from "react-bootstrap/Accordion";
import usePageTitle from "../../../common/hooks/usePageTitle";
const AccountFaq = () => {
  usePageTitle("FAQ");
  return (
    <div className="pg_faq">
      <Accordion defaultActiveKey="0" className="theme_accordion style_2">
        <Accordion.Item eventKey="0" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">
                How can I change my mobile number?
              </h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="r14 height_limit">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">
                Can I change my business using one account?
              </h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="r14 height_limit">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">
                Where can I see my total viewers?
              </h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <p className="r14 height_limit">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default AccountFaq;
