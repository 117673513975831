import toast from "../toast";

export const showThrowError = (error) => {
  let errorMessage = error?.response?.data?.msg ?? "Someting went wrong";
  toast.error(errorMessage);
};

export const showSuccess = (msg) => {
  toast.success(msg);
};

export const showError = (msg) => {
  toast.error(msg);
};

export const showAlert = (msg) => {
  toast.warning(msg);
};
