// const baseurl = "http://localhost:3000";
const baseurl = "https://businessbaab.com";
const ASSETS = {
  faviconIcon: baseurl + "/assets/img/fevicon_browser.png",
  businessLogo: baseurl + "/assets/img/portal_logo.jpg",
  uploadIcon: baseurl + "/assets/img/upload_img.png",
  uploadIconNew :baseurl + "/assets/img/upload_img_promoter.svg",
  uploadSmallIcon: baseurl + "/assets/img/upload_img_small.png",
  businessBaapLogo: baseurl + "/assets/img/logo_businessbaab.svg",
  landingImage: baseurl + "/assets/img/congrates.svg",

  notFoundError: baseurl + "/assets/img/404_error.svg",
  QRCode: baseurl + "/assets/img/qr_code.svg",
  PERSON: baseurl + "/assets/img/user.png",

  demoProduct: baseurl + "/assets/img/product_demo.jpg",
  dummyproductimg: baseurl + "/assets/img/image_not_available.jpg",
  

  // icons
  QRcodeIcon: baseurl + "/assets/img/menu_icon/qr.svg",
  languagesIcon: baseurl + "/assets/img/menu_icon/language2.svg",
  contactIcon: baseurl + "/assets/img/menu_icon/contact2.svg",
  shareIcon: baseurl + "/assets/img/menu_icon/share.svg",
  aboutUsIcon: baseurl + "/assets/img/menu_icon/personal.svg",
  homeIcon: baseurl + "/assets/img/menu_icon/home.svg",
  menuIcon: baseurl + "/assets/img/menu_icon.svg",
  languageIcon: baseurl + "/assets/img/language.svg",
  shoppingCartIcon: baseurl + "/assets/img/shopping-cart.png",
  emailIcon: baseurl + "/assets/img/c_mail.png",
  whatsappIcon: baseurl + "/assets/img/c_whatsapp.png",
  callIcon: baseurl + "/assets/img/c_call.png",
  addressIcon: baseurl + "/assets/img/c_address.png",
  businessIcon: baseurl + "/assets/img/menu_icon/business.svg",
  notificationIcon: baseurl + "/assets/img/menu_icon/notification.svg",
  faqIcon: baseurl + "/assets/img/menu_icon/faq.svg",
  settingsIcon: baseurl + "/assets/img/menu_icon/setting.svg",
  policyIcon: baseurl + "/assets/img/menu_icon/terms.svg",
  knowMoreIcon: baseurl + "/assets/img/menu_icon/web.svg",
  logoutIcon: baseurl + "/assets/img/menu_icon/logout2.svg",
  editIcon: baseurl + "/assets/img/edit.png",
  filterIcon: baseurl + "/assets/img/filter.png",
  eyeIcon: baseurl + "/assets/img/eye.png",

  // social links
  facebook: baseurl + "/assets/img/facebook.png",
  twitter: baseurl + "/assets/img/twitter.png",
  snapchat: baseurl + "/assets/img/snapchat.png",
  linkedin: baseurl + "/assets/img/linkedin.png",
  threads: baseurl + "/assets/img/threads.png",
  instagram: baseurl + "/assets/img/instagram.png",
  telegram: baseurl + "/assets/img/telegram.png",
  // payment links
  phonepe: baseurl + "/assets/img/phonepe.png",
  gpay: baseurl + "/assets/img/gpay.png",
  paytm: baseurl + "/assets/img/paytm.jpg",
  bhim: baseurl + "/assets/img/bhim.png",
  bharatpay: baseurl + "/assets/img/bharatpay.png",
};

export default ASSETS;
