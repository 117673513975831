import React from "react";
import { Link } from "react-router-dom";

import usePageTitle from "../../common/hooks/usePageTitle";
import { LABELS } from "../../common/label";
import ContactList from "./ContactList";
import { CATEGORY_LISTS } from "../../common/constants";

const Dashboard = ({ userDetails, categoryName }) => {
  const cardData = [
    {
      id: 1,
      iconSrc: "./assets/img/personal.svg",
      title: "Edit Personal Info",
      click: "/account/edit-personal-info",
      active: true,
    },
    {
      id: 2,
      iconSrc: "./assets/img/business.svg",
      title: "Edit Business Info",
      click: "/account/edit-business-info",
      active: true,
    },
    {
      id: 3,
      iconSrc: "./assets/img/product.svg",
      title: LABELS.EDIT_SERVICE[categoryName] || "Edit Service",
      click:
        CATEGORY_LISTS.TRAVEL === userDetails?.categoryName
          ? "/account/edit-travel-service-info"
          : "/account/edit-service-info",
      active: true,
    },
    {
      id: 7,
      iconSrc: "./assets/img/product.svg",
      title: "Edit Vehicle",
      click: "/account/edit-vehicle-info",
      active: CATEGORY_LISTS.TRAVEL === userDetails?.categoryName,
    },
    {
      id: 8,
      iconSrc: "./assets/img/product.svg",
      title: "Edit Package",
      click: "/account/edit-package-info",
      active: CATEGORY_LISTS.TRAVEL === userDetails?.categoryName,
    },
    {
      id: 4,
      iconSrc: "./assets/img/payment.svg",
      title: "Edit Payment Info",
      click: "/account/edit-payment-info",
      active: true,
    },
    {
      id: 5,
      iconSrc: "./assets/img/color_theme.svg",
      title: "Change Theme Color",
      click: "/account/change-theme",
      active: true,
    },
    // {
    //   id: 6,
    //   iconSrc: "./assets/img/url.svg",
    //   title: "Set Custom Url",
    //   click: "/account/set-custom-url", active: true,
    // },
    // {
    //   id: 7,
    //   title: "Switch to another Business",
    //   iconSrc: "./assets/img/switch.svg",
    //   click: "", active: true,
    // },
    {
      id: 6,
      title: "Change Theme Layout",
      iconSrc: "./assets/img/switch.svg",
      click: "/account/change-template",
      active: true,
    },
  ];
  const creationDateTime = new Date(userDetails?.createdAt).getTime();
  const currentTime = new Date().getTime();
  const timeDifference = currentTime - creationDateTime;
  const twentyFourHours = 24 * 60 * 60 * 1000;
  if (timeDifference < twentyFourHours) {
    cardData.push({
      id: 9,
      iconSrc: "./assets/img/url.svg",
      title: "Set Custom Url",
      click: "/account/set-custom-url",
      active: true,
    });
  }

  // if (CATEGORY_LISTS.TRAVEL === userDetails?.categoryName) {
  //   cardData.splice(3, 0, {
  //     id: 7,
  //     iconSrc: "./assets/img/product.svg",
  //     title: "Edit Vehicle",
  //     click: "/account/edit-vehicle-info",
  //   });
  // }
  usePageTitle("Account");
console.log("cat name", userDetails?.categoryName);
  return (
    <div className="dashboard">
      <section className="dashboard_settings">
        <div className="long_card_parent">
          {cardData
            .filter((f) => f.active)
            .map((card) => (
              <Link
                to={card.click}
                className={`long_card_single ${card.title}` }
                key={card.id}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <div className="lcs_inner">
                  <div className="icon">
                    <img src={card.iconSrc} alt="" />
                  </div>
                  <h6 className="r14">{card.title}</h6>
                </div>
                <span className="material-symbols-outlined arrow_forward">
                  arrow_forward
                </span>
              </Link>
            ))}
        </div>
      </section>
      <div className="vg22"></div>
      {userDetails?.publicIdentifier && (
        <Link to={`/${userDetails?.publicIdentifier}`} className="theme_btn">
          View Your Website
        </Link>
      )}

      <div className="vg30"></div>
      <ContactList />
    </div>
  );
};

export default Dashboard;
