import React, { useState } from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { sendHelpSupportDetails } from "../../../store/actions/contactActions";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { helpSupportSchema } from "../../../common/validations/formValidation";
import TextError from "../../../shared/TextError";
const REASONS = ["query 1", "query 2", "query 3"];
const HelpAndSupport = ({ submitting, sendHelpSupportDetails }) => {
  const [showMsg, setShowMsg] = useState(false);
  const initialValues = {
    name: "",
    reason: "",
    phoneNumber: "",
    message: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission logic here
    sendHelpSupportDetails({ ...values }, resetForm, setShowMsg);
  };
  usePageTitle("Help & Support");
  return (
    <div className="pghelp">
      <h6 className="r14">Describe your query, what you want.</h6>
      <div className="vg22"></div>
      <Formik
        initialValues={initialValues}
        validationSchema={helpSupportSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form_field">
              <label htmlFor="username">Name</label>
              <Field
                type="text"
                name="name"
                id="name"
                placeholder="Enter name"
              />{" "}
              <ErrorMessage name="name" component={TextError} />
            </div>

            <div className="ff_gap"></div>

            <div className="form_field">
              <label htmlFor="yourmobile">Registered mobile number</label>
              <Field
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                placeholder="Enter number"
              />
              <ErrorMessage name="phoneNumber" component={TextError} />
            </div>
            <div className="ff_gap"></div>
            <div className="form_field">
              <label htmlFor="reason">Select query type</label>
              <Field as="select" name="reason" id="reason">
                <option value="">Select</option>
                {REASONS.map((reason, i) => (
                  <option value={reason} key={`reason_${i}`}>
                    {reason}
                  </option>
                ))}
              </Field>{" "}
              <ErrorMessage name="reason" component={TextError} />
            </div>
            <div className="ff_gap"></div>

            <div className="form_field">
              <label htmlFor="describequery">Describe your query</label>
              <Field
                as="textarea"
                name="message"
                id="message"
                placeholder="Message here"
              />{" "}
              <ErrorMessage name="message" component={TextError} />
            </div>

            <div className="vg22"></div>
            <div className="col-12">
              <button type="submit" className="theme_btn" disabled={submitting}>
                {submitting ? <ActivityIndigator /> : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {/* modal for delete product  */}
      <Modal
        show={showMsg}
        onHide={() => setShowMsg(false)}
        className="my_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">Help & Support</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={() => setShowMsg(false)}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 green">
            Your query has successfully submitted. Our team will contact you
            soon.
          </h6>
        </Modal.Body>
      </Modal>
      {/* modal for delete product  */}
    </div>
  );
};

const mapStateToProps = ({ form }) => {
  return { submitting: form.submitting };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendHelpSupportDetails: (payload, onAction, setShowMsg) =>
      dispatch(sendHelpSupportDetails(payload, onAction, setShowMsg)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpAndSupport);
