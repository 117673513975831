import React from "react";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { Accordion } from "react-bootstrap";

const Notification = () => {
  usePageTitle("Notification");
  return (
    <div className="pg_notification">
      <Accordion defaultActiveKey="0" className="theme_accordion style_2">
        <Accordion.Item eventKey="0" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Payment Getaway</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <h6 className="b14">We have added payment getaway</h6>
            <div className="vg8"></div>
            <p className="r14 height_limit">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Add one more Category</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <h6 className="b14">Add one more Category</h6>
            <div className="vg8"></div>
            <p className="r14 height_limit">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Reduced Rate</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <h6 className="b14">Reduced Rate</h6>
            <div className="vg8"></div>
            <p className="r14 height_limit">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="relative">
          <Accordion.Header>
            <div className="left">
              <h5 className="product_name">Refer & Earn</h5>
            </div>
            <div className="right">
              <span class="material-symbols-outlined open icon_grad">add</span>
              <span class="material-symbols-outlined close icon_grad">
                remove
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <h6 className="b14">Refer & Earn</h6>
            <div className="vg8"></div>
            <p className="r14 height_limit">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum. Why do we use it? It is a long established fact that
              a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it
              has a more-or-less normal distribution of letters, as opposed to
              using 'Content here, content here', making it look like readable
              English. Many desktop publishing packages and web page editors now
              use Lorem Ipsum as their default model text, and a search for
              'lorem ipsum' will uncover many web sites still in their infancy.
              Various versions have evolved over the years, sometimes by
              accident, sometimes on purpose (injected humour and the like).
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Notification;
