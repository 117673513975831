import React from "react";
// react carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const BannerSlider = ({ images }) => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 991, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="banner from_left_right relative">
      {(images || []).length > 1 ? (
        <Slider {...carouselSettings} >
          {images.map((image, i) => (
            <div key={`image-${i}`}>
              <img src={image} alt={`slide-${i}`} />
            </div>
          ))}
        </Slider>
      ) : (
        <div>
          <img src={images[0]} alt={`slide-1`} />
        </div>
      )}
    </section>
  );
};

export default BannerSlider;
