import { APIs } from "../common/constants";
import {
  showError,
  showSuccess,
  showThrowError,
} from "../common/helpers/responseHelper";
import axios from "../common/api_helpers";
export const sendContactDetails =
  (payload, onAction = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios.post(APIs.SEND_CONTACT_DETAILS, {
        ...payload,
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        showSuccess(data.msg);
        if (onAction !== undefined) {
          onAction();
        }
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });

      showThrowError(err);
    }
  };
export const sendHelpSupportDetails =
  (payload, onAction = undefined, setShowMsg = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });

      const { data } = await axios.post(APIs.SEND_SUPPORT_DETAILS, {
        ...payload,
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        // showSuccess(data.msg);
        if (onAction !== undefined) {
          onAction();
        }
        if (setShowMsg !== undefined) {
          setShowMsg(true);
        }
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });

      showThrowError(err);
    }
  };
export const sendPromoterDetails =
  (payload, onAction = undefined, setImage = undefined) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORM_SUBMITTING",
      });
      let fd = new FormData();
      for (let key in payload) {
        fd.append(key, payload[key]);
      }
      const { data } = await axios({
        method: "post",
        url: APIs.REGISTER_PROMOTER,
        data: fd,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });
      if (data.status) {
        showSuccess(data.msg);
        if (onAction !== undefined) {
          onAction();
        }
        if (setImage !== undefined) {
          setImage(null);
        }
        window.location.href = "https://console.businessbaab.com";
      } else if (!data.status) {
        showError(data.msg);
      }
      dispatch({
        type: "INITIALIZE_FORM",
      });
    } catch (err) {
      dispatch({
        type: "RESET_FORM_SUBMITTING",
      });

      showThrowError(err);
    }
  };
export const deleteContact = (_id, onAction) => async (dispatch) => {
  try {
    const { data } = await axios.delete(`${APIs.DELET_CONTACT_DETAILS}/${_id}`);

    if (data.status) {
      dispatch({ type: "FILTER_CONTACT_LISTS", payload: _id });
      showSuccess(data.msg);
      if (onAction !== undefined) onAction();
    } else if (!data.status) {
      showError(data.msg);
    }
  } catch (err) {
    showThrowError(err);
  }
};
