import React, { useEffect } from "react";
import PortalNavbar from "./PortalNavbar";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Home from "./Home/Home";
import HomeTwo from "./Home/HomeTwo";
import HomeThree from "./Home/HomeThree";
import HomeFour from "./Home/HomeFour";
import Products from "./Products/Products";
import PAboutUs from "./Pages/PAboutUs";
import PContactUs from "./Contact/PContactUs";
import Qrcode from "./Pages/Qrcode";
import ProductSingleDetail from "./Products/ProductSingleDetail";
import SoppingCart from "./Checkout/SoppingCart";
import DeliveryAddress from "./Checkout/DeliveryAddress";
import {
  getPublicUserInfo,
  updateShoppingCart,
} from "../../store/actions/publicActions";
import { connect } from "react-redux";
import ActivityLoader from "../../shared/ActivityLoader/ActivityLoader";
import { getCategory } from "../../common/utils/utils";
import VehiclePackages from "./Travel/VehiclePackages";
import VehiclePackageSingleDetails from "./Travel/VehiclePackageSingleDetails";
import BookNow from "./Checkout/BookNow";

const HomeLayout = ({
  getPublicUserInfo,
  profile,
  loading,
  limitedService,
  updateShoppingCart,
}) => {
  let { username } = useParams();
  const navigate = useNavigate();
  let categoryName = getCategory();

  useEffect(() => {
    if (username) {
      getPublicUserInfo(username, navigate);
      updateShoppingCart();
    }
  }, [getPublicUserInfo, username, updateShoppingCart]);

  const templates = {
    template_1: Home,
    template_2: HomeTwo,
    template_3: HomeThree,
    template_4: HomeFour,
  };

  const renderComponent = () => {
    let TemplateComponent = templates[profile.theme?.template];
    if (!TemplateComponent) {
      TemplateComponent = Home;
    }

    return (
      <TemplateComponent
        profile={profile}
        limitedService={limitedService}
        categoryName={categoryName}
      />
    );
  };

  return (
    <div
      className={`${profile?.theme?.colorClass} ${profile?.theme?.template}`}
    >
      {loading ? <ActivityLoader /> : null}
      <Routes>
        {" "}
        {/* <Route
          path="/"
          element={
            <Home
              profile={profile}
              limitedService={limitedService}
              categoryName={categoryName}
            />
          }
        /> */}
        <Route path="/" element={renderComponent()} />
        <Route
          path="/about-us"
          element={<PAboutUs profile={profile} />}
        ></Route>
         <Route
          path="/portalNavbar"
          element={<PortalNavbar profile={profile} />}
        ></Route>
        
        <Route
          path="/contact-us"
          element={<PContactUs profile={profile} />}
        ></Route>
        <Route path="/qrcode" element={<Qrcode profile={profile} />}></Route>
        <Route
          path="/products"
          element={<Products profile={profile} categoryName={categoryName} />}
        ></Route>
        <Route
          path="/packages"
          element={
            <VehiclePackages
              profile={profile}
              categoryName={categoryName}
              type={"packageInfo"}
            />
          }
        ></Route>{" "}
        <Route
          path="/vehicles"
          element={
            <VehiclePackages
              profile={profile}
              categoryName={categoryName}
              type={"vehicleInfo"}
            />
          }
        ></Route>{" "}
        <Route
          path="/services"
          element={
            <VehiclePackages
              profile={profile}
              categoryName={categoryName}
              type={"serviceInfo"}
            />
          }
        ></Route>
        <Route
          path="/travel/details/:_id"
          element={<VehiclePackageSingleDetails categoryName={categoryName} />}
        ></Route>
        <Route
          path="/product/:_id"
          element={<ProductSingleDetail categoryName={categoryName} />}
        ></Route>
        <Route path="/shopping-cart" element={<SoppingCart />}></Route>
        <Route
          path="/checkout"
          element={<DeliveryAddress profile={profile} />}
        ></Route>
        <Route path="/booknow" element={<BookNow profile={profile} />}></Route>
      </Routes>
    </div>
  );
};

const mapStateToProps = ({ profile: { user, loading, limitedService } }) => {
  return {
    profile: user,
    loading,
    limitedService,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPublicUserInfo: (username, navigate) =>
      dispatch(getPublicUserInfo(username, navigate)),

    updateShoppingCart: () => dispatch(updateShoppingCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeLayout);
