export const LABELS = {
  HOME_LIMITED_SERVICE: {
    "All Services": "Our Services",
    "Online Store": "Our Products",
    "Retail Shop": "Our Products",
    Travels: "Our Services",
  },
  HOME_VIEW_SERVICE: {
    "All Services": "View All Services",
    "Online Store": "View All Products",
    "Retail Shop": "View All Products",
    Travels: "View All Services",
  },
  SINGLE_SERVICE_HEADER: {
    "All Services": "Service",
    "Online Store": "Product",
    "Retail Shop": "Product",
    Travels: "Service",
  },
  ALL_SERVICE_HEADER: {
    "All Services": "Services",
    "Online Store": "Products",
    "Retail Shop": "Products",
    Travels: "Travels",
  },
  BUSINESS_NAME: {
    "All Services": "Service Name",
    "Online Store": "Store Name",
    "Retail Shop": "Business Name",
    Travels: "Travel Name",
  },
  BUSINESS_TITLE: {
    "All Services": "Service",
    "Online Store": "Store",
    "Retail Shop": "Business",
    Travels: "Travel",
  },
  ADD_SERVICE: {
    "All Services": "Service Info",
    "Online Store": "Product Info",
    "Retail Shop": "Product Info",
    Travels: "Travel Info",
  },
  EDIT_SERVICE: {
    "All Services": "Edit Service Info",
    "Online Store": "Edit Product Info",
    "Retail Shop": "Edit Product Info",
  },
  SERVICE_TITLE: {
    "All Services": "Service",
    "Online Store": "Product",
    "Retail Shop": "Product",
    Travels: "Vehicle",
  },
  MY_SERVICE_TITLE: {
    "All Services": "My Services",
    "Online Store": "My Products",
    "Retail Shop": "My Products",
  },
};
