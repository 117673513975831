import React, { useEffect } from "react";
import { connect } from "react-redux";
import { sendContactDetails } from "../../store/actions/contactActions";
import {
  getCities,
  getCountries,
  getStates,
} from "../../store/actions/commonActions";
import { ErrorMessage, Field, Form, Formik } from "formik";
import TextError from "../../shared/TextError";
import ActivityIndigator from "../../shared/ActivityIndigator";
import { contactSchema } from "../../common/validations/formValidation";

const ContactForm = ({
  sendContactDetails,
  submitting,
  getCountries,
  getStates,
  getCities,
  countries,
  states,
  cities,
}) => {
  useEffect(() => {
    getCountries();
    getStates(101);
  }, [getCountries, getStates]);
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    countryId: "101",
    stateId: "",
    cityId: "",
    message: "",
  };
  const handleSubmit = (values, { resetForm }) => {
    sendContactDetails({ ...values, senderType: "visitor" }, resetForm);
  };
  const handleCountryChange = (value, setFieldValue) => {
    if (value) {
      getStates(value);
      setFieldValue("countryId", value);
      setFieldValue("stateId", "");
      setFieldValue("cityId", "");
    }
  };

  const handleStateChange = (value, setFieldValue) => {
    if (value) {
      getCities(value);
      setFieldValue("stateId", value);
      setFieldValue("cityId", "");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form
          onSubmit={handleSubmit}
          style={{
            maxWidth: "520px",
            width: "100%",
            margin: "auto",
          }}
        >
          <div className="row">
            <div className="col-6">
              <div className="form_field">
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter name"
                />{" "}
                <ErrorMessage name="name" component={TextError} />
              </div>
              <div className="ff_gap"></div>
            </div>
            <div className="col-6 pl0">
              <div className="form_field">
                <label htmlFor="cp_wa_number">WhatsApp*</label>
                <Field
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Enter whatsapp"
                />
                <ErrorMessage name="phoneNumber" component={TextError} />
              </div>
              <div className="ff_gap"></div>
            </div>
            <div className="col-6">
              <div className="form_field">
                <label htmlFor="cp_eid" className="field_optional">
                  Email Id (optional)
                </label>
                <Field
                  type="text"
                  name="email"
                  id="cp_eid"
                  placeholder="Enter email address"
                />
                <ErrorMessage name="email" component={TextError} />
              </div>
              <div className="ff_gap"></div>
            </div>
            <div className="col-6 pl0">
              <div className="form_field">
                <label htmlFor="cp_subj" className="field_optional">
                  Subject (optional)
                </label>
                <Field
                  type="text"
                  name="subject"
                  id="cp_subj"
                  placeholder="Type Subject"
                />
              </div>
              <div className="ff_gap"></div>
            </div>
            <div className="col-4">
              <div className="form_field">
                <label htmlFor="businesscountry">Country</label>
                <Field
                  as="select"
                  name="countryId"
                  id="countryId"
                  onChange={(e) =>
                    handleCountryChange(e.target.value, setFieldValue)
                  }
                  value={values.countryId}
                >
                  {" "}
                  <option value="">Select</option>
                  {(countries ?? []).map((country, i) => (
                    <option key={`country_${i}`} value={country.countryId}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="countryId" component={TextError} />
              </div>
              <div className="ff_gap"></div>
            </div>
            <div className="col-4 pl0">
              <div className="form_field">
                <label htmlFor="businessstate">State*</label>
                <Field
                  as="select"
                  name="stateId"
                  id="stateId"
                  onChange={(e) =>
                    handleStateChange(e.target.value, setFieldValue)
                  }
                  value={values.stateId}
                >
                  {" "}
                  <option value="">Select</option>
                  {(states ?? []).map((state, i) => (
                    <option key={`state_${i}`} value={state.stateId}>
                      {state.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="stateId" component={TextError} />
              </div>
              <div className="ff_gap"></div>
            </div>
            <div className="col-4 pl0">
              <div className="form_field">
                <label htmlFor="businesscity">City*</label>
                <Field as="select" name="cityId" id="cityId">
                  <option value="">Select</option>
                  {(cities ?? []).map((city, i) => (
                    <option key={`city_${i}`} value={city.cityId}>
                      {city.name}
                    </option>
                  ))}
                </Field>{" "}
                <ErrorMessage name="cityId" component={TextError} />
              </div>
            </div>
            <div className="col-12">
              <div className="form_field">
                <label htmlFor="cp_message">Message</label>
                <Field
                  as="textarea"
                  name="message"
                  id="message"
                  placeholder="Type here"
                />{" "}
                <ErrorMessage name="message" component={TextError} />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="vg22"></div>
            <button
              type="submit"
              className="theme_btn short_btn"
              disabled={submitting}
            >
              {submitting ? <ActivityIndigator /> : "Submit"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
const mapStateToProps = ({ form, common }) => {
  return { submitting: form.submitting, ...common };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: (countryId) => dispatch(getStates(countryId)),
    getCities: (cityId) => dispatch(getCities(cityId)),
    sendContactDetails: (payload, onAction, setImage) =>
      dispatch(sendContactDetails(payload, onAction, setImage)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
