import React, { useEffect, useState } from "react";
import ASSETS from "../../../common/images";

const ShoppingCartIem = ({ item, updateCartItems, handleDeleteClick }) => {
  const [qty, setQty] = useState(0);

  useEffect(() => {
    if (item?._id) {
      setQty(item?.qty);
    }
  }, [item]);
  const handleChange = (e) => {
    let enteredValue = Number(e.target.value);
    // if (enteredValue <= item?.quantity) {
    setQty(enteredValue);
    updateCartItems(item, enteredValue);
    // }
  };

  return (
    <div className="cart_single relative">
      <div className="product_img">
        <img
          src={item?.thumbnail ? item?.thumbnail : ASSETS.demoProduct}
          alt={item?.title}
        />
      </div>
      <div className="right">
        <div className="detail">
          <h5 className="m12 only_two_line">{item?.title}</h5>
          <h6 className="r12 blue">
            {item?.price} x {item?.qty} ={" "}
            <span className="sb12">₹{item?.price * item?.qty}</span>
          </h6>
        </div>
        <div className="quantity">
          <div className="form_field">
            <div className="form_field_inner">
              <input
                type="number"
                name="quantity"
                id="quantity"
                placeholder=""
                value={qty}
                min={0}
                max={item?.quantity}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="close_media" onClick={() => handleDeleteClick(item?._id)}>
        <span className="material-symbols-outlined"> close </span>
      </div>
    </div>
  );
};

export default ShoppingCartIem;
