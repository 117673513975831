import React, { useState, useEffect } from "react";

// react tabs
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import usePageTitle from "../../../common/hooks/usePageTitle";
import OrderItem from "./OrderItem";
import { connect } from "react-redux";
import {
  getOrders,
  deleteOrder,
  changeOrderStatus,
} from "../../../store/actions/orderActions";
import ActivityLoader from "../../../shared/ActivityLoader/ActivityLoader";
import ConfirmationAlert from "../../../shared/Model/ConfirmationAlert";
import { useParams } from "react-router-dom";
import InvoiceModal from "./InvoiceModal";
import {
  CATEGORY_LISTS,
  FILTER_TRAVEL_OPTIONS,
} from "../../../common/constants";
import { getSession } from "../../../store/actions/authActions";
const Orders = ({
  userDetails,
  submitting,
  loading,
  orders,
  totalOrders,
  backendAssetUrl,
  getOrders,
  deleteOrder,
  changeOrderStatus,
  totalOtherOrders,
}) => {
  const [search, setSearch] = useState(null);
  const [tab, setTab] = useState("pending");
  const [type, setType] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const { orderType } = useParams();
  const [invoiceData, setInvoiceData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);

  const [filterType, setFilterType] = useState("all");
  const { categoryName } = getSession();

  // useEffect(() => {
  //   getOrders({
  //     pageNumber: 1,
  //     limit: 100,
  //     sortType: -1,
  //     search: "",
  //     type: 0,
  //   });
  // }, [orderType, getOrders]);

  useEffect(() => {
    if (orderType !== undefined) {
      if (orderType === "pending") {
        setTab("pending");
        setType(0);
        getOrders({
          pageNumber: 1,
          limit: 100,
          sortType: -1,
          search: "",
          type: 0,
          filterType,
          categoryName,
        });
      } else if (orderType === "complete") {
        setTab("complete");
        setType(1);
        getOrders({
          pageNumber: 1,
          limit: 100,
          sortType: -1,
          search: "",
          type: 1,
          filterType,
          categoryName,
        });
      }
    } else {
      getOrders({
        pageNumber: 1,
        limit: 100,
        sortType: -1,
        search: "",
        type: 0,
        filterType,
        categoryName,
      });
    }
  }, [orderType, getOrders, filterType]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search || search !== null) {
        getOrders({
          search,
          pageNumber: 1,
          limit: 100,
          sortType: -1,
          type: type,
          filterType,
          categoryName,
        });
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search, getOrders]);
  const handleTab = (t) => {
    setTab(t);
    if (t === "complete") {
      setType(1);
      callAgainApi({ type: 1 });
    } else {
      setType(0);
      callAgainApi({ type: 0 });
    }
  };
  const handleStatusChange = (order, status) => {
    changeOrderStatus(
      {
        orderId: order._id,
        status: status ? 1 : 0,
      },
      onReset
    );
  };
  const onReset = () => {};

  const handleDeleteClick = (event, data) => {
    event.stopPropagation();
    if (data?._id) {
      deleteHandler(data?._id);
    }
  };
  const deleteHandler = (deleteId) => {
    setDeleteId(deleteId);
    setShowConfirm(true);
  };

  const deleteActionHanlder = () => {
    setDeleting(true);
    deleteOrder(deleteId, onAction);
  };

  const onAction = () => {
    setShowConfirm(false);
    setDeleteId(null);
    setDeleting(false);
    callAgainApi();
  };

  const callAgainApi = (payload = {}) => {
    getOrders({
      pageNumber: 1,
      limit: 100,
      sortType: -1,
      search: "",
      type: type,
      filterType,
      categoryName,
      ...payload,
    });
  };

  usePageTitle("Orders");
  const handleData = (order) => {
    setInvoiceData(order);
    setShowInvoice(true);
    console.log("order", order);
  };
  // console.log("userdetail", userDetails);

  return (
    <div className="pgorders">
      {" "}
      {loading ? <ActivityLoader /> : null}
      <div className="search_with_filter">
        {/* <SearchFilter
          setSearch={setSearch}
          search={search}
          placeholder="Search Name"
          showLabel={true}
        /> */}
        {/* <Link to="/account/filter" className="my_filter">
          <img src={ASSETS.filterIcon} alt="" />
          <span className="grad_text gt_border">Filter</span>
        </Link> */}
      </div>
      {CATEGORY_LISTS.TRAVEL === categoryName && (
        <div className="inline_radio_group">
          {FILTER_TRAVEL_OPTIONS.map((o) => (
            <div className="radio_field" key={o.id}>
              <label>
                <input
                  type="radio"
                  name="type"
                  value={o.value}
                  checked={filterType === o.value}
                  onChange={() => setFilterType(o.value)}
                />
                {o.label}
              </label>
            </div>
          ))}
        </div>
      )}
      <div className="vg30"></div>
      <div className="my_tab lead_order">
        <Tabs
          id="controlled-tab-example"
          activeKey={tab}
          onSelect={(k) => handleTab(k)}
          className="mb-3"
        >
          <Tab
            eventKey="pending"
            title={
              <div className="tab_title">
                <h6 className="m10">
                  {" "}
                  {tab === "pending" ? totalOrders : totalOtherOrders}
                </h6>
                <h5 className="m16">Total Leads</h5>
              </div>
            }
          >
            <OrderItem
              orders={orders}
              handleStatusChange={handleStatusChange}
              handleDeleteClick={handleDeleteClick}
              backendAssetUrl={backendAssetUrl}
              handleData={handleData}
            />
          </Tab>
          <Tab
            eventKey="complete"
            title={
              <div className="tab_title">
                <h6 className="m10">
                  {tab === "complete" ? totalOrders : totalOtherOrders}
                </h6>
                <h5 className="m16">Done Orders</h5>
              </div>
            }
          >
            <OrderItem
              orders={orders}
              toggleBtn={false}
              handleDeleteClick={handleDeleteClick}
              backendAssetUrl={backendAssetUrl}
              handleData={handleData}
            />
          </Tab>
        </Tabs>
      </div>
      {showConfirm && (
        <ConfirmationAlert
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
          actionHandler={deleteActionHanlder}
          deleting={deleting}
          labelName={"order"}
        />
      )}
      {/* invoice modal */}
      {showInvoice && invoiceData && (
        <InvoiceModal
          order={invoiceData}
          userDetails={userDetails}
          show={showInvoice}
          onHide={() => setShowInvoice(false)}
        />
      )}
    </div>
  );
};
const mapStateToProps = ({
  form,
  order: { loading, orders, totalOrders, backendAssetUrl, totalOtherOrders },
}) => {
  return {
    submitting: form.submitting,
    loading,
    orders,
    totalOrders,
    backendAssetUrl,
    totalOtherOrders,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: (payload) => dispatch(getOrders(payload)),
    changeOrderStatus: (payload, onAction) =>
      dispatch(changeOrderStatus(payload, onAction)),
    deleteOrder: (_id, onAction) => dispatch(deleteOrder(_id, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
