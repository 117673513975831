import React from "react";
import { Formik, Field, Form } from "formik";
// vendor navbar
import VendorNavbar from "../component/VendorNavbar";

const PGLangauge = () => {
  const initialValues = {};

  const handleSubmit = (values) => {
    // Handle form submission logic here
    console.log(values);
  };
  return (
    <div className="vendor_page_spacing">
      <VendorNavbar title="Language" backgroundColor="white" backArrow="arrow_back" />
      <div className="pg_language">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form>
            <div className="form_field">
              <label htmlFor="select_language">Change language</label>
              <Field as="select" id="select_language" name="select_language">
                <option value="">Select</option>
                <option value="option1">English</option>
                <option value="option2">Hindi</option>
                <option value="option3">Urdu</option>
              </Field>
            </div>
            <div className="vg22"></div>
            <button type="submit" className="theme_btn">
              Update
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default PGLangauge;
