import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
  maxWidth = 200,
  maxHeight = 200
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        width: maxWidth,
        height: maxHeight,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const CommonImageCropper = (props) => {
  const [crop, setCrop] = useState();

  const imgRef = useRef(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState();
  function onImageLoad(e) {
    let aspect = props?.imageSettings?.aspect || 1;
    if (aspect) {
      const { width, height } = e.currentTarget;
      const { maxHeight, maxWidth } = props?.imageSettings;
      setCrop(centerAspectCrop(width, height, aspect, maxWidth, maxHeight));
    }
  }
  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "low";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      let fileUrl = null;
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }

          blob.name = fileName;

          const file = new File([blob], fileName, {
            type: blob.type,
            lastModified: Date.now(),
          });

          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          setCroppedImageUrl(fileUrl);
          resolve(file);
        },
        props?.originalImage?.type ?? "image/jpeg",
        1
      );
    });
  };
  const handleSave = async () => {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageFile = await getCroppedImg(
        imgRef.current,
        crop,
        props?.originalImage?.name ?? "croppedImage.jpeg"
      );

      props.setFieldValue(croppedImageFile);
      props.actionHandler(URL.createObjectURL(croppedImageFile), "save");
    }
  };
  return (
    <Modal show={props.show} onHide={props.onHide} className="my_modal modal_top crop_modal">
      <Modal.Header closeButton>
        <Modal.Title className="m18" style={{
          display:"flex",
          justifyContent:"space-between",
          gap:"8px"
        }}>
          <button className="modal_btn modal_btn_border" onClick={props.onHide}>
            Cancel
          </button>
          <button className="modal_btn" onClick={handleSave}>
            Done
          </button>
        </Modal.Title>
        {/* <span
          className="material-symbols-outlined modal_close red"
          onClick={props.onHide}
        >
          close
        </span> */}
      </Modal.Header>
      <div className="vg22"></div>
      <Modal.Body>
        {" "}
        {croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
        )}
        <ReactCrop
          crop={crop}
          onChange={(c) => setCrop(c)}
          // onChange={(_, percentCrop) => setCrop(percentCrop)}
          keepSelection={true}
        // aspect={props?.imageSettings?.aspect ?? 1}
        // maxHeight={props?.imageSettings?.maxHeight ?? 200}
        // maxWidth={props?.imageSettings?.maxWidth ?? 200}
        >
          <img ref={imgRef} src={props.image} onLoad={onImageLoad} />
        </ReactCrop>
      </Modal.Body>


    </Modal>
  );
};

export default CommonImageCropper;
