import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "../common/validations/formValidation";
import TextError from "../shared/TextError";
import { connect } from "react-redux";
import { createAccountOrLogin } from "../store/actions/authActions";
import ActivityIndigator from "../shared/ActivityIndigator";

const LoginPassword = ({ createAccountOrLogin, submitting }) => {
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    // Handle form submission logic here
    createAccountOrLogin(values, navigate, false);
  };
  return (
    <div className="inner">
      <Formik
        initialValues={{
          email: "",
          password: "",
          loginType: "emailAndPassword",
        }}
        validationSchema={loginSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="row">
            <div className="col-12">
              <div className="form_field">
                <label
                  htmlFor="email"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Email ID
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                />
                <ErrorMessage name="email" component={TextError} />
              </div>
            </div>
            <div className="ff_gap"></div>
            <div className="col-12">
              <div className="form_field">
                <label
                  htmlFor="password"
                  style={{
                    textAlign: "left",
                  }}
                >
                  Create New Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                />
                <ErrorMessage name="password" component={TextError} />
              </div>
            </div>
            <div className="vg22"></div>
            <div className="col-12">
              <button type="submit" disabled={submitting} className="theme_btn">
                {submitting ? <ActivityIndigator /> : "Login"}
              </button>
            </div>
            <div className="vg22"></div>
            <Link className="text-center grad_text">Forgot Password ?</Link>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
const mapStateToProps = ({ form, auth }) => {
  return {
    submitting: form.submitting,
    success: form.success,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createAccountOrLogin: (data, navigate, onAction) =>
      dispatch(createAccountOrLogin(data, navigate, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPassword);
