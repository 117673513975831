import moment from "moment";

export const getDigitFormat = (val) =>
  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const dateToFormat = (date, format = "DD MMM YYYY") =>
  moment(date).format(format);

export const dateToFormatWithTime = (date, format = "DD MMM YYYY h:mm A") =>
  moment(date).format(format);
