import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageTitle } from "../../store/actions/accountActions";

const usePageTitle = (title) => {
  const dispatch = useDispatch();
  const currentTitle = useSelector((state) => state.title);

  useEffect(() => {
    dispatch(setPageTitle(title));
  }, [dispatch, title]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return currentTitle;
};

export default usePageTitle;
