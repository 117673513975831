import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import usePageTitle from "../../../common/hooks/usePageTitle";
import { connect } from "react-redux";
import { sendContactDetails } from "../../../store/actions/contactActions";
import ActivityIndigator from "../../../shared/ActivityIndigator";
import { contactSchema } from "../../../common/validations/formValidation";
import TextError from "../../../shared/TextError";
const AccountContactUs = ({ profile, submitting, sendContactDetails }) => {
  usePageTitle("Contact Us");

  const handleSubmit = (values, { resetForm }) => {
    sendContactDetails(
      { ...values, userId: profile?._id, senderType: "user" },
      resetForm
    );
  };
  return (
    <div className="pg_contactus">
      <Formik
        initialValues={{
          name: profile?.personalInfo?.name || "",
          email: profile?.personalInfo?.email || "",
          phoneNumber: profile?.personalInfo?.phoneNumber || "",
          message: "",
        }}
        validationSchema={contactSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <div className="form_field">
              <label htmlFor="cp_message">Message to BusinessBaab</label>
              <Field
                as="textarea"
                name="message"
                id="message"
                placeholder="Message here"
              />{" "}
              <ErrorMessage name="message" component={TextError} />
            </div>
            <div className="vg22"></div>
            <button type="submit" className="theme_btn" disabled={submitting}>
              {submitting ? <ActivityIndigator /> : "Submit"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = ({ form }) => {
  return { submitting: form.submitting };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendContactDetails: (payload, onAction) =>
      dispatch(sendContactDetails(payload, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountContactUs);
