import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ASSETS from "../../../common/images";
import { PAYMENT_PLATFORMS } from "../../../common/constants";

const PaymentLinks = ({ paymentLink }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);
  const getLabel = (type) => {
    let payment = PAYMENT_PLATFORMS.find((p) => p.value === type);
    return payment !== undefined ? payment?.label ?? "" : "";
  };

  const copyUpi = (text, index) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Link copied to clipboard:', text);
        setCopiedIndex(index); // Set index of copied link
        setTimeout(() => {
          setCopiedIndex(null); // Reset copied index after some time
        }, 3000); // Reset after 3 seconds
      })
      .catch((error) => {
        console.error('Unable to copy link to clipboard:', error);
      });
  };

  return (
    <>
      <div className="sect_divider"></div>
      <h3 className="sect_title">Pay Me</h3>
      <div className="pay_me">
        {paymentLink.map((link, i) => (
          <div key={`payment-${i}`} className="pay_me_single">
            <div className="left">
              <div className="pay_icon">
                <img src={ASSETS[link.type]} alt={link.type} />
              </div>
              <h6 className="r16 medium black">{getLabel(link.type)}</h6>
            </div>
            <div className="right r14" onClick={() => copyUpi(link.link, i)}>

              {copiedIndex === i ? (
                <div className='green'>
                  Copied
                </div>
              ) : (
                <>
                  <span className="material-symbols-outlined">content_copy</span>
                  Copy UPI
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PaymentLinks;
