import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";

// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import GoogleMetaTag from "../component/GoogleMetaTag";
import useScrollToTop from "../common/hooks/useScrollToTop";

import LiveChatScript from "./LiveChatScript";

// react tabs
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const SiteFaq = () => {

    useScrollToTop();
    const [key, setKey] = useState("english");
    return (
        <div className="website_pages site_faq">
             <GoogleMetaTag title={"FAQ"} content={"BusinessBaab website FAQ"} />
            <SiteHeader />
            <div className="page">
                <div className="container">
                    <div className="my_tab fit_width">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >
                              <Tab
                                eventKey="english"
                                title={
                                    <div className="tab_title">
                                        <h5 className="m16 lh22">Read in English</h5>
                                    </div>
                                }
                            >
                                <div className="vg22"></div>
                                <div className="categories_in_accordion curve_style accordion_big">
                                    <Accordion defaultActiveKey="0" className="theme_accordion style_2">
                                        {/* <Accordion.Item eventKey="0" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">Can I create a website for free on BusinessBaab?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    Yes, you can create a website for free on BusinessBaab for 7 Days. This feature is provided so that if you have any doubts, or if you want to see how my website looks, you have a 7 Day free option. After that, you will need to take a monthly or yearly plan. <br />
                                                    Steps: Click on any "Create Website" button... Enter your mobile number... Enter OTP... and select the option "I want to free trial for 7 Days"... and then step by step provide information about your website. Your website is ready.

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                        <Accordion.Item eventKey="0" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">How can I earn extra income on BusinessBaab?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    By using our referral program, you can start earning today. Become a promoter for BusinessBaab and earn a 20% commission for each successful referral. Payments are made by BusinessBaab within 7 and 15 days. <br />
                                                    Go to the "Refer & Earn" page from the menu... Fill out the form on the page and Submit... Then click on the "Promoter Login" button in the Menu... Log in with the mobile number you provided while filling the form... Your Dashboard will open here... Scroll down a bit and click on the "Download your QR Code" button... As soon as you click the button, an image including a QR Code will be downloaded. You have to print it out and display it at your shop or anywhere else... Then when someone creates a website using your QR Code and makes payment, you get a 20% commission. <br />
                                                    Another option is, you can click on the "Share My Link" button to share this link with anyone. Even if a customer comes through the link and makes payment to create a website, you still get a 20% commission.

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">How can I claim the earned amount on BusinessBaab?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    You will receive payment on the payment information you provided when becoming a Promoter. Payment from BusinessBaab is made weekly or every 15 days. To know how much payment you have received and how much is pending, your entire Dashboard will be shown on "Promoter Login". Here you will see the vendors you have created, the plans taken by them will also be displayed. And how much you have earned will also be displayed.
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>


                                        <Accordion.Item eventKey="3" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">Is there a separate charge for domain and hosting on BusinessBaab?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    No, there is no separate charge. Hosting is free on BusinessBaab, and as for the domain, personal domains are not available on BusinessBaab. Your URL is created after BusinessBaab.com. <br />
                                                    Like: BusinessBaab.com/your_username (When you create a website, there is an option at the end where you can also create your username of your choice)
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">Is there a refund after purchasing a plan on BusinessBaab?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    No, once you purchase a plan, you do not get a refund.
                                                     {/* For your satisfaction, you can use the "7 Day free trial" plan first. */}
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">My business category is not available on BusinessBaab</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    Don't worry, we have designed BusinessBaab keeping every business in mind. However, if you didn't find a suitable category for your business, contact us. We will assist you completely.
                                                    <div className="vg8"></div>
                                                    <Link className="theme_btn short_btn" to="/contact-us">
                                                    Contact Us
                                                    </Link>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">Is coding knowledge or technical expertise required to build a website on BusinessBaab?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    No, you do not need any coding knowledge to build a website on BusinessBaab. You just need to follow steps and provide your information, and in the end, you will get a professional website ready. That too in just 15 minutes.
                                                    <div className="vg8"></div>
                                                    <Link className="theme_btn short_btn" to="/region">
                                                    Build Website Now
                                                    </Link>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="hindi"
                                title={
                                    <div className="tab_title">
                                        <h5 className="m16 lh22">हिंदी में पढ़ें</h5>
                                    </div>
                                }
                            >
                                <div className="vg22"></div>
                                <div className="categories_in_accordion curve_style accordion_big">
                                    <Accordion defaultActiveKey="0" className="theme_accordion style_2">
                                        {/* <Accordion.Item eventKey="0" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">क्या BusinessBaab पर फ्री में वेबसाइट बना सकते हैं?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    जी हाँ, आप BusinessBaab पर 7 दिनों के लिए फ्री में वेबसाइट बना सकते हैं. यह सुविधा इसलिए दी गई है, कि अगर आपको कोई संकोच हो, या आपको देखना है कि मेरी वेबसाइट कैसी दिखती है, इसलिए 7 दिन फ्री का ऑप्शन दिया गया है. इसके बाद आपको कोई मंथली या ईयरली प्लान लेना पड़ेगा। <br />
                                                    स्टेप्स : किसी भी "Create Website" वाले बटन पर क्लिक करें... आपका मोबाइल नंबर दर्ज करें... OTP डालें... और "I want to free trial for 7 Days" का ऑप्शन सिलेक्ट करें... और फिर स्टेप बाय स्टेप अपनी वेबसाइट की इनफार्मेशन देते जाएं। आपकी वेबसाइट तैयार है।

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                        <Accordion.Item eventKey="0" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">BusinessBaab पर एक्स्ट्रा इनकम कैसे कर सकते हैं?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                हमारे रेफरल कार्यक्रम का उपयोग करके आज से ही अपनी कमाई शुरू कर सकते हैं।  BusinessBaab के लिए एक प्रमोटर बनें और प्रत्येक सफल रेफरल के लिए 20% कमीशन अर्जित करें। BusinessBaab की तरफ से 7 दिन और 15 दिन में भुगतान कर दिया जाता है। <br />
                                                    Menu से "Refer & Earn" पेज पर जाएँ ... पेज पर दिया गया फॉर्म भरें और Submit करें... फिर Menu में "Promoter Login" बटन पर क्लिक करें... फॉर्म भरते समय जो मोबाइल नंबर दिया था, उससे Login करें... यहाँ आपका Dashboard ओपन हो जायेगा... थोड़ा नीचे स्क्रॉल करने पर "Download your QR Code" बटन पर क्लिक करें... बटन पर क्लिक करते ही आपको एक QR Code सहित इमेज डाउनलोड हो जाएगी। जिसे आपको प्रिंट निकलवा कर अपनी शॉप पर या कहीं भी चस्पा कर देना है... फिर जब कोई आपके QR Code का उपयोग करके वेबसाइट बनाता है और भुगतान करता है, तो आपको 20% कमीशन मिलता है। <br />
                                                    एक ऑप्शन और है, आप "Share My Link" बटन पर क्लिक करके किसी को भी यह लिंक शेयर कर सकते हैं। लिंक के द्वारा आया हुआ कस्टमर भी अगर भुगतान करके वेबसाइट बनता है, तब भी आपको 20% कमीशन मिलता है।

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">BusinessBaab पर कमाई हुई राशि का भुगतान कैसे अर्जित करें?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    Promoter बनते समय आपने जो पेमेंट इनफार्मेशन दी थी, उस पर आपको भुगतान मिलेगा। BusinessBaab की तरफ से भुगतान साप्ताहिक या 15 दिनों में किया जाता है। आपको कितना भुगतान मिला है और कितना बाकी है, यह जानने के लिए "Promoter Login" पर आपको, आपका पूरा Dashboard दिखेगा। यहाँ आपके द्वारा बनाये गए वेंडर दिखेंगे, उनके द्वारा लिया हुआ प्लान भी दिखेगा। और आपकी कमाई कितनी हुई है, वो भी दिखेगी।
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">क्या BusinessBaab पर डोमेन और होस्टिंग का अलग से चार्ज है ?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    नहीं, कोई अलग से चार्ज नहीं है। BusinessBaab पर होस्टिंग फ्री है, और जहाँ तक डोमेन की बात है, तो पर्सनल डोमेन BusinessBaab पर नहीं होता। आपका जो url बनता है, वो BusinessBaab.com के बाद बनता है। <br />
                                                    जैसे : BusinessBaab.com/your_username (जब आप वेबसाइट बनाते हैं, तो अंत में एक ऑप्शन आता है, जिसमें आप अपना यूजरनेम अपनी पसंद का भी बना सकते हैं)

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">क्या BusinessBaab पर प्लान खरीदने के बाद रिफंड मिलता है ?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    नहीं, एक बार प्लान खरीदने के बाद आपको रिफंड नहीं मिलता।
                                                     {/* अपने सेटिस्फैक्शन के लिए आप पहले "7 दिन फ्री ट्रायल" वाला प्लान इस्तेमाल कर सकते हैं। */}

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">मेरे बिज़नेस की केटेगरी BusinessBaab पर नहीं है</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    फ़िक्र न करें, हमने हर बिज़नेस को ध्यान में रखते हुए BusinessBaab को डिज़ाइन किया है। फिर भी अगर आपको अपने बिज़नेस के लिए सूटेबल केटेगरी नहीं मिली, तो हमसे संपर्क करें। हम आपकी पूरी मदद करेंगे।
                                                    <div className="vg8"></div>
                                                    <Link className="theme_btn short_btn" to="/contact-us">
                                                        संपर्क करें
                                                    </Link>


                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6" className="relative">
                                            <Accordion.Header>
                                                <div className="left">
                                                    <h5 className="product_name">क्या BusinessBaab पर वेबसाइट बनाने के लिए कोडिंग ज्ञान या टेक्निकल होने की आवश्यकता है ?</h5>
                                                </div>
                                                <div className="right">
                                                    <span class="material-symbols-outlined open icon_grad">
                                                        add
                                                    </span>
                                                    <span class="material-symbols-outlined close icon_grad">
                                                        remove
                                                    </span>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="r14">
                                                    नहीं, BusinessBaab पर वेबसाइट बनाने के लिए आपको किसी भी तरह के कोडिंग ज्ञान की आवश्यकता नहीं है। आप बस स्टेप्स फॉलो करते जाएँ, और अपनी इन्फॉर्मेशन देते जाएँ, अंत में आपको एक शानदार पेशेवर वेबसाइट तैयार मिलेगी। वो भी सिर्फ 10 मिनिट में।                                               
                                                    <div className="vg8"></div>
                                                    <Link className="theme_btn short_btn" to="/region">
                                                    अभी वेबसाइट बनाएँ
                                                    </Link>



                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </Tab>
                          
                        </Tabs>
                    </div>
                </div>
            </div>
            <SiteFooter />
            {/* <LiveChatScript/> */}
        </div>
    )
}

export default SiteFaq
