import React, { useRef } from "react";
import ASSETS from "../../common/images";
import usePageTitle from "../../common/hooks/usePageTitle";
import html2canvas from "html2canvas";
import { QRCodeSVG } from "qrcode.react";

const DownloadQR = ({ userDetails }) => {
  const qrCodeRef = useRef(null);
  let publicIdentifier = `${window.origin}/${userDetails?.publicIdentifier}`;
  usePageTitle("Download My QR Code");

  const handleDownload = () => {
    let downloadCard = qrCodeRef.current;
  
    html2canvas(downloadCard, { width: 432 }).then((canvas) => {
      const dataURL = canvas.toDataURL("image/png");
      const a = document.createElement("a");
      a.href = dataURL;
      a.download = "My-QR-Code.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  };

  return (
    <div className="myqrcode">
      <div className="my_code" id="dowanlod_card" ref={qrCodeRef}>
        <div className="our_logo">
          <img src="/assets/img/logo_businessbaab.svg" alt="" />
        </div>
        <div className="code_container relative">
          {" "}
          <QRCodeSVG
            value={publicIdentifier}
            size={"100%"}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
            level={"L"}
            includeMargin={false}
            style={{ width: "100%", height: "100%" }}
            // imageSettings={{
            //   //   src: ASSETS.faviconIcon,
            //   src: "https://static.zpao.com/favicon.png",
            //   x: undefined,
            //   y: undefined,
            //   height: 40,
            //   width: 40,
            //   excavate: false,
            // }}
          />{" "}
          <div className="watermark">
            <img src="/assets/img/fevicon_browser.png" alt="" />
          </div>
        </div>
        <div className="vg22"></div>
        <h6 className="r14 text-center">Scan and see our Website</h6>
        <div className="vg12"></div>
        <h5 className="text-center m20">Absne Private Limited</h5>
      </div>
      <div className="vg22"></div>
      <div className="theme_btn pointer" onClick={handleDownload}>
        Download and Print
      </div>
    </div>
  );
};

export default DownloadQR;
