import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import VehicleForm from "./VehicleForm";
import { connect } from "react-redux";
import {
  addEditUserService,
  deleteUserService,
  getUserServices,
} from "../../../../store/actions/travelServiceActions";
import ActivityLoader from "../../../../shared/ActivityLoader/ActivityLoader";
import usePageTitle from "../../../../common/hooks/usePageTitle";
import ConfirmationAlert from "../../../../shared/Model/ConfirmationAlert";
import { LABELS } from "../../../../common/label";

const VehicleInfo = ({
  heading,
  getUserServices,
  loading,
  lists,
  categoryName,
  imageBackendUrl,
  addEditUserService,
  submitting,
  deleteUserService,
  editMode = false,
}) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  let filterType = "vehicleInfo";
  useEffect(() => {
    getUserServices({
      pageNumber: 1,
      limit: 100,
      sortType: 1,
      search: "",
      type: filterType,
    });
  }, [getUserServices]);

  const handleSubmit = (values) => {
    let editMode = !showAddForm;
    let action = !editMode ? handleAddAction() : undefined;
    addEditUserService(
      { ...values, price: values?.minimumPrice },
      action,
      callAgainApi
    );
  };

  const handleDeleteClick = (event, data) => {
    event.stopPropagation();
    if (data?._id) {
      deleteHandler(data?._id);
    }
  };

  const deleteHandler = (deleteId) => {
    setDeleteId(deleteId);
    setShowConfirm(true);
  };

  const deleteActionHanlder = () => {
    setDeleting(true);
    deleteUserService(deleteId, onAction);
  };

  const onAction = () => {
    setShowConfirm(false);
    setDeleteId(null);
    setDeleting(false);
    callAgainApi();
  };

  const callAgainApi = () => {
    getUserServices({
      pageNumber: 1,
      limit: 100,
      sortType: 1,
      search: "",
      type: filterType,
    });
  };

  const handleAddAction = () => {
    setShowAddForm(!showAddForm);
  };
  usePageTitle(heading ?? "Vehicle Info");

  useEffect(() => {
    setShowAddForm(false);
    if (lists.length === 0) {
      setShowAddForm(true);
    }
  }, [lists]);

  return (
    <div className="info_forms product_info_pg">
      {loading ? <ActivityLoader /> : null}
      {!showAddForm ? (
        <div className="info_form_inner">
          {lists.length > 0 ? (
            <Accordion className="theme_accordion relative">
              {lists.map((data, index) => {
                data.pricePerKm =
                  data.pricePerKm !== null ? data.pricePerKm : "";

                let thumbnail =
                  data.thumbnail !== ""
                    ? `${imageBackendUrl}${data.thumbnail}`
                    : "";

                return (
                  <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>
                      <div className="left">
                        <h5 className="product_name">{data.title}</h5>
                        <h6 className="product_id">ID : #{data.serviceNo}</h6>
                      </div>
                      <div className="right">
                        <span
                          className="material-symbols-outlined red"
                          onClick={(e) => handleDeleteClick(e, data)}
                        >
                          delete_forever
                        </span>
                        <span className="material-symbols-outlined open icon_grad">
                          add
                        </span>
                        <span className="material-symbols-outlined close icon_grad">
                          remove
                        </span>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <VehicleForm
                        data={{ ...data, thumbnail }}
                        handleSubmit={handleSubmit}
                        index={index}
                        submitting={submitting}
                        categoryName={categoryName}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <div>No Vehicle Added yet</div>
          )}
          <div className="bottom_plus" onClick={handleAddAction}>
            <span className="material-symbols-outlined">add</span>
          </div>
          <div className="vg22"></div>
          <div className="vg22"></div>

          {!editMode && (
            <div className="col-12">
              <Link to="/account/payment-info" className="theme_btn">
                Save & Next
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="add_more_items_div relative">
          <div className="close_media" onClick={handleAddAction}>
            <span className="material-symbols-outlined">close</span>
          </div>

          <VehicleForm
            data={{
              type: filterType,
              title: "",
              totalSeats: "",
              priceType: "negotiable",
              minimumPrice: "",
              pricePerKm: "",
              description: "",
              thumbnail: "",
              features: [],
            }}
            handleSubmit={handleSubmit}
            index={-1}
            submitting={submitting}
            categoryName={categoryName}
          />
        </div>
      )}
      {showConfirm && (
        <ConfirmationAlert
          show={showConfirm}
          onHide={() => setShowConfirm(false)}
          actionHandler={deleteActionHanlder}
          deleting={deleting}
          labelName={LABELS.SERVICE_TITLE[categoryName] || "item"}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({
  form,
  service: { loading, lists, totalDocuments, imageBackendUrl },
}) => {
  return {
    submitting: form.submitting,
    loading,
    lists,
    totalDocuments,
    imageBackendUrl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserServices: (payload) => dispatch(getUserServices(payload)),

    addEditUserService: (payload, onAction, callAgainApi) =>
      dispatch(addEditUserService(payload, onAction, callAgainApi)),
    deleteUserService: (_id, onAction) =>
      dispatch(deleteUserService(_id, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleInfo);
