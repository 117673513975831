import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import useNavigate
import { useDispatch, useSelector } from "react-redux";
import { getSession, logout } from "../../store/actions/authActions";
import { getGender } from "../../common/utils/utils";
import { LABELS } from "../../common/label";
import ASSETS from "../../common/images";
import { dateToFormat } from "../../common/helpers/_helper";
import { CATEGORY_LISTS } from "../../common/constants";

const Navbar = ({
  title,
  backgroundColor,
  backArrow,
  userDetails,
  categoryName,
  skipPath = null,
  isMenuIcon = true,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  let { title: pageTitle } = useSelector((state) => state.account);
  let { Authorization } = getSession();
  const location = useLocation(); // Get the current location
  const dispatch = useDispatch();
  // goback code
  const navigate = useNavigate(); // Use useNavigate hoo
  const handleGoBack = () => {
    navigate(-1); // Navigate back one step
  };

  const onToggle = () => {
    setShowMenu(!showMenu);
  };

  const onLogout = () => {
    dispatch(logout(navigate));
    onToggle();
  };

  const onShare = async () => {
    let URL = `${window.origin}/${userDetails?.publicIdentifier}`;

    const data = {
      title: "Business Baab",
      // text: this.text,
      url: URL,
    };
    // console.log(data);
    if (navigator.share) {
      navigator
        .share(data)
        .then(() => {
          console.log("Successful share! 🎉");
          onToggle();
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="vendor_header">
      <div className="header" style={{ background: backgroundColor }}>
        <div className="header_left">
          <span
            className="material-symbols-outlined back pointer"
            onClick={handleGoBack}
          >
            {backArrow}
          </span>
          <h6 className="m18">{pageTitle ?? "Acount"}</h6>
        </div>
        <div className="header_right">
          {skipPath !== null && (
            <Link to={skipPath} className="language pointer">
              Skip
            </Link>
          )}

          {isMenuIcon && (
            <div className="huburger_icon pointer" onClick={onToggle}>
              <img src={ASSETS.menuIcon} alt="" />
            </div>
          )}
        </div>
        {/* modal for menu */}
        <Modal
          show={showMenu}
          onHide={onToggle}
          className="my_modal menu_modal vendor_header_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title className="m18"></Modal.Title>
            <span
              className="material-symbols-outlined modal_close red"
              onClick={onToggle}
            >
              close
            </span>
          </Modal.Header>
          <div className="vg22"></div>
          <Modal.Body>
            {Authorization && (
              <>
                {" "}
                <h5 className="r18">
                  Hello {getGender(userDetails?.personalInfo?.gender)}&nbsp;
                  <span className="semibold">
                    {userDetails?.personalInfo?.name || ""}
                  </span>
                </h5>
                {!userDetails?.isGrantUser && userDetails?.subscription && (
                  <h5 className="normal_font">
                    <span
                      clssName="normal_font"
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {userDetails?.subscription?.planType === "day"
                        ? "Free Trial"
                        : userDetails?.subscription?.planType || ""}{" "}
                      plan{" "}
                    </span>
                    <span className="highlight_color">
                      {userDetails?.subscription?.paymentAt &&
                        userDetails?.subscription?.expiresAt && (
                          <span>
                            {dateToFormat(
                              userDetails?.subscription?.paymentAt
                            ) || ""}
                            {" - "}
                            {dateToFormat(
                              userDetails?.subscription?.expiresAt
                            ) || ""}
                          </span>
                        )}
                    </span>{" "}
                  </h5>
                )}
                {userDetails?.isGrantUser && (
                  <span className="highlight_color">Grants</span>
                )}
                <h5 className="normal_font">
                  Registered Mobile no :{" "}
                  <Link
                    to={`tel:${userDetails?.phoneNumber || ""}`}
                    className="blue"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    {userDetails?.phoneNumber || ""}
                  </Link>
                </h5>
                <>
                  {!userDetails?.isGrantUser && (
                    <>
                      {" "}
                      <span>
                        {" "}
                        {userDetails?.needToRecharge && (
                          <span>Plan Expired</span>
                        )}
                      </span>
                      {userDetails?.needToRecharge && (
                        <Link
                          to="/account/recharge"
                          className="modal_btn"
                          onClick={onToggle}
                        >
                          Rechage Now
                        </Link>
                      )}
                    </>
                  )}
                </>
                <div className="sect_divider"></div>
                <div className="redirect_button">
                  <Link
                    to={`/${userDetails?.publicIdentifier}`}
                    className="modal_btn"
                    onClick={onToggle}
                  >
                    Businessbaab
                  </Link>
                  <Link to="/account" className="modal_btn" onClick={onToggle}>
                    Dashboard
                  </Link>
                  <Link
                    to="/account/analytics"
                    className="modal_btn"
                    onClick={onToggle}
                  >
                    Analytics
                  </Link>
                  <Link
                    to="/account/orders"
                    className="modal_btn"
                    onClick={onToggle}
                  >
                    Orders
                  </Link>
                  {CATEGORY_LISTS.TRAVEL === categoryName ? (
                    <Link
                      to="/account/my-vehicles"
                      className="modal_btn"
                      onClick={onToggle}
                    >
                      My Vehicles
                    </Link>
                  ) : (
                    <Link
                      to="/account/my-products"
                      className="modal_btn"
                      onClick={onToggle}
                    >
                      {LABELS.MY_SERVICE_TITLE[categoryName] || ""}
                    </Link>
                  )}
                </div>
              </>
            )}

            <div className="menus">
              {/* <Link to={"/"} className="menu_single" onClick={onToggle}>
                <div className="icon">
                  <img src={ASSETS.homeIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Home</h6>
              </Link> */}
              <Link
                to="/account/about-us"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.aboutUsIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">About us</h6>
              </Link>
              <Link
                to="/account/business"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.businessIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Business</h6>
              </Link>
              {/* <Link
                to="/account/notification"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.notificationIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Notification</h6>
              </Link> */}
              {/* <Link
                to="/account/faq"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.faqIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">FAQ</h6>
              </Link> */}
              <Link
                to="/account/settings"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.settingsIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Setting</h6>
              </Link>
              <Link
                to="/account/terms-conditions"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.policyIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Terms & Conditions</h6>
              </Link>
              <Link
                to="/account/privacy-policy"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.policyIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Privay Policy</h6>
              </Link>
              <Link
                to="/account/refund-policy"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.policyIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Refund Policy</h6>
              </Link>

              <Link
                to="/account/contact-us"
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.contactIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">Contact us</h6>
              </Link>
            </div>
            <div className="sect_divider"></div>
            <div className="menus">
              <Link className="menu_single" onClick={onShare}>
                <div className="icon">
                  <img src={ASSETS.shareIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">share my portal</h6>
              </Link>
              <Link
                to={"/account/qr-code"}
                className="menu_single"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.QRcodeIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">dowanlod My OR code</h6>
              </Link>
              {/* <Link to="/language" className="menu_single" onClick={onToggle}>
                <div className="icon">
                  <img src={ASSETS.languageIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">language</h6>
              </Link> */}
              <Link
                to="https://www.businessbaab.com/"
                className="menu_single"
                target="_blank"
                onClick={onToggle}
              >
                <div className="icon">
                  <img src={ASSETS.knowMoreIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">know more</h6>
              </Link>
              <span className="menu_single" onClick={onLogout}>
                <div className="icon">
                  <img src={ASSETS.logoutIcon} alt="" />
                </div>
                <h6 className="r16 text_capital">logout</h6>
              </span>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Navbar;
